import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BoUser, BoUserPage} from '@models/bo-user';
import {map} from '@otel';
import {ApiRoutesService} from '@services/rest-api/apiRoutesService';
import {
    GetUsersRequestPayload,
    GetUsersTextFilter,
    GetUsersTextFilterKey,
    IUserManagementApiService,
} from '@services/rest-api/IUserManagementApiService';
import {RestResponse, ServiceResponsePayload} from '@services/types';

import {RestService} from './restService';

type SetLoginAccessPayload = {
    userId: string;
    isEnable: boolean;
};

@injectable()
export class InternalUserApiService implements IUserManagementApiService {
    private readonly _restService: RestService;
    private readonly _apiRouteService: ApiRoutesService;
    private readonly _internalUsersApiBuilder: InternalUsersApiBuilder;

    private items: BoUser[];
    private total: number;

    constructor(
        @inject(ServiceTypes.RestService) restService: RestService,
        @inject(ServiceTypes.ApiRoutesService) apiRouteService: ApiRoutesService
    ) {
        this._restService = restService;
        this._apiRouteService = apiRouteService;
        this._internalUsersApiBuilder = new InternalUsersApiBuilder();
    }

    getUserPage(requestPayload: GetUsersRequestPayload): Observable<ServiceResponsePayload<GetUsersRequestPayload, BoUserPage>> {
        this._internalUsersApiBuilder.reset();
        this._internalUsersApiBuilder.buildQuery(requestPayload);
        const query = this._internalUsersApiBuilder.getQuery();
        const usersEndpoint: string = this._apiRouteService?.getInternalUsersEndpoint();

        return this._restService.get({endpoint: usersEndpoint, query}).pipe(
            map(response => {
                const boUserPage = this.getResponse(response, requestPayload);
                return {
                    ...response,
                    requestPayload,
                    responsePayload: boUserPage,
                };
            })
        );
    }

    public setLoginAccessStatus(requestPayload: SetLoginAccessPayload): Observable<ServiceResponsePayload<SetLoginAccessPayload, null>> {
        const endpoint = this._apiRouteService?.setLoginAccessEndpoint(requestPayload?.userId, requestPayload?.isEnable);
        return this._restService.put({endpoint}).pipe(map(response => ({...response, requestPayload, responsePayload: null})));
    }

    private getResponse(response: RestResponse, requestPayload: GetUsersRequestPayload): BoUserPage {
        this.items = response?.responsePayload?.response?.items;
        this.total = response?.responsePayload?.response?.count;

        if (this._internalUsersApiBuilder.getIsClientFilter()) {
            this.clientResponseFilter(requestPayload);
        }

        return {items: this.items, total: this.total};
    }

    private clientResponseFilter(requestPayload: GetUsersRequestPayload) {
        this.clientIdFilter(requestPayload?.ids);
        this.clientPagination(requestPayload?.page, requestPayload?.size);
    }

    private clientIdFilter(ids: string[]) {
        if (ids?.length) {
            this.items = this.items?.filter(user => ids?.includes(user?.id));
            this.total = this.items?.length;
        }
    }

    private clientPagination(page: number, size: number) {
        if (size > 0) {
            const paginationPage = page > 0 ? page : 1;
            const startIndex = (paginationPage - 1) * size;
            const endIndex = startIndex + size;

            this.items = this.items.slice(startIndex, endIndex);
        }
    }
}

export class InternalUsersApiBuilder {
    private urlQuery: URLSearchParams;
    private isClientFilter: boolean = false;

    public getQuery(): string {
        return this.urlQuery.toString();
    }

    public reset() {
        this.urlQuery = new URLSearchParams();
        this.isClientFilter = false;
    }

    public getIsClientFilter() {
        return this.isClientFilter;
    }

    public buildQuery(payload: GetUsersRequestPayload) {
        this.isClientFilter = !!payload?.ids?.length;
        this.buildFilter(payload.textFilter);
        this.buildPagination(payload.page, payload.size);
    }

    private buildPagination(page: number, size: number): void {
        if (!this.isClientFilter && page > 0) {
            this.urlQuery.set('page', (page - 1).toString());
        }
        if (!this.isClientFilter && size > 0) {
            this.urlQuery.set('size', size.toString());
        }
    }

    private buildFilter(filters: GetUsersTextFilter[]): void {
        const filterKeyMap: Record<GetUsersTextFilterKey, InternalUsersFilterParams> = {
            em_fn_ln: null,
            email: 'email',
            firstName: 'firstName',
            lastName: 'lastName',
        };
        const filterValue = filters
            ?.map(f => ({key: filterKeyMap[f.key], value: f.value}))
            .filter(f => f.key && f.value)
            .map(f => `${f.key}=${f.value}`)
            .join(',');
        if (filterValue) {
            this.urlQuery.set('filter', filterValue);
        }
    }
}

type InternalUsersFilterParams = 'email' | 'firstName' | 'lastName' | 'id';
