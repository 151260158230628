import React, {useContext} from 'react';
import {IntlContext, MessageDescriptor} from 'react-intl';
import {Box, Grid, GridSize} from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {makeStyles} from 'tss-react/mui';

import LocalizedText from '@components/i18n/LocalizedText';
import {CustomTheme} from '@style';

import {useJurisdictionFeatureChecker} from '../../features/app/config/hooks';
import {FeatureName} from '../../features/app/config/types';
import {isBlank, isMessageDescriptor} from '../../features/module-shared/utils';

import {dashCharacter as emptyCellCharacter} from './label/EmptyLabel';
import StyledTooltip from './Tooltip';

export interface KeyValuePair {
    key: string | MessageDescriptor;
    value: string | MessageDescriptor | number | JSX.Element;
    tooltip?: MessageDescriptor;
    isUnderlined?: boolean;
    featureName?: FeatureName;
    multiline?: boolean;
    allowOverflow?: boolean;
    hideTooltip?: boolean;
}

interface KeyValueTableProps {
    rows: Array<KeyValuePair>;
    tableLabel: MessageDescriptor;
    title?: MessageDescriptor;
    columnsCount?: number;
    className?: string;
    styles?: {
        tableRow?: string;
        table?: string;
    };
}

const useStyles = makeStyles()((theme: CustomTheme) => ({
    tableContainer: {
        width: '100%',
        padding: theme.spacing(3, 4, 2),
    },
    tableContainerHeader: {
        marginBottom: theme.spacing(2),
    },
    table: {
        display: 'grid',
        gridTemplateColumns: 'minmax(165px, max-content) auto',
    },
    tableCell: {
        padding: theme.spacing(2, 0),
        alignItems: 'center',
        display: 'flex',
        minWidth: 0,
    },
    tableCellHeader: {
        marginRight: theme.spacing(1),
    },
    tableCellHeaderMultiLine: {
        alignItems: 'flex-start',
        height: '100%',
    },
    tableCellSingleLine: {
        minHeight: theme.custom.keyValueTableCell,
    },
    tableCellUnderlined: {
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    tableCellValue: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: theme.typography.body2.lineHeight,
    },
    tableCellValueOverflowVisible: {
        overflow: 'visible',
    },
}));

const KeyValueTable = ({rows, columnsCount = 1, tableLabel, title, className, styles, ...props}: KeyValueTableProps) => {
    const {formatMessage} = useContext(IntlContext);
    const {classes, cx} = useStyles();

    const gridSize = (12 / columnsCount) as GridSize;
    const checkedRows = rows.filter(r => r !== null);

    const checkFeatureAvailability = useJurisdictionFeatureChecker();
    const availableRows = checkedRows.filter(row => !row.featureName || checkFeatureAvailability({featureName: row.featureName}));

    const columnSize = Math.ceil(availableRows.length / columnsCount);

    const checkedValue = (value: string | number | JSX.Element | MessageDescriptor): string | number | JSX.Element => {
        if (React.isValidElement(value)) {
            return value;
        } else if (isValueNumberOrString(value)) {
            return isBlank(value as string | number) ? emptyCellCharacter : value;
        } else if (isMessageDescriptor(value)) {
            return <LocalizedText label={value as MessageDescriptor} />;
        }
        return emptyCellCharacter;
    };

    const isValueNumberOrString = (value: string | number | JSX.Element | MessageDescriptor): value is number | string => {
        return typeof value === 'number' || typeof value === 'string';
    };

    return (
        <>
            <Box component={Paper} className={`${classes.tableContainer} ${className}`} {...props}>
                {title && (
                    <Typography className={classes.tableContainerHeader} variant="h6">
                        {formatMessage(title)}
                    </Typography>
                )}
                <Grid container spacing={5}>
                    {[...Array(columnsCount).keys()].map((column, index) => {
                        const columnRows = availableRows.slice(column * columnSize, (column + 1) * columnSize);
                        return (
                            <Grid key={index} item xs={gridSize} className={styles?.tableRow}>
                                <Box
                                    className={`${classes.table} ${styles?.table}`}
                                    aria-label={tableLabel ? formatMessage(tableLabel) : ''}
                                >
                                    {columnRows.map(({tooltip, value, key, isUnderlined, multiline, allowOverflow, hideTooltip}, index) => {
                                        const underlined = index !== columnSize - 1 || isUnderlined;
                                        const showCellValueTooltip =
                                            !hideTooltip && (isValueNumberOrString(value) || isMessageDescriptor(value));

                                        return (
                                            <React.Fragment key={index}>
                                                <Box
                                                    className={cx(
                                                        {
                                                            [classes.tableCellHeaderMultiLine]: multiline,
                                                            [classes.tableCellUnderlined]: underlined,
                                                        },
                                                        classes.tableCellHeader,
                                                        classes.tableCell
                                                    )}
                                                >
                                                    <StyledTooltip
                                                        element={
                                                            <Box>
                                                                {key ? (
                                                                    <Typography variant="subtitle1">
                                                                        <LocalizedText label={key} />
                                                                    </Typography>
                                                                ) : null}
                                                            </Box>
                                                        }
                                                        title={<Box>{tooltip ? <LocalizedText label={tooltip} /> : null}</Box>}
                                                        hideTooltip={!tooltip}
                                                    />
                                                </Box>
                                                <Box
                                                    className={cx(
                                                        {
                                                            [classes.tableCellSingleLine]: !multiline,
                                                            [classes.tableCellUnderlined]: underlined,
                                                        },
                                                        classes.tableCell
                                                    )}
                                                >
                                                    <StyledTooltip
                                                        element={
                                                            <Box
                                                                className={cx(
                                                                    {
                                                                        [classes.tableCellValueOverflowVisible]: allowOverflow,
                                                                    },
                                                                    classes.tableCellValue
                                                                )}
                                                            >
                                                                {checkedValue(value)}
                                                            </Box>
                                                        }
                                                        title={<Box>{showCellValueTooltip ? checkedValue(value) : null}</Box>}
                                                        hideTooltip={!showCellValueTooltip}
                                                    />
                                                </Box>
                                            </React.Fragment>
                                        );
                                    })}
                                </Box>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </>
    );
};

export default KeyValueTable;
