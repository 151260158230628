import React from 'react';
import {useIntl} from 'react-intl';

import {ValueChip} from '@components/chip/SquaredChipList';
import {ChipType, ChipVariant} from '@components/chip/types';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {withEmptyCheck} from '@components/label/EmptyLabel';
import {SecurityCase} from '@models/generated/graphql';

import {localizedSecurityCaseType} from '../../app/intl/shared-resources/securityCaseType';

import {WorkspaceList} from './WorkspaceList';

type SecurityCasesListProps = {
    value: SecurityCase[];
};

export const SecurityCasesList = withEmptyCheck(({value}: SecurityCasesListProps) => {
    const {formatMessage} = useIntl();

    return (
        <WorkspaceList>
            {value?.map(c => {
                const chipKey = c?.type ? formatMessage(localizedSecurityCaseType[c.type]) : emptyCellCharacter;
                return c ? (
                    <ValueChip type={ChipType.Prefix} variant={ChipVariant.Default} label={chipKey}>
                        {c?.case_id}
                    </ValueChip>
                ) : (
                    emptyCellCharacter
                );
            })}
        </WorkspaceList>
    );
});
