import React from 'react';
import {useDispatch} from 'react-redux';
import {GridCellParams} from '@mui/x-data-grid';

import {ActionButtonColumnValue} from '@components/data-grid/types';

import {ButtonCell} from './ButtonCell';

export function renderMuiGridActionButtonCell(params: GridCellParams) {
    const dispatch = useDispatch();
    const value = params.value as ActionButtonColumnValue;
    return <ButtonCell onClick={() => dispatch(value.action)} value={value} />;
}
