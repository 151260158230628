import React, {useRef} from 'react';
import {Box, TextField, TextFieldProps} from '@mui/material';

import {Message, MessageOrientation, MessageType} from '@components/alerts/Message';
import {EditableProps, withEditButton} from '@components/editable/EditButtonHoc';

import {dashCharacter as emptyCellCharacter} from '../label';

import {useClasses} from './EditableText.style';

type EditableTextInternalProps = EditableProps &
    TextFieldProps & {
        errorMessage?: string;
        errorMessageOrientation?: MessageOrientation;
        formatter?: (value: unknown) => React.ReactNode;
    };

export const EditableTextInternal = ({
    mode,
    errorMessageOrientation = MessageOrientation.Inline,
    errorMessage,
    formatter,
    ...props
}: EditableTextInternalProps) => {
    const {classes} = useClasses();
    const containerRef = useRef<HTMLDivElement>(null);

    const {disabled, value, placeholder} = props;
    const isEditDisabled = disabled || mode !== 'Edit';
    const inputValue = isEditDisabled && !value ? emptyCellCharacter : value;
    const disabledInputValue = isEditDisabled && !value ? emptyCellCharacter : formatter ? formatter(value) : value;
    const inputPlaceholder = mode === 'Edit' ? placeholder : (inputValue as string);

    return isEditDisabled ? (
        <Box className={classes.editableTextReadonly}>{disabledInputValue as React.ReactNode}</Box>
    ) : (
        <div ref={containerRef} className={classes.editableTextContainer}>
            <TextField
                {...props}
                inputProps={{'data-testid': 'editableTextInput'}}
                className={classes.editableTextInput}
                value={inputValue}
                disabled={isEditDisabled}
                variant={mode !== 'Edit' ? 'standard' : 'outlined'}
                placeholder={inputPlaceholder}
            />
            <Message
                containerRef={containerRef}
                className={classes.editableTextErrorMessage}
                message={errorMessage}
                variant="inherit"
                messageType={MessageType.Error}
                messageOrientation={errorMessageOrientation}
            />
        </div>
    );
};

export const EditableText = withEditButton(EditableTextInternal);
