import React from 'react';

import {QueryFilterAdapterModel, QueryFilterAdapterProps, withQueryFilter} from '@components/filter';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {BonusCodeServerFilterKeys, BonusCodeServerTextFilterKeys} from '@redux/entity';
import {getMirrorObject} from '@utils/object';

import {BonusCodeFilterModel, BonusCodeFilterName, BonusCodeTextFilterKeys} from '../types';

import {BonusCodeFilters} from './BonusCodeFilters';

type BonusCodeQueryFilterAdapterProps = QueryFilterAdapterProps<
    BonusCodeServerFilterKeys,
    BonusCodeFilterName,
    BonusCodeServerTextFilterKeys
>;

function BonusCodeQueryFilterAdapter({model, onChange, ...props}: BonusCodeQueryFilterAdapterProps) {
    const clientTextKeyToServer: Record<BonusCodeTextFilterKeys, BonusCodeServerTextFilterKeys> = {
        id_name: 'idName',
        id: 'bonusId',
        name: 'bonusName',
    };
    const serverTextKeyToClient: Record<BonusCodeServerTextFilterKeys, BonusCodeTextFilterKeys> = getMirrorObject(clientTextKeyToServer);

    const filterModel: BonusCodeFilterModel = {
        text: model?.text ? {...model?.text, option: serverTextKeyToClient[model?.text?.option]} : null,
        bonus_status: model?.bonusStatus as BonusStatus[],
        credit_type: model?.creditType as BonusCreditType[],
        create_time: {
            from: model?.['create_time.from'] as number,
            to: model?.['create_time.to'] as number,
        },
        created_by: model?.createdBy as string,
    };

    function handleChange(model: BonusCodeFilterModel) {
        const result: QueryFilterAdapterModel<BonusCodeServerFilterKeys, BonusCodeServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
            bonusStatus: model?.bonus_status?.length ? model?.bonus_status : undefined,
            creditType: model?.credit_type?.length ? model?.credit_type : undefined,
            'create_time.from': model?.create_time?.from,
            'create_time.to': model?.create_time?.to,
            createdBy: model?.created_by,
        };
        onChange(result);
    }

    return <BonusCodeFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const BonusCodeQueryFilters = withQueryFilter<BonusCodeServerFilterKeys, BonusCodeFilterName, BonusCodeServerTextFilterKeys>(
    BonusCodeQueryFilterAdapter,
    {
        id_name: ['bonusId', 'bonusName', 'idName'],
    }
);
