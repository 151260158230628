import React, {useContext, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box, List, ListItem, ListItemText} from '@mui/material';
import merge from 'deepmerge';

import {MessageWithIcon} from '@components/alerts/MessageWithIcon';
import Button, {OutlinedButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons/types';
import {FormTextInputDefault, numberRegex, RuleType, useValidationFormatter} from '@components/input';
import {ModalContext} from '@components/modal';
import {
    BonusCodeInput,
    BonusCreditType,
    BonusReleaseMechanism,
    BonusReleaseMetric,
    BonusReleaseRatioType,
    BonusStatus,
    BonusToCredit,
    Platform,
} from '@models/generated/graphql';
import {useAuthUser} from '@auth';
import {getUTCTimestamp} from '@utils';

import {useCurrencySymbol} from '../../app/intl/hooks';
import {beBonusActions} from '../../be-bonus/actions';
import {useAsyncActionState} from '../../shared/async-action/hooks';

import {localizedBonusEngineForm} from './BonusEngineForm.localize';
import {useBonusEngineFormClasses} from './BonusEngineForm.style';

const localized = defineMessages({
    bonusId: {
        id: 'BECB_bonusId',
        defaultMessage: 'Bonus ID',
    },
    bonusName: {
        id: 'BECB_bonusName',
        defaultMessage: 'Bonus Name',
    },
    placeholderBonusName: {
        id: 'BECB_placeholderBonusName',
        defaultMessage: 'e.g., 47GQGCHU',
    },
    bonusDescription: {
        id: 'BECB_bonusDescription',
        defaultMessage: 'Bonus Description',
    },
    placeholderBonusDescription: {
        id: 'BECB_placeholderBonusDescription',
        defaultMessage: 'Description',
    },
    depositBonusAmount: {
        id: 'BECB_depositBonusAmount',
        defaultMessage: 'Deposit Bonus Amount',
    },
    placeholderDepositBonusAmount: {
        id: 'BECB_placeholderDepositBonusAmount',
        defaultMessage: '0.1',
    },
    depositBonusAmountInfoMessage: {
        id: 'BECB_depositBonusAmountInfoMessage',
        defaultMessage: 'Every $1 deposit made, player will be eligible for bonus ',
    },
    maximumBonus: {
        id: 'BECB_maximumBonus',
        defaultMessage: 'Maximum Bonus',
    },
    placeholderMaximumBonus: {
        id: 'BECB_placeholderMaximumBonus',
        defaultMessage: '0,00',
    },
    bonusValueParam: {
        id: 'BECB_bonusValueParam',
        defaultMessage: 'Bonus Value Param',
    },
    placeholderBonusValueParam: {
        id: 'BECB_placeholderBonusValueParam',
        defaultMessage: '0,00',
    },
    bonusValueParamInfoMessage: {
        id: 'BECB_bonusValueParamInfoMessage',
        defaultMessage: 'Amount paid per single trigger',
    },
});

const cashBonusStartTimeDefault = 1640139000; //Wednesday, December 22, 2021 2:10:00 AM
const cashBonusEndTimeDefault = 2147483000; //Tuesday, January 19, 2038 3:03:20 AM
export const getCashBonusDefaults = (uid: string): BonusCodeInput => {
    const timestamp = getUTCTimestamp();

    return {
        bonus_name: '',
        platform: Platform.Wpto,
        bonus_status: BonusStatus.Active,
        create_time: timestamp,
        start_time: {
            seconds: cashBonusStartTimeDefault,
        },
        end_time: {
            seconds: cashBonusEndTimeDefault,
        },
        credit_type: BonusCreditType.CashBonus,
        bonus_to_credit: BonusToCredit.Amount,
        release_mechanism: BonusReleaseMechanism.Immediate,
        release_metric: BonusReleaseMetric.None,
        release_ratio_type: BonusReleaseRatioType.Fixed,
        meta: {
            expire_period: 7776000,
            show_max_bonus_in_total: 1,
        },
        created_by: uid,
    };
};

type BonusEngineCashBonusFormProps = {
    initialModel?: BonusCodeInput;
    mode?: 'create' | 'read';
};

export const BonusEngineCashBonusForm = ({initialModel, mode = 'create'}: BonusEngineCashBonusFormProps) => {
    const {classes} = useBonusEngineFormClasses();
    const currencySymbol = useCurrencySymbol();
    const dispatch = useDispatch();
    const {reset, control, handleSubmit} = useForm<BonusCodeInput>({
        defaultValues: initialModel,
    });
    const validationFormatter = useValidationFormatter();
    const {requestStatus, isProgress} = useAsyncActionState(beBonusActions.addBonusCode);
    const isInputsDisabled = mode === 'read' || isProgress;
    const {closeModal} = useContext(ModalContext);
    const {formatMessage} = useIntl();
    const {sub} = useAuthUser();
    const onConfirmClicked = (newItem: BonusCodeInput) => {
        const cashBonusDefaults = getCashBonusDefaults(sub);
        const {meta, ...others} = newItem;
        const maxBonusGet = Number(meta.max_bonus_get);
        const newItemProcessed = {
            ...others,
            meta: {
                bonus_value_param: Number(meta.bonus_value_param),
                max_bonus_get: maxBonusGet,
                total_max_bonus_get: maxBonusGet,
            },
        };

        const payload: BonusCodeInput = merge(cashBonusDefaults, newItemProcessed);

        dispatch(beBonusActions.addBonusCode.request({bonus: payload}));
        closeModal();
    };

    useEffect(() => {
        if (requestStatus) {
            reset();
        }
    }, [requestStatus]);

    return (
        <Box data-testid="addCashBonusForm">
            <List classes={{padding: classes.bonusEngineFormModalList}}>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText className={classes.bonusEngineFormModalListItemText} primary={formatMessage(localized.bonusName)} />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderBonusName}
                                disabled={isInputsDisabled}
                            />
                        )}
                        name="bonus_name"
                        control={control}
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.bonusName),
                        }}
                    />
                </ListItem>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText
                        className={classes.bonusEngineFormModalListItemText}
                        primary={formatMessage(localized.bonusDescription)}
                    />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderBonusDescription}
                                disabled={isInputsDisabled}
                            />
                        )}
                        name="description"
                        control={control}
                    />
                </ListItem>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText className={classes.bonusEngineFormModalListItemText} primary={formatMessage(localized.maximumBonus)} />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderMaximumBonus}
                                disabled={isInputsDisabled}
                                endAdornment={currencySymbol}
                            />
                        )}
                        name="meta.max_bonus_get"
                        control={control}
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.maximumBonus),
                            pattern: {
                                value: numberRegex,
                                message: validationFormatter(RuleType.Number, localized.maximumBonus),
                            },
                        }}
                    />
                </ListItem>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText className={classes.bonusEngineFormModalListItemText} primary={formatMessage(localized.bonusValueParam)} />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderBonusValueParam}
                                disabled={isInputsDisabled}
                                endAdornment={currencySymbol}
                            />
                        )}
                        name="meta.bonus_value_param"
                        control={control}
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.bonusValueParam),
                            pattern: {
                                value: numberRegex,
                                message: validationFormatter(RuleType.Number, localized.bonusValueParam),
                            },
                        }}
                    />
                </ListItem>
                <Box className={classes.bonusEngineFormMessageWithIconContainer}>
                    <MessageWithIcon
                        className={classes.bonusEngineFormMessageWithIcon}
                        icon={CustomIcon.WarningOutline}
                        label={localized.bonusValueParamInfoMessage}
                    />
                </Box>
            </List>

            <Box className={classes.bonusEngineFormActionButtons}>
                <Button
                    className={classes.bonusEngineFormAddActionFooterButton}
                    color="primary"
                    label={localizedBonusEngineForm.submit}
                    onClick={handleSubmit(onConfirmClicked)}
                    disabled={isInputsDisabled}
                />
                <OutlinedButton
                    className={classes.bonusEngineFormAddActionFooterButton}
                    label={localizedBonusEngineForm.close}
                    onClick={closeModal}
                />
            </Box>
        </Box>
    );
};
