import React from 'react';
import {GridCellParams, GridColDef} from '@mui/x-data-grid';

import {EmptyLabel} from '@components/label';
import {DefaultSymbol} from '@components/label/EmptyLabel';
import {AutoAdjustedWidthTooltip} from '@components/tooltip/AutoAdjustedWidthTooltip';

export const renderCellWithTooltip = (
    params: GridCellParams<any, React.ReactNode>,
    showEmptyCharacter = true,
    emptyCharacter: DefaultSymbol = 'dash'
) => {
    const colDef = params.colDef as GridColDef;
    const value = colDef.valueFormatter ? params.formattedValue : params.value;

    return renderValueWithTooltip(value, showEmptyCharacter, emptyCharacter);
};

export const renderValueWithTooltip = (value: React.ReactNode, showEmptyCharacter = true, emptyCharacter: DefaultSymbol = 'dash') => {
    const isEmpty = value === null || value === undefined;
    return isEmpty ? (
        <EmptyLabel defaultSymbol={showEmptyCharacter ? emptyCharacter : 'none'} />
    ) : (
        <AutoAdjustedWidthTooltip value={value} showEmptyCharacter={showEmptyCharacter}></AutoAdjustedWidthTooltip>
    );
};
