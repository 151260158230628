import React, {memo} from 'react';
import TextField from '@mui/material/TextField';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {IModuleGridItem} from '@components/data-grid';
import {dashCharacter as emptyCellCharacter} from '@components/label';

import {MessageType, PatchAsyncAction} from '../../types';
import {EditableColDef} from '../editable-components/types';

import {EditableProps, EditStateTrigger, withEditable} from './EditableHoc';
import {getEditDisabledState, getGridRowData} from './utils';

const useClasses = makeStyles()(() => ({
    input: {
        width: '100%',
        background: 'transparent',
    },
}));

type EditableTextOptions = {
    placeholder: string;
};

export const EditableText = memo(
    withEditable(({value, onChange, disabled, options}: EditableProps<string, EditableTextOptions>) => {
        const {classes} = useClasses();

        const changeValue = (e: React.BaseSyntheticEvent) => {
            onChange(e.target.value);
        };

        return (
            <TextField
                className={classes.input}
                value={disabled && !value ? emptyCellCharacter : value}
                disabled={disabled}
                onInput={changeValue}
                placeholder={options?.placeholder}
            />
        );
    })
);

export const renderEditableTextCell =
    <T extends IModuleGridItem>(saveAction: PatchAsyncAction, isReadonly?: (model: T) => boolean) =>
    (params: GridCellParams) => {
        const {model, defaultValue, field, id, typeName} = getGridRowData<T>(params);
        const isEditable = (params.colDef as EditableColDef)?.isEditable;
        const isEditDisabled = getEditDisabledState(model, isEditable, isReadonly);
        return (
            <EditableText
                model={model}
                value={defaultValue}
                action={saveAction}
                messageType={MessageType.FieldMessage}
                disabled={isEditDisabled}
                field={field}
                typeName={typeName}
                editStateTrigger={EditStateTrigger.WrappedComponent}
                id={id}
            />
        );
    };
