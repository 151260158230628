import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {Box, Divider} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {SquareIconButton} from '@components/button/Buttons';
import {CustomIcon} from '@components/icons';
import {MarkdownViewer} from '@components/rich-text-editor/MarkdownViewer';
import {CustomTheme, SystemColorsEnum} from '@style';
import {formatTimestamp} from '@utils';

import {MemoizedChip, MemoizedLabelChip} from '../chip/ChipRenderer';
import {CircleBoxWithText} from '../circle-box/CircleBoxWithText';
import {dashCharacter as emptyCellCharacter} from '../label';

import {Attachments} from './Attachments';
import {Attachment, Timestamp} from './types';

export type CommentItemProps = {
    chipValue: string;
    chipName: string;
    authorName: string;
    markdown: string;
    attachments: Attachment[];
    date: Timestamp;
    id: string;
    entityId: string;
    isPinned: boolean;
    isPinDisabled?: boolean;
    onPin: () => void;
};

export const useClasses = makeStyles()((theme: CustomTheme) => ({
    commentItem: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        background: theme.palette.background.paper,
        padding: theme.spacing(2.5, 2),
    },
    commentItemPinned: {
        background: theme.palette.primary.light,
    },
    commentItemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    commentItemDivider: {
        margin: theme.spacing(0, 2),
    },
    commentItemEditorWrapper: {
        margin: theme.spacing(1, 0),
    },
    commentItemBottomInformations: {
        display: 'flex',
        gap: theme.spacing(1.25),
        alignItems: 'center',
        marginTop: theme.spacing(1.5),
        flexWrap: 'wrap',
    },
    commentItemDateInformation: {
        color: theme.palette.secondary.main,
    },
}));

export const CommentItem = ({
    chipValue,
    chipName,
    authorName,
    entityId,
    markdown,
    attachments,
    date,
    isPinned,
    isPinDisabled,
    onPin,
}: CommentItemProps) => {
    const {classes, cx} = useClasses();

    return (
        <>
            <Divider className={classes.commentItemDivider} variant="fullWidth" />
            <Box
                className={cx(classes.commentItem, {
                    [classes.commentItemPinned]: isPinned,
                })}
            >
                <Box className={classes.commentItemHeader}>
                    <CircleBoxWithText
                        letter={authorName?.charAt(0)?.toUpperCase() ?? emptyCellCharacter}
                        color={SystemColorsEnum.LightBlue}
                        size="medium"
                        text={authorName}
                        textBold
                    />
                    <SquareIconButton
                        iconClassName={isPinned ? CustomIcon.UnpinOutline : CustomIcon.PinOutline}
                        iconFontSize={12}
                        type="button"
                        onClick={onPin}
                        disabled={isPinDisabled}
                    >
                        <ClearIcon />
                    </SquareIconButton>
                </Box>
                <Box className={classes.commentItemEditorWrapper}>
                    <MarkdownViewer markdown={markdown} />
                </Box>
                <Attachments attachments={attachments?.map(i => ({...i, posted_at: date}))} />
                <Box className={classes.commentItemBottomInformations}>
                    <MemoizedChip value={chipValue} chipName={chipName} />
                    <MemoizedLabelChip value={entityId} />
                    <Box component="span" className={classes.commentItemDateInformation}>
                        {formatTimestamp(date, 'date-time')}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
