import React from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {Box} from '@mui/material';
import moment from 'moment';

import {EditableDate} from '@components/editable/EditableDate';
import {FormError, RuleType, useValidationFormatter} from '@components/input';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {Locale} from '@components/types';
import {Timestamp} from '@models/generated/graphql';
import {customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {formatDate, momentToTimestampSeconds, timestampSecondsToDate} from '@utils';

import {useMessageFormatter} from '../../app/intl/hooks';
import {LackOfPermissionIndicator, useMultiplePermissions, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {UserProfileModel} from '../../player-actions/pii-actions/types';
import {useReduxForm} from '../../shared/form/hooks';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    dateOfBirth: {
        id: 'UserProfileBirthday_dateOfBirth',
        defaultMessage: 'D.O.B.',
    },
    yearsOld: {
        id: 'UserProfileBirthday_yearsOld',
        defaultMessage: 'Years',
    },
});

type UserPersonalInfoProps = UserProfileModel & {
    disabled?: boolean;
    locale: Locale;
};

const UserProfileBirthdayInternal = (props: UserPersonalInfoProps) => {
    const isEditable = useMultiplePermissions({allowedPermissions: customerSupportUpdatePermissions});
    const validationMessageFormatter = useValidationFormatter();
    const formatter = useMessageFormatter();
    const {formatMessage} = useIntl();

    const {control, state, submit, cancel, handleSubmit} = useReduxForm<Omit<UserProfileModel, 'userId'>, UserProfileModel>({
        initialModel: props,
        asyncAction: personalInfoActions.editDOB,
        map: m => ({
            user: {
                uid: props?.userId,
                birthday: m?.user?.birthday as Timestamp,
            },
            userId: props?.userId,
        }),
    });

    const formatDateValue = (value: Date) => {
        const formattedDate = formatDate(value, 'date', false, false);
        const years = moment().diff(value, 'years', false);
        const formattedYears = `${years} ${formatMessage(localized.yearsOld)}`;
        return value ? `${formattedDate}, ${formattedYears}` : emptyCellCharacter;
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                render={({field, fieldState}) => (
                    <Box>
                        <EditableDate
                            mode={state.isProgress ? 'InProgress' : 'Read'}
                            value={timestampSecondsToDate((field.value as Timestamp)?.seconds)}
                            formatter={formatDateValue}
                            onChange={value => {
                                const updatedValue = {
                                    seconds: momentToTimestampSeconds(moment(value)),
                                };
                                field.onChange(updatedValue);
                            }}
                            onSave={() => handleSubmit(submit)}
                            onCancel={cancel}
                            placeholder={formatter(localized.dateOfBirth)}
                            disabled={!isEditable || props.disabled}
                            locale={props.locale}
                        />
                        {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                    </Box>
                )}
                control={control}
                name="user.birthday"
                defaultValue={props.user?.birthday}
                rules={{
                    required: validationMessageFormatter(RuleType.Required, localized.dateOfBirth),
                }}
            ></Controller>
        </form>
    );
};

export const UserProfileBirthday = withMultiplePermission(UserProfileBirthdayInternal, {
    allowedPermissions: customerSupportUpdatePermissions,
    indicator: LackOfPermissionIndicator.Disabled,
});
