import React from 'react';

import {QueryFilterAdapterModel, QueryFilterAdapterProps, withQueryFilter} from '@components/filter';
import {LoginStatus} from '@models/generated/graphql';
import {UserLoginServerFilterKeys, UserLoginServerTextFilterKeys} from '@redux/entity';
import {getMirrorObject} from '@utils/object';

import {UserLoginFilterModel, UserLoginFilterName, UserLoginTextFilterKeys} from '../types';

import {UserLoginFilters} from './UserLoginFilters';

type UserLoginQueryFilterAdapterProps = QueryFilterAdapterProps<
    UserLoginServerFilterKeys,
    UserLoginFilterName,
    UserLoginServerTextFilterKeys
>;

export function UserLoginQueryFilterAdapter({model, onChange, ...props}: UserLoginQueryFilterAdapterProps) {
    const clientTextKeyToServer: Record<UserLoginTextFilterKeys, UserLoginServerTextFilterKeys> = {
        uid_username_ip_deviceId_country_isp_connectionType: 'uidUsernameIpDeviceIdCountryIspConnectionType',
        uid: 'uid',
        username: 'username',
        ip: 'ip',
        deviceId: 'device.uuid',
        country: 'location.country',
        isp: 'isp',
        connectionType: 'connection_type',
    };
    const serverTextKeyToClient: Record<UserLoginServerTextFilterKeys, UserLoginTextFilterKeys> = getMirrorObject(clientTextKeyToServer);

    const filterModel: UserLoginFilterModel = {
        text: model?.text ? {...model?.text, option: serverTextKeyToClient[model?.text?.option]} : null,
        status: model?.login_status as LoginStatus[],
        loginDate: {
            from: model?.['logged_at_ts.from'] as number,
            to: model?.['logged_at_ts.to'] as number,
        },
        logoutDate: {
            from: model?.['logged_out_at_ts.from'] as number,
            to: model?.['logged_out_at_ts.to'] as number,
        },
    };

    function handleChange(model: UserLoginFilterModel) {
        const result: QueryFilterAdapterModel<UserLoginServerFilterKeys, UserLoginServerTextFilterKeys> = {
            text: model?.text ? {...model?.text, option: clientTextKeyToServer[model?.text?.option]} : undefined,
            login_status: model?.status?.length ? model?.status : undefined,
            'logged_at_ts.from': model?.loginDate?.from,
            'logged_at_ts.to': model?.loginDate?.to,
            'logged_out_at_ts.from': model?.logoutDate?.from,
            'logged_out_at_ts.to': model?.logoutDate?.to,
        };
        onChange(result);
    }

    return <UserLoginFilters model={filterModel} onChange={handleChange} {...props} />;
}

export const UserLoginQueryFilters = withQueryFilter<UserLoginServerFilterKeys, UserLoginFilterName, UserLoginServerTextFilterKeys>(
    UserLoginQueryFilterAdapter,
    {
        uid_username_ip_deviceId_country_isp_connectionType: [
            'uidUsernameIpDeviceIdCountryIspConnectionType',
            'uid',
            'username',
            'ip',
            'device.uuid',
            'location.country',
            'isp',
            'connection_type',
        ],
    }
);
