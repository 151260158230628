import {defineMessages} from 'react-intl';
import {GridValueGetterParams} from '@mui/x-data-grid';

import {renderChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import {
    DataGridEntityColDef,
    DataGridEntityConfigFactory,
    MuiDataGridServer,
    renderCellHeader,
    renderCellWithTooltip,
    renderColoredChipCell,
    withDataGridEntityServer,
} from '@components/data-grid/mui';
import {dashCharacter, notAvailableCharacter} from '@components/label';
import {ConnectionType} from '@config/connectionType';
import {LoginStatus} from '@models/generated/graphql';
import {formatTimestamp} from '@utils/date';

import {UserLoginViewModel, UserLoginViewModelKeys} from '../types';

export const localizedUserLoginGrid = defineMessages<UserLoginViewModelKeys>({
    uid: {
        id: 'DataGridUserLogin_uid',
        defaultMessage: 'Unique Id',
    },
    username: {
        id: 'DataGridUserLogin_username',
        defaultMessage: 'Username',
    },
    logged_at_ts: {
        id: 'DataGridUserLogin_logged_at_ts',
        defaultMessage: 'Login',
    },
    logged_out_at_ts: {
        id: 'DataGridUserLogin_logoutDate',
        defaultMessage: 'Logout',
    },
    login_status: {
        id: 'DataGridUserLogin_status',
        defaultMessage: 'Status',
    },
    ip: {
        id: 'DataGridUserLogin_ip',
        defaultMessage: 'IP Address',
    },
    session_id: {
        id: 'DataGridUserLogin_sessionId',
        defaultMessage: 'Session Id',
    },
    isp: {
        id: 'DataGridUserLogin_isp',
        defaultMessage: 'ISP',
    },
    connection_type: {
        id: 'DataGridUserLogin_connectionType',
        defaultMessage: 'Connection Type',
    },
    id: {
        id: 'DataGridUserLogin_id',
        defaultMessage: 'ID',
    },
    'location.country': {
        id: 'DataGridUserLogin_country',
        defaultMessage: 'Country',
    },
    'location.state': {
        id: 'DataGridUserLogin_state',
        defaultMessage: 'State',
    },
    'location.city': {
        id: 'DataGridUserLogin_city',
        defaultMessage: 'City',
    },
    'device.model': {
        id: 'DataGridUserLogin_deviceModel',
        defaultMessage: 'Device Model',
    },
    'device.uuid': {
        id: 'DataGridUserLogin_deviceId',
        defaultMessage: 'Device Id',
    },
    'device.name': {
        id: 'DataGridUserLogin_deviceName',
        defaultMessage: 'Device Name',
    },
    'device.operating_system': {
        id: 'DataGridUserLogin_os',
        defaultMessage: 'OS',
    },
    'device.mac_address': {
        id: 'DataGridUserLogin_macAddress',
        defaultMessage: 'MAC Address',
    },
});

class DataGridUserLoginConfigFactory extends DataGridEntityConfigFactory<UserLoginViewModelKeys, UserLoginViewModel> {
    getColumnConfig(): Partial<Record<UserLoginViewModelKeys, DataGridEntityColDef<UserLoginViewModelKeys, UserLoginViewModel>>> {
        return {
            id: {
                field: 'id',
                hide: true,
            },
            uid: {
                field: 'uid',
                valueGetter: p => this.getUid(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.uid),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            username: {
                field: 'username',
                valueGetter: p => this.getUsername(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.username),
                renderCell: renderCellWithTooltip,
                width: 200,
            },
            logged_at_ts: {
                field: 'logged_at_ts',
                valueGetter: p => this.getLoggedAt(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.logged_at_ts),
                renderCell: renderColoredChipCell,
                width: 200,
            },
            logged_out_at_ts: {
                field: 'logged_out_at_ts',
                valueGetter: p => this.getLoggedOutAt(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.logged_out_at_ts),
                renderCell: renderColoredChipCell,
                width: 200,
            },
            login_status: {
                field: 'login_status',
                valueGetter: p => this.getLoginStatus(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.login_status),
                renderCell: params => renderChip<LoginStatus>(params.value as LoginStatus, nameof<LoginStatus>()),
                width: 150,
            },
            ip: {
                field: 'ip',
                valueGetter: p => this.getIp(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.ip),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            isp: {
                field: 'isp',
                valueGetter: p => this.getIsp(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.isp),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            connection_type: {
                field: 'connection_type',
                valueGetter: p => this.getConnectionType(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.connection_type),
                renderCell: params => renderChip<ConnectionType>(params.value as ConnectionType, nameof<ConnectionType>()),
                width: 200,
            },
            'location.country': {
                field: 'location.country',
                valueGetter: p => this.getCountry(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['location.country']),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            'location.state': {
                field: 'location.state',
                valueGetter: p => this.getState(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['location.state']),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            'location.city': {
                field: 'location.city',
                valueGetter: p => this.getCity(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['location.city']),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            'device.mac_address': {
                field: 'device.mac_address',
                valueGetter: p => this.getDeviceMacAddress(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['device.mac_address']),
                renderCell: renderCellWithTooltip,
                width: 180,
            },
            'device.model': {
                field: 'device.model',
                valueGetter: p => this.getDeviceModel(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['device.model']),
                renderCell: renderCellWithTooltip,
                width: 200,
            },
            'device.uuid': {
                field: 'device.uuid',
                valueGetter: p => this.getDeviceUuid(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['device.uuid']),
                renderCell: renderCellWithTooltip,
                width: 200,
            },
            'device.name': {
                field: 'device.name',
                valueGetter: p => this.getDeviceName(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['device.name']),
                renderCell: renderCellWithTooltip,
                width: 200,
            },
            'device.operating_system': {
                field: 'device.operating_system',
                valueGetter: p => this.getDeviceOS(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid['device.operating_system']),
                renderCell: renderCellWithTooltip,
                width: 150,
            },
            session_id: {
                field: 'session_id',
                valueGetter: p => this.getSessionId(p),
                renderHeader: () => renderCellHeader(localizedUserLoginGrid.session_id),
                renderCell: renderCellWithTooltip,
                width: 290,
            },
        };
    }

    private getUid(p: GridValueGetterParams) {
        return this.getRow(p)?.uid;
    }

    private getUsername(p: GridValueGetterParams) {
        return this.getRow(p)?.username;
    }

    private getLoggedAt(p: GridValueGetterParams) {
        const item = this.getRow(p);
        return {
            value: formatTimestamp(item?.logged_at_ts, 'date-time'),
            variant: item.login_status === 'Success' ? ChipVariant.Green : ChipVariant.Grey,
            type: ChipType.Status,
        };
    }

    private getLoggedOutAt(p: GridValueGetterParams) {
        const item = this.getRow(p);
        return {
            value:
                item.login_status === 'Success'
                    ? item?.logged_out_at_ts?.seconds
                        ? formatTimestamp(item?.logged_out_at_ts, 'date-time')
                        : dashCharacter
                    : notAvailableCharacter,
            variant: item?.logged_out_at_ts?.seconds ? ChipVariant.Green : ChipVariant.Grey,
            type: ChipType.Status,
        };
    }

    private getLoginStatus(p: GridValueGetterParams) {
        return this.getRow(p)?.login_status;
    }

    private getIp(p: GridValueGetterParams) {
        return this.getRow(p)?.ip;
    }

    private getIsp(p: GridValueGetterParams) {
        return this.getRow(p)?.isp;
    }

    private getConnectionType(p: GridValueGetterParams) {
        return this.getRow(p)?.connection_type;
    }

    private getCountry(p: GridValueGetterParams) {
        return this.getRow(p)?.location?.country;
    }

    private getState(p: GridValueGetterParams) {
        return this.getRow(p)?.location?.state;
    }

    private getCity(p: GridValueGetterParams) {
        return this.getRow(p)?.location?.city;
    }

    private getDeviceMacAddress(p: GridValueGetterParams) {
        return this.getRow(p)?.device?.mac_address;
    }

    private getDeviceModel(p: GridValueGetterParams) {
        return this.getRow(p)?.device?.model;
    }

    private getDeviceUuid(p: GridValueGetterParams) {
        return this.getRow(p)?.device?.uuid;
    }

    private getDeviceName(p: GridValueGetterParams) {
        return this.getRow(p)?.device?.name;
    }

    private getDeviceOS(p: GridValueGetterParams) {
        return this.getRow(p)?.device?.operating_system;
    }

    private getSessionId(p: GridValueGetterParams) {
        return this.getRow(p)?.session_id;
    }
}

export const DateGridUserLoginServer = withDataGridEntityServer<UserLoginViewModelKeys, UserLoginViewModel>(MuiDataGridServer, () =>
    new DataGridUserLoginConfigFactory().getColumnConfig()
);
