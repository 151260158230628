import React, {FC} from 'react';

import {isStringNullOrEmpty} from '@utils';

type CheckValue<TValue> = {
    value: TValue;
};

export const dashCharacter = '—';
export const notAvailableCharacter = 'N/A';

export type DefaultSymbol = 'none' | 'invisible' | 'dash' | 'N/A';

type EmptyLabelProps = {
    defaultSymbol: DefaultSymbol;
};

export function EmptyLabel(props: EmptyLabelProps) {
    const symbol = props?.defaultSymbol ?? 'none';
    const symbolMap: Record<DefaultSymbol, string> = {
        dash: dashCharacter,
        invisible: '‍',
        none: '',
        'N/A': notAvailableCharacter,
    };
    return <>{symbolMap[symbol]}</>;
}

export const withEmptyCheck =
    <TValue, TProps extends CheckValue<TValue>>(WrappedComponent: FC<TProps>) =>
    (props: TProps) => {
        const {value} = props;
        const isEmpty = isEmptyCellValue<TValue>(value);
        return isEmpty ? <EmptyLabel defaultSymbol="dash" /> : <WrappedComponent {...props} />;
    };

export function isEmptyCellValue<TValue>(value: TValue) {
    return (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value?.length === 0) ||
        (typeof value === 'string' && isStringNullOrEmpty(value)) ||
        (typeof value === 'number' && isNaN(value))
    );
}

export const TextWithEmptyCheck = withEmptyCheck(({value}: CheckValue<string>) => <>{value}</>);
