import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {useService} from '@inversify';
import {EntityType} from '@redux/entity';

import {downloadActions, IDownloadStrategy} from '../block-download';
import {useAsyncActionState} from '../shared/async-action/hooks';

import {BeBonusHistoryColumnsConfigurationKeys} from './types';

const localized = defineMessages<BeBonusHistoryColumnsConfigurationKeys>({
    withBonusId: {
        id: 'useBonusCodeHistoryDownload_withBonusId',
        defaultMessage: 'Bonus id',
    },
    withCreateTime: {
        id: 'useBonusCodeHistoryDownload_withCreateTime',
        defaultMessage: 'Transaction Date',
    },
    withEmail: {
        id: 'useBonusCodeHistoryDownload_withEmail',
        defaultMessage: 'Email',
    },
    withTransactionAmount: {
        id: 'useBonusCodeHistoryDownload_withTransactionAmount',
        defaultMessage: 'Paid Value',
    },
    withTriggerBy: {
        id: 'useBonusCodeHistoryDownload_withTriggerBy',
        defaultMessage: 'Triggered By',
    },
    withTriggerByUid: {
        id: 'useBonusCodeHistoryDownload_withTriggerByUid',
        defaultMessage: 'Triggered by UID',
    },
    withType: {
        id: 'useBonusCodeHistoryDownload_withType',
        defaultMessage: 'Type',
    },
    withUid: {
        id: 'useBonusCodeHistoryDownload_withUid',
        defaultMessage: 'UID',
    },
    withUnrealizedAmount: {
        id: 'useBonusCodeHistoryDownload_withUnrealizedAmount',
        defaultMessage: 'Unrealized Value',
    },
    withUsername: {
        id: 'useBonusCodeHistoryDownload_withUsername',
        defaultMessage: 'Username',
    },
});

export type UseBonusCodeHistoryDownloadProps = {
    bonusId: string;
    keys: BeBonusHistoryColumnsConfigurationKeys[];
    filter: string;
    filename: MessageDescriptor;
    total: number;
};

// TODO refactor hook to use view model keys
export function useBonusCodeHistoryDownload({bonusId, filter, keys, filename, total}: UseBonusCodeHistoryDownloadProps) {
    const dispatch = useDispatch();
    const {isProgress} = useAsyncActionState(downloadActions.download);
    const {formatMessage} = useIntl();

    const downloadStrategy = useService<IDownloadStrategy<BeBonusHistoryColumnsConfigurationKeys>>('BonusCodeHistoryDownloadStrategy');
    const headers: Record<BeBonusHistoryColumnsConfigurationKeys, string> = keys?.reduce((result, key) => {
        result[key] = formatMessage(localized[key]);
        return result;
    }, {} as Record<BeBonusHistoryColumnsConfigurationKeys, string>);

    function handleClick() {
        dispatch(
            downloadActions.download.request({
                keys: {[EntityType.BonusCode]: keys},
                worksheetKeys: keys.filter(k => k !== 'withTriggerByUid'),
                filter,
                headers,
                total,
                filename: formatMessage(filename, {bonusId}),
                strategy: downloadStrategy,
            })
        );
    }

    return {handleClick, isProgress};
}
