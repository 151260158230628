import React, {useCallback, useEffect, useRef, useState} from 'react';
import {defineMessages} from 'react-intl';

import {defineAccessControlledResource, PoliciesAccessRule, PolicyRestrictedResource} from '@access-control';
import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {FilterWithPlacement} from '@components/filter/types';
import {LayoutQueue} from '@components/layout';
import PageHeader from '@components/page/PageHeader';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {Policy} from '@auth';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay} from '@redux/view';

import {useBoUsers} from 'src/features/block-bo-user';
import {DataGridBonusCode} from 'src/features/block-bonus-code/components/DataGridBonusCode';
import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    BonusCodeDownloadButton,
    BonusCodeQueryFilters,
    BonusEngineAddBonusCode,
    BulkBonusEngineButton,
    DataGridBonusCodeKeys,
    useBonusCodes,
} from '../features/block-bonus-code';

const localized = defineMessages({
    bonusEngineHeader: {
        id: 'BE_bonusEngineHeader',
        defaultMessage: 'Bonus Engine',
    },
});

export function BonusEnginePage() {
    const keys: BonusCodeDetailsViewModelKeys[] = [
        'bonus_id',
        'bonus_name',
        'credit_type',
        'total_paid_out_amount',
        'create_time.seconds',
        'bonus_status',
        'description',
        'created_by',
    ];
    const columns: DataGridBonusCodeKeys[] = [...keys, 'deactivate_action', 'details_action'];
    const {
        items: bonusCodes,
        totalCount,
        handleFilterChange,
        filterString,
        searchFilter,
        handlePageChange,
        handlePageSizeChange,
        handleSortChange,
    } = useBonusCodes({
        viewType: 'BonusEngine',
        fields: keys,
        realtimeMode: RealtimeUpdatesMode.Confirm,
        triggers: [{type: RealtimeMessageTrigger.Add}],
        syncWithUrl: true,
        validateFilter: null,
        defaultSorting: [{field: 'create_time.seconds', sort: 'desc'}],
        cleanDelay: extendedViewCleanDelay,
    });
    const {items: boUsers} = useBoUsers({
        viewType: 'BonusEngine',
        fields: ['id', 'email'],
        validateFilter: null,
        ids: [...new Set(bonusCodes?.map(i => i.created_by))],
    });

    const items: BonusCodeDetailsViewModel[] = bonusCodes.map(bc => ({
        ...bc,
        created_by: bc.created_by?.length ? boUsers.find(bu => bu.id === bc.created_by)?.email : null,
    }));
    const {selectedIds, handleSelect} = useDataGridSelectedIds();

    const isRowSelectable = useCallback((model: BonusCodeDetailsViewModel): boolean => {
        const isSelectable = model?.bonus_status === BonusStatus.Active && model?.credit_type === BonusCreditType.MttToken;
        return isSelectable;
    }, []);

    const [primaryNode, setPrimaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();
    const [secondaryNode, setSecondaryNode] = useState<Omit<FilterWithPlacement, 'filterName'>>();

    const primaryRef = useRef<HTMLDivElement>();
    const secondaryRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (primaryRef.current) {
            setPrimaryNode({nodeId: 'primary', node: primaryRef.current});
        }
        if (secondaryRef.current) {
            setSecondaryNode({nodeId: 'secondary', node: secondaryRef.current});
        }
    }, []);

    return (
        <LayoutQueue
            header={
                <PageHeader
                    header={localized.bonusEngineHeader}
                    actions={
                        <>
                            <BonusEngineAddBonusCode />
                            <BulkBonusEngineButton selectedIds={selectedIds} />
                            <BonusCodeDownloadButton
                                keys={keys}
                                filter={filterString}
                                total={totalCount}
                                filename={localized.bonusEngineHeader}
                            />
                        </>
                    }
                />
            }
            toolbar={
                <>
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched ref={primaryRef} />
                            <ToolbarItems secondary ref={secondaryRef} />
                        </ToolbarItemsContainer>
                    </Toolbar>
                    <BonusCodeQueryFilters
                        model={filterString}
                        onChange={handleFilterChange}
                        availableFilters={[
                            {
                                filterName: 'id_name',
                                ...primaryNode,
                            },
                            {
                                filterName: 'credit_type',
                                ...primaryNode,
                            },
                            {
                                filterName: 'bonus_status',
                                ...primaryNode,
                            },
                            {
                                filterName: 'created_by',
                                ...primaryNode,
                            },
                            {
                                filterName: 'create_time',
                                ...secondaryNode,
                            },
                        ]}
                    />
                </>
            }
            body={
                <DataGridBonusCode
                    selection={{
                        disabled: false,
                        selectedIds,
                        idGetter: m => m.bonus_id,
                    }}
                    columns={columns}
                    rows={items}
                    paging={{
                        rowCount: totalCount,
                        page: searchFilter?.paging.page,
                        pageSize: searchFilter?.paging.pageSize,
                    }}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortChange={handleSortChange}
                    sortModel={{sortModel: searchFilter?.sorting}}
                    onSelect={handleSelect}
                    isRowSelectable={isRowSelectable}
                />
            }
        />
    );
}

export const bonusEngineResource: PolicyRestrictedResource = defineAccessControlledResource({
    name: 'BonusEngine',
    rules: new PoliciesAccessRule([new Policy(ModuleName.MarketingFunction, null, 'read')]),
});
