import React, {useEffect, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {StyledComponentProps} from '@mui/material/styles';

import {withInfoMessage, WithInfoMessageProps} from '@components/alerts/InfoMessageHoc';
import {MessageOrientation} from '@components/alerts/Message';
import {Autocomplete, AutocompleteOptionType} from '@components/autocomplete';
import LocalizedText from '@components/i18n/LocalizedText';
import {withStyledInputLabel} from '@components/input';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {EntityType} from '@redux/entity';
import {extendedViewCleanDelay, useViewInit, ViewType} from '@redux/view';
import {isStringNullOrEmpty} from '@utils';

import {Filter} from 'src/common/types';

import {useClasses} from './ViewAutocomplete.style';

export type ViewAutocompleteProps<TFilter extends Partial<Record<string, unknown>>, TEntity> = WithInfoMessageProps & {
    viewType: ViewType;
    entityType: EntityType;
    displayName: MessageDescriptor;
    fields: string[];
    value: TEntity | TEntity[];
    filter?: TFilter;
    filterKey?: string;
    optionsLimit?: number;
    placeholder?: MessageDescriptor;
    isDisabled?: boolean;
    onValueChange?: (value: string | string[]) => void;
    onOptionChange?: (value: AutocompleteOptionType | AutocompleteOptionType[]) => void;
    onOptionsChange?: (options: AutocompleteOptionType[], inputValue?: string) => void;
    hasLargeOptions?: boolean;
    errorMessage?: string;
    errorMessageOrientation?: MessageOrientation;
    optionMapper: (value: TEntity) => AutocompleteOptionType;
    valueMapper: (value: TEntity) => string;
    filterMapper?: (value: string) => string;
    validateFilter: (filter: Filter<string, string>[]) => boolean;
    mode: 'client' | 'server';
    multiple?: boolean;
    loading?: boolean;
};

export const ViewAutocomplete = <TFilter extends Partial<Record<string, unknown>>, TEntity>({
    viewType,
    entityType,
    displayName,
    fields,
    optionMapper,
    value,
    filter,
    filterKey,
    filterMapper,
    valueMapper,
    validateFilter,
    isDisabled,
    placeholder,
    optionsLimit = 20,
    onValueChange,
    onOptionChange,
    onOptionsChange,
    mode,
    hasLargeOptions,
    errorMessage,
    errorMessageOrientation,
    multiple,
    loading,
}: ViewAutocompleteProps<TFilter, TEntity> & StyledComponentProps) => {
    const {classes} = useClasses();

    const [inputValue, setInputValue] = useState<string>('');
    const [isReadyLoadData, setIsReadyLoadData] = useState<boolean>(false);
    const {
        items,
        handleFilterChange: handleViewEntityFilterChange,
        viewEntity: {status},
    } = useViewInit<TEntity, string, string>({
        viewType,
        displayName,
        entity: {
            entity: entityType,
            fields,
        },
        defaultPaging: {page: 1, pageSize: optionsLimit},
        defaultFilters: [{key: filterKey, value: filter?.[filterKey]}],
        cleanDelay: extendedViewCleanDelay,
        validateFilter: filter => isReadyLoadData && validateFilter && validateFilter(filter),
    });

    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const autocompleteValue = multiple ? (value as TEntity[])?.map(valueMapper) : valueMapper(value as TEntity);
    const {options, selectedOption} = getOptions(value);

    useEffect(() => {
        setIsReadyLoadData(true);

        return () => {
            setIsReadyLoadData(false);
            handleFilterInit();
        };
    }, []);

    useEffect(() => {
        handleFilterInit();
    }, [isReadyLoadData, autocompleteValue]);

    useEffect(() => {
        if (onOptionsChange) {
            onOptionsChange(options, inputValue);
        }
    }, [options?.map(o => o.value)?.join()]);

    function handleValueChange(option: AutocompleteOptionType | AutocompleteOptionType[]) {
        if (onValueChange) {
            const value = multiple ? (option as AutocompleteOptionType[])?.map(o => o.value) : (option as AutocompleteOptionType)?.value;
            onValueChange(value ?? null);
        }

        if (onOptionChange) {
            onOptionChange(option);
        }
    }

    function handleFilterChange(value: string) {
        if (mode === 'server') {
            handleViewEntityFilterChange([
                {
                    key: filterKey,
                    value: filterMapper ? filterMapper(value) : value,
                },
            ]);
        }

        setHasChanges(value !== autocompleteValue);
        setInputValue(value);
    }

    function handleFilterInit() {
        handleViewEntityFilterChange([
            {
                key: filterKey,
                value: filter?.[filterKey],
            },
        ]);

        setHasChanges(filter?.[filterKey] !== autocompleteValue);
    }

    function getOptions(value: TEntity | TEntity[]) {
        let allOptions: AutocompleteOptionType[] = [];
        if (!items?.length && value && !hasChanges) {
            allOptions = multiple ? (value as TEntity[])?.map(optionMapper) : [optionMapper(value as TEntity)];
        } else {
            allOptions = items?.reduce((result: AutocompleteOptionType[], i) => {
                const option = optionMapper(i);
                return option?.value ? [...result, option] : result;
            }, []) as AutocompleteOptionType[];
        }
        return {
            options: allOptions?.sort((a, b) => a.label?.localeCompare(b.label)),
            selectedOption: getSelectedOption(allOptions, value),
        };
    }

    function getSelectedOption(
        options: AutocompleteOptionType[],
        value: TEntity | TEntity[]
    ): AutocompleteOptionType | AutocompleteOptionType[] {
        let result: AutocompleteOptionType | AutocompleteOptionType[];
        if (multiple) {
            const optionValues = (value as TEntity[])?.map(o => valueMapper(o));
            result = [];
            optionValues?.forEach(o => {
                (result as AutocompleteOptionType[])?.push(options?.find(i => i.value === o));
            });
            result = options?.filter(i => optionValues?.includes(i.value));
        } else {
            result = options?.find(i => i.value === valueMapper(value as TEntity));
        }
        return result;
    }

    function getDisableLabel(selectedOption: AutocompleteOptionType) {
        return !isStringNullOrEmpty(selectedOption?.label) ? selectedOption?.label : emptyCellCharacter;
    }

    function getMultipleModeDisableLabel(selectedOptions: AutocompleteOptionType[]) {
        return selectedOptions?.length ? selectedOptions?.map(o => o.label).join() : emptyCellCharacter;
    }

    const loadingValue =
        loading || ((value as TEntity[])?.length && !(selectedOption as AutocompleteOptionType[])?.length) || status === 'inProgress';

    return isDisabled ? (
        <LocalizedText
            label={
                multiple
                    ? getMultipleModeDisableLabel(selectedOption as AutocompleteOptionType[])
                    : getDisableLabel(selectedOption as AutocompleteOptionType)
            }
        />
    ) : (
        <Autocomplete
            multiple={multiple}
            dataTestId={viewType}
            classes={{
                root: classes.viewAutocomplete,
                popupIndicator: classes.viewAutocompletePopupIndicator,
            }}
            value={autocompleteValue}
            options={options}
            onValueChange={handleValueChange}
            onFilterChange={handleFilterChange}
            placeholder={placeholder ?? displayName}
            optionsLimit={optionsLimit}
            allowEmptyValue
            defaultOption={selectedOption}
            errorMessage={errorMessage}
            loading={loadingValue}
            errorMessageOrientation={errorMessageOrientation}
            hasLargeOptions={hasLargeOptions}
            clientFilter={mode === 'client'}
            useValueAsDefaultInputValue={true}
        />
    );
};

export const StyledViewAutocomplete = withStyledInputLabel(ViewAutocomplete);

export const ViewAutocompleteWithInfoMessage = withInfoMessage(ViewAutocomplete);
