import {useContext} from 'react';
import {IntlContext} from 'react-intl';

import {ChipKeyValue} from '@components/chip/SquaredChipList';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {SecurityCase} from '@models/generated/graphql';

import {localizedSecurityCaseType} from '../../app/intl/shared-resources/securityCaseType';

export type SecurityCaseHistoryValue = {
    security_cases: SecurityCase[];
};

export const useSecurityCasesFormatter = (json: string): ChipKeyValue[] => {
    const {formatMessage} = useContext(IntlContext);
    const value: SecurityCaseHistoryValue = JSON.parse(json);
    const formatSecurityCase = (securityCase: SecurityCase): ChipKeyValue => {
        if (securityCase) {
            const formattedCaseType =
                securityCase.type && localizedSecurityCaseType[securityCase.type]
                    ? formatMessage(localizedSecurityCaseType[securityCase.type])
                    : securityCase?.type ?? emptyCellCharacter;

            return {key: formattedCaseType, value: securityCase.case_id};
        }

        return {key: emptyCellCharacter};
    };

    return value?.security_cases?.map(formatSecurityCase) ?? [];
};
