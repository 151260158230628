import React from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';

import {
    DateRangeFilter,
    DateRangeFilterValue,
    FilterGroupNew,
    FilterGroupNewFilter,
    FilterProps,
    FiltersProps,
    SelectFilter,
} from '@components/filter';
import {TextInputWithDropdownFilter} from '@components/filter/TextFilterWithDropdown';
import {LoginStatus} from '@models/generated/graphql';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {localizedLoginStatus} from '../../app/intl/shared-resources/loginStatus';
import {SelectOption} from '../../module-shared/types';
import {UserLoginFilterModel, UserLoginFilterName, UserLoginTextFilterKeys, UserLoginTextFilterValue} from '../types';

const localized = defineMessages({
    textFullFilter: {
        id: 'UserLoginFilters_textFullFilter',
        defaultMessage: 'All',
    },
    userIdFilter: {
        id: 'UserLoginFilters_userIdFilter',
        defaultMessage: 'User ID',
    },
    usernameFilter: {
        id: 'UserLoginFilters_usernameFilter',
        defaultMessage: 'Username',
    },
    ipFilter: {
        id: 'UserLoginFilters_ipFilter',
        defaultMessage: 'IP Address',
    },
    deviceFilter: {
        id: 'UserLoginFilters_deviceFilter',
        defaultMessage: 'Device ID',
    },
    countryFilter: {
        id: 'UserLoginFilters_countryFilter',
        defaultMessage: 'Country',
    },
    loginStatusFilter: {
        id: 'UserLoginFilters_statusFilter',
        defaultMessage: 'Log-in Status',
    },
    ispFilter: {
        id: 'UserLoginFilters_ispFilter',
        defaultMessage: 'ISP',
    },
    connectionTypeFilter: {
        id: 'UserLoginFilters_connectionTypeFilter',
        defaultMessage: 'Connection Type',
    },
    textFullFilterPlaceholder: {
        id: 'UserLoginFilters_textFullFilterPlaceholder',
        defaultMessage: 'Search in Login History',
    },
    userIdFilterPlaceholder: {
        id: 'UserLoginFilters_userIdFilterPlaceholder',
        defaultMessage: '123',
    },
    usernameFilterPlaceholder: {
        id: 'UserLoginFilters_usernameFilterPlaceholder',
        defaultMessage: 'For example, Antonio',
    },
    ipFilterPlaceholder: {
        id: 'UserLoginFilters_ipFilterPlaceholder',
        defaultMessage: 'For example, 192.158.1.38',
    },
    deviceFilterPlaceholder: {
        id: 'UserLoginFilters_deviceFilterPlaceholder',
        defaultMessage: 'For example, d41d8cd98f00b204e9800998ecf8427e',
    },
    countryFilterPlaceholder: {
        id: 'UserLoginFilters_countryFilterPlaceholder',
        defaultMessage: 'For example, Ireland',
    },
    loginFilterPlaceholder: {
        id: 'UserLoginFilter_loginFilterPlaceholder',
        defaultMessage: 'Select Login Date Range',
    },
    logoutFilterPlaceholder: {
        id: 'UserLoginFilter_logoutFilterPlaceholder',
        defaultMessage: 'Select Logout Date Range',
    },
    ispFilterPlaceholder: {
        id: 'UserLoginFilters_ispFilterPlaceholder',
        defaultMessage: 'For example, Amazon.com',
    },
    connectionTypeFilterPlaceholder: {
        id: 'UserLoginFilters_connectionTypeFilterPlaceholder',
        defaultMessage: 'For example, Hosting',
    },
});

function UserLoginTextFilter(props: FilterProps<UserLoginTextFilterValue>) {
    const placeholderMapper: Record<UserLoginTextFilterKeys, MessageDescriptor> = {
        uid_username_ip_deviceId_country_isp_connectionType: localized.textFullFilterPlaceholder,
        uid: localized.userIdFilterPlaceholder,
        username: localized.usernameFilterPlaceholder,
        ip: localized.ipFilterPlaceholder,
        deviceId: localized.deviceFilterPlaceholder,
        country: localized.countryFilterPlaceholder,
        isp: localized.ispFilterPlaceholder,
        connectionType: localized.connectionTypeFilterPlaceholder,
    };
    const options: SelectOption<UserLoginTextFilterKeys>[] = [
        {label: localized.textFullFilter, value: 'uid_username_ip_deviceId_country_isp_connectionType'},
        {label: localized.userIdFilter, value: 'uid'},
        {label: localized.usernameFilter, value: 'username'},
        {label: localized.ipFilter, value: 'ip'},
        {label: localized.deviceFilter, value: 'deviceId'},
        {label: localized.countryFilter, value: 'country'},
        {label: localized.ispFilter, value: 'isp'},
        {label: localized.connectionTypeFilter, value: 'connectionType'},
    ];

    return (
        <TextInputWithDropdownFilter
            options={options}
            inputPlaceholder={props?.value?.option ? placeholderMapper[props?.value?.option] : localized.textFullFilterPlaceholder}
            width="full"
            {...props}
        />
    );
}

function UserLoginStatusFilter(props: FilterProps<string>) {
    return (
        <SelectFilter
            multiple
            label={localized.loginStatusFilter}
            {...props}
            chipType={nameof<LoginStatus>()}
            options={Object.values(LoginStatus).map(t => ({label: localizedLoginStatus[t], value: t}))}
        />
    );
}

function UserLoginDateFilter(props: FilterProps<DateRangeFilterValue> & {placeholder: MessageDescriptor}) {
    const {locale} = useJurisdictionConfig();

    return <DateRangeFilter {...props} placeholder={localized.loginFilterPlaceholder} locale={locale} />;
}

function UserLogoutDateFilter(props: FilterProps<DateRangeFilterValue> & {placeholder: MessageDescriptor}) {
    const {locale} = useJurisdictionConfig();

    return <DateRangeFilter {...props} placeholder={localized.logoutFilterPlaceholder} locale={locale} />;
}

type UserLoginFiltersProps = FiltersProps<UserLoginFilterModel, UserLoginFilterName>;

export function UserLoginFilters({model, onChange, availableFilters}: UserLoginFiltersProps) {
    const filters: FilterGroupNewFilter<UserLoginFilterModel, UserLoginFilterName>[] = [
        {
            filterName: 'uid_username_ip_deviceId_country_isp_connectionType',
            modelField: 'text',
            component: UserLoginTextFilter,
            collapseOnMobile: false,
        },
        {
            filterName: 'status',
            modelField: 'status',
            component: UserLoginStatusFilter,
            collapseOnMobile: false,
        },
        {
            filterName: 'loginDate',
            modelField: 'loginDate',
            component: UserLoginDateFilter,
            collapseOnMobile: false,
        },
        {
            filterName: 'logoutDate',
            modelField: 'logoutDate',
            component: UserLogoutDateFilter,
            collapseOnMobile: false,
        },
    ];

    return (
        <FilterGroupNew
            model={model}
            onChange={onChange}
            mode="update-on-change"
            allFilters={filters}
            availableFilters={availableFilters}
        />
    );
}
