import 'moment-duration-format';
import 'moment/locale/zh-cn';
import moment, {Moment} from 'moment-timezone';

import {Timestamp} from '@models/generated/graphql';

import {Filter} from '../types';

export function getUTCDateWithLocalTimezone(m: Moment): Date {
    const utc = m.utc();
    return new Date(utc.year(), utc.month(), utc.date());
}

export function getLocalDateWithoutTimezone(m: Moment): Date {
    return m.local().utcOffset(0, true).toDate();
}

export function getTodayAsDate() {
    return getUTCDateWithLocalTimezone(moment());
}

export function getUTCTimestamp(): Timestamp {
    return {seconds: moment.utc().unix()};
}

export function isCurrentDate(date: Date) {
    const endOfToday = moment.utc().endOf('day');
    const endOfDate = moment(getLocalDateWithoutTimezone(moment(date))).endOf('day');

    return endOfToday.isSame(endOfDate, 'day');
}

export function secondsSinceEpoch(date: Date) {
    return Math.floor(date.getTime() / 1000);
}

export function isRecent(date: Timestamp, minutes: number) {
    return date ? moment().diff(moment.unix(date.seconds), 'minutes') < minutes : false;
}

export const endDateNow = 'now';

export function totalHoursPassedFromNow(date: string): string {
    if (date === endDateNow) return '0';
    return date ? `${moment().diff(moment.unix(Number(date)), 'hours')}` : '';
}

export function getNHoursAgoAsSeconds(hours: number | string): number {
    return moment()
        .subtract(Number(`${hours}`), 'h')
        .unix();
}

export function isDiffNHours(from: number, to: number, nHours: number) {
    return Math.abs(moment.unix(from).diff(moment.unix(to), 'hours')) === nHours;
}

export function timestampSecondsToMoment(seconds: number | string): Moment {
    return seconds ? moment.unix(Number(`${seconds}`)) : null;
}

export function timestampSecondsToDate(seconds: number | string): Date {
    return timestampSecondsToMoment(seconds)?.toDate();
}

export function momentToTimestampSeconds(date: Moment) {
    return date?.unix();
}

export function formatElapsedTime(minutes: number | string) {
    return moment.duration(minutes, 'minutes').humanize();
}

export function formatTimestamp(
    timestamp: Timestamp,
    format: 'date' | 'time' | 'date-time' | 'date-time-with-seconds',
    withTimeZone = true
) {
    let result: string = null;
    const seconds = timestamp?.seconds;

    if (seconds) {
        const formattedDate = formatMomentDate(moment.unix(seconds).local(true), format);
        result = withTimeZone ? `${formattedDate} ${moment().zoneAbbr()}` : formattedDate;
    }

    return result;
}

export function formatDate(
    date: Date | Moment,
    format: 'date' | 'time' | 'date-time' | 'date-time-with-seconds',
    isLocal = true,
    withTimeZone = true
) {
    let result: string = null;

    if (date) {
        result = formatMomentDate(moment(date).local(isLocal), format, isLocal);
        result = withTimeZone ? `${result} ${moment().zoneAbbr()}` : result;
    }
    return result;
}

export function formatDayOfWeek(date: Date, format: 'dd' | 'ddd' | 'dddd') {
    return date ? moment(date).utcOffset(-date.getTimezoneOffset()).format(format) : '';
}

export function getFormatTemplate(format: 'date' | 'time' | 'date-time') {
    let result: string = null;
    const localeData = moment.localeData();

    switch (format) {
        case 'date':
            result = localeData.longDateFormat('ll');
            break;
        case 'time':
            result = localeData.longDateFormat('LTS');
            break;
        case 'date-time':
        default:
            result = localeData.longDateFormat('lll');
            break;
    }

    return result;
}

function formatMomentDate(date: Moment, format: 'date' | 'time' | 'date-time' | 'date-time-with-seconds', isLocal = true) {
    let result: string = null;

    switch (format) {
        case 'date':
            result = date.local(isLocal).format('ll');
            break;
        case 'time':
            result = date.local(isLocal).format('LTS');
            break;
        case 'date-time-with-seconds':
            result = date.local(isLocal).format('MMM DD, YYYY h:mm:ss A');
            break;
        case 'date-time':
        default:
            result = date.local(isLocal).format('lll');
            break;
    }

    return result;
}

export const sortByTimestamp = (first: Timestamp, second: Timestamp) => {
    let result: number;
    if (!first?.seconds) {
        result = -1;
    } else if (!second?.seconds) {
        result = 1;
    } else {
        result = first.seconds - second.seconds;
    }

    return result;
};

export const sortByDate = (first: Date, second: Date) => {
    let result: number;
    if (!first) {
        result = -1;
    } else if (!second) {
        result = 1;
    } else {
        result = first.getTime() - second.getTime();
    }

    return result;
};

const getUtcDate = (date: Date | Moment) => {
    return date ? moment.utc(date) : null;
};

const getLocalDate = (date: Date) => {
    return date ? moment(date.toDateString()).local() : null;
};

export const getStartOfDate = (dateLocal: Date) => {
    const localStartDate = getLocalDate(dateLocal)?.startOf('day');
    const res = getUtcDate(localStartDate);
    return res;
};

export const getEndOfDate = (dateLocal: Date) => {
    const localEndDate = getLocalDate(dateLocal)?.endOf('day');
    return getUtcDate(localEndDate);
};

export const addDaysToDate = (date: Date, days: number) => {
    const result = new Date(date);
    const newDate = date.getDate() + days;
    result.setDate(newDate);
    return result;
};

export function formatDateRange(fromLocal: Date, toLocal: Date) {
    let res = '';
    const from = fromLocal ? getStartOfDate(fromLocal) : null;
    const to = toLocal ? getEndOfDate(toLocal) : null;
    const fromFormatted = from ? formatDate(from, 'date', false) : null;
    const toFormatted = to ? formatDate(to, 'date', false) : null;

    if (fromFormatted && toFormatted) {
        if (fromFormatted === toFormatted) {
            res = `${fromFormatted}`;
        } else {
            res = `${fromFormatted} - ${toFormatted}`;
        }
    } else if (toFormatted) {
        res = `${toFormatted}`;
    } else if (from) {
        res = `${fromFormatted}`;
    }

    return res;
}

export const setMomentRelativeThresholds = () => {
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 30);
    moment.relativeTimeThreshold('M', 12);
    moment.relativeTimeThreshold('ss', 0);
};

export const convertDaysToSeconds = (days: number) => {
    return 24 * 60 * 60 * days;
};

export const convertSecondsToDays = (seconds: number) => {
    return Math.ceil(seconds / 24 / 60 / 60);
};

export function isFutureDate(date: string | Date) {
    return moment(date).diff(moment()) > 0;
}

export function isValidDataRangeFilter(filter: Filter[], prefix: string, validIfNotExist?: boolean): boolean {
    const fromValue = filter?.find(f => f.key === `${prefix}.from`)?.value;
    const toValue = filter?.find(f => f.key === `${prefix}.to`)?.value;
    return fromValue ? (Number(fromValue) && Number(toValue) ? fromValue <= toValue : true) : !!validIfNotExist;
}

export function getLastNDaysDateRange(rangePeriodDays: number): [startDate: number, endDate: string] {
    const now = new Date();
    const startDate = getUtcDate(addDaysToDate(now, -rangePeriodDays))?.startOf('day');
    const startDateSeconds = momentToTimestampSeconds(startDate);

    return [startDateSeconds, endDateNow];
}

export function getTodayDateRange(): [startDate: number, endDate: string] {
    return getLastNDaysDateRange(0);
}

export function getLastWeekDateRange(): [startDate: number, endDate: number] {
    const rangePeriodDays = 6;

    let endOfWeek;
    const now = new Date();
    const dayOfWeek = getUtcDate(now).day();
    const isSunday = dayOfWeek === 0;
    if (!isSunday) {
        endOfWeek = addDaysToDate(now, -dayOfWeek);
    } else {
        endOfWeek = now;
    }
    const startDate = getUtcDate(addDaysToDate(endOfWeek, -rangePeriodDays))?.startOf('day');
    const startDateSeconds = momentToTimestampSeconds(startDate);
    const endDate = getUtcDate(endOfWeek)?.endOf('day');
    const endDateSeconds = momentToTimestampSeconds(endDate);

    return [startDateSeconds, endDateSeconds];
}

export function getStartOfWeek(subtractedWeeks = 0): moment.Moment {
    const startWeek = moment().subtract(moment.duration(subtractedWeeks, 'weeks'));
    const dayOfWeek = startWeek.isoWeekday();
    return startWeek.add(dayOfWeek >= 1 ? -(dayOfWeek - 1) : -6, 'day').startOf('day');
}

export function getEndOfWeek(subtractedWeeks = 0): moment.Moment {
    const startOfWeek = getStartOfWeek(subtractedWeeks);
    return startOfWeek.add(6, 'day').endOf('day');
}
