import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {AutocompleteOptionType, InlineAutocomplete} from '@components/autocomplete';
import {getNonEmptyValueValidator} from '@redux/view';

import {useBoUsers} from '../hooks';

const localized = defineMessages({
    placeholder: {
        id: 'BoUserAutocomplete_placeholder',
        defaultMessage: 'Enter email',
    },
    emptyPlaceholder: {
        id: 'BoUserAutocomplete_placeholder',
        defaultMessage: 'Start typing email',
    },
});

const useStyles = makeStyles()(() => ({
    boUserAutocompleteContainer: {
        minHeight: '170px',
    },
}));

type BoUserAutocompleteProps = {
    value?: string;
    onChange: ({id, email}: {id: string; email: string}) => void;
};

export function BoUserAutocomplete({value, onChange}: BoUserAutocompleteProps) {
    const {classes} = useStyles();
    const {items, handleFilterChange} = useBoUsers({
        viewType: 'BoUserAutocomplete',
        syncWithUrl: false,
        fields: ['id', 'email'],
        defaultPaging: {
            page: 1,
            pageSize: 10,
        },
        ids: value ? [value] : undefined,
        validateFilter: value ? () => true : getNonEmptyValueValidator('email'),
    });

    function handleAutocompleteFilterChange(value: string) {
        handleFilterChange([
            {
                key: 'id',
                value: null,
            },
            {
                key: 'email',
                value,
            },
        ]);
    }

    function handleChange(value: AutocompleteOptionType) {
        onChange({id: value?.value ?? null, email: value?.label});
    }

    return (
        <div className={classes.boUserAutocompleteContainer}>
            <InlineAutocomplete
                value={value}
                options={items?.map(i => ({
                    label: i.email,
                    value: i.id,
                }))}
                onFilterChange={handleAutocompleteFilterChange}
                onValueChange={handleChange}
                allowEmptyValue
                useValueAsDefaultInputValue
                mode={'server'}
                placeholder={localized.placeholder}
                emptyPlaceholder={localized.emptyPlaceholder}
            />
        </div>
    );
}
