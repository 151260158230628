import React, {useContext, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {defineMessages, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box, List, ListItem, ListItemText} from '@mui/material';
import merge from 'deepmerge';

import Button, {OutlinedButton} from '@components/button/Buttons';
import {FormTextInputDefault, RuleType, useValidationFormatter} from '@components/input';
import {ModalContext} from '@components/modal';
import {
    BonusCodeInput,
    BonusCreditType,
    BonusReleaseMechanism,
    BonusReleaseMetric,
    BonusReleaseRatioType,
    BonusStatus,
    BonusToCredit,
    Platform,
} from '@models/generated/graphql';
import {useAuthUser} from '@auth';
import {getUTCTimestamp} from '@utils';

import {beBonusActions} from '../../be-bonus/actions';
import {useAsyncActionState} from '../../shared/async-action/hooks';

import {localizedBonusEngineForm} from './BonusEngineForm.localize';
import {useBonusEngineFormClasses} from './BonusEngineForm.style';

const localized = defineMessages({
    bonusId: {
        id: 'BEMTT_bonusId',
        defaultMessage: 'Bonus ID',
    },
    bonusName: {
        id: 'BEMTT_bonusName',
        defaultMessage: 'Bonus Name',
    },
    placeholderBonusName: {
        id: 'BEMTT_placeholderBonusName',
        defaultMessage: 'e.g., 47GQGCHU',
    },
    bonusDescription: {
        id: 'BEMTT_bonusDescription',
        defaultMessage: 'Bonus Description',
    },
    placeholderBonusDescription: {
        id: 'BEMTT_placeholderBonusDescription',
        defaultMessage: 'Description',
    },
    ticketSerialID: {
        id: 'BEMTT_ticketSerialID',
        defaultMessage: 'Ticket Serial ID',
    },
    placeholderTicketSerialID: {
        id: 'BEMTT_placeholderTicketSerialID',
        defaultMessage: 'Serial ID',
    },
});

const mttTicketStartTimeDefault = 1640139000; //Wednesday, December 22, 2021 2:10:00 AM
const mttTicketEndTimeDefault = 2147483000; //Tuesday, January 19, 2038 3:03:20 AM
const mttTicketExpirePeriodDefault = 8601000;
export const getMTTTicketDefaults = (uid: string): BonusCodeInput => {
    const timestamp = getUTCTimestamp();

    return {
        bonus_name: '',
        platform: Platform.Wpto,
        bonus_status: BonusStatus.Active,
        create_time: timestamp,
        start_time: {
            seconds: mttTicketStartTimeDefault,
        },
        end_time: {
            seconds: mttTicketEndTimeDefault,
        },
        credit_type: BonusCreditType.MttToken,
        bonus_to_credit: BonusToCredit.Amount,
        release_mechanism: BonusReleaseMechanism.Immediate,
        release_metric: BonusReleaseMetric.None,
        release_ratio_type: BonusReleaseRatioType.Fixed,
        meta: {
            expire_period: mttTicketExpirePeriodDefault,
            max_bonus_get: 1,
            bonus_value_param: 1,
            total_max_bonus_get: 1,
            show_max_bonus_in_total: 1,
        },
        created_by: uid,
    };
};

type BonusEngineMTTTicketFormProps = {
    initialModel?: BonusCodeInput;
    mode?: 'create' | 'read';
};

export const BonusEngineMTTTicketForm = ({initialModel, mode = 'create'}: BonusEngineMTTTicketFormProps) => {
    const {classes} = useBonusEngineFormClasses();
    const {sub} = useAuthUser();
    const dispatch = useDispatch();
    const {reset, control, handleSubmit} = useForm<BonusCodeInput>({
        defaultValues: initialModel,
    });
    const validationFormatter = useValidationFormatter();
    const {requestStatus, isProgress} = useAsyncActionState(beBonusActions.addBonusCode);
    const isInputDisabled = mode === 'read' || isProgress;
    const {closeModal} = useContext(ModalContext);
    const {formatMessage} = useIntl();
    const onConfirmClicked = (newItem: BonusCodeInput) => {
        const mttTicketDefaults = getMTTTicketDefaults(sub);
        const payload: BonusCodeInput = merge(mttTicketDefaults, newItem);

        dispatch(beBonusActions.addBonusCode.request({bonus: payload}));
        closeModal();
    };

    useEffect(() => {
        if (requestStatus) {
            reset();
        }
    }, [requestStatus]);

    return (
        <Box data-testid="addMTTTicketForm">
            <List classes={{padding: classes.bonusEngineFormModalList}}>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText className={classes.bonusEngineFormModalListItemText} primary={formatMessage(localized.bonusName)} />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderBonusName}
                                disabled={isInputDisabled}
                            />
                        )}
                        name="bonus_name"
                        control={control}
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.bonusName),
                        }}
                    />
                </ListItem>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText
                        className={classes.bonusEngineFormModalListItemText}
                        primary={formatMessage(localized.bonusDescription)}
                    />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderBonusDescription}
                                disabled={isInputDisabled}
                            />
                        )}
                        name="description"
                        control={control}
                    />
                </ListItem>
                <ListItem className={classes.bonusEngineFormModalListItem}>
                    <ListItemText className={classes.bonusEngineFormModalListItemText} primary={formatMessage(localized.ticketSerialID)} />
                    <Controller
                        render={({field, fieldState}) => (
                            <FormTextInputDefault
                                key={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                fieldState={fieldState}
                                hideLabel={true}
                                placeholder={localized.placeholderTicketSerialID}
                                disabled={isInputDisabled}
                            />
                        )}
                        name="meta.ticket_serial"
                        control={control}
                        rules={{
                            required: validationFormatter(RuleType.Required, localized.ticketSerialID),
                        }}
                    />
                </ListItem>
            </List>

            <Box className={classes.bonusEngineFormActionButtons}>
                <Button
                    className={classes.bonusEngineFormAddActionFooterButton}
                    color="primary"
                    label={localizedBonusEngineForm.submit}
                    onClick={handleSubmit(onConfirmClicked)}
                    disabled={isInputDisabled}
                />
                <OutlinedButton
                    className={classes.bonusEngineFormAddActionFooterButton}
                    label={localizedBonusEngineForm.close}
                    onClick={closeModal}
                />
            </Box>
        </Box>
    );
};
