import React, {useEffect, useState} from 'react';
import equal from 'fast-deep-equal/es6';

import {isStringNullOrEmpty} from '@utils/string';

import {useDebouncedCallback} from 'src/common/hooks/debounce';

import {AutocompleteOptionType, AutocompleteOptionValue, AutocompleteStateProps} from './types';

type AutocompleteState = {
    open: boolean;
    showInputIcon: boolean;
    selectedOption: AutocompleteOptionValue;
    inputValue: string;
    optionsInternal: AutocompleteOptionType[];
    autocompleteState: string;
    handleFilterChange: (value: string) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    handleAutocompleteChange: (event: React.ChangeEvent<{}>, selected: string | AutocompleteOptionType) => void;
    handleOpen: (event: React.ChangeEvent<{}>) => void;
    handleClose: (event: React.ChangeEvent<{}>) => void;
    handleClear: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleBlur: (event: React.ChangeEvent<{}>) => void;
    handleKeyDown: (event: React.ChangeEvent<{}>) => void;
};

export function useAutocompleteState<TValue>({
    defaultOption,
    value,
    options,
    onValueChange,
    onFilterChange,
    allowEmptyValue,
    allowFreeInputValue,
    multiple,
    isAlwaysOpen,
    useValueAsDefaultInputValue,
    mode,
}: AutocompleteStateProps<TValue>): AutocompleteState {
    const [open, setOpen] = useState(isAlwaysOpen ?? false);
    const [showInputIcon, setShowInputIcon] = useState(false);

    const emptyOption: AutocompleteOptionType = {value: '', label: ''};
    const defaultSelectedOption: AutocompleteOptionValue = defaultOption ?? (multiple ? [emptyOption] : emptyOption);
    const [optionsInternal, setOptionsInternal] = useState<AutocompleteOptionType[]>(options ?? []);
    const [selectedOption, setSelectedOption] = useState<AutocompleteOptionValue>(getSelectedOption(optionsInternal));
    const [inputValue, setInputValue] = useState<string>(getInputValue(selectedOption));

    //used to track user events taken on autocomplete and show info message based on iy
    const [autocompleteState, setAutocompleteState] = useState('');

    useDebouncedCallback(inputValue, mode === 'server' ? 500 : 0, (text: string) => {
        if (!multiple && allowFreeInputValue && text) {
            if ((selectedOption as AutocompleteOptionType)?.label !== text) {
                //set entered value as selected option for autocomplete when allowFreeInputValue enabled
                const newValue = {
                    label: text,
                    value: text,
                };
                setSelectedOption(newValue);
                handleValueChange(newValue);
            }
        }

        handleFilterChange(text);
    });
    useEffect(() => {
        const newSelectedOption = getSelectedOption(optionsInternal);
        setSelectedOption(newSelectedOption);
        //NOTE: for autocomplete with multiple prop, chips are used instead of text value
        // so no need to set input value
        if (!multiple) {
            setInputValue((newSelectedOption as AutocompleteOptionType)?.label ?? '');
        }
    }, [JSON.stringify(value)]);

    useEffect(() => {
        //NOTE: for autocomplete with multiple prop, we should subscribe to defaultOption updates because
        // badge content can be changed from the outside
        if (multiple) {
            setSelectedOption(defaultOption);
        }
    }, [JSON.stringify(defaultOption)]);

    useEffect(() => {
        setOptionsInternal(options ?? []);
        if (!multiple) {
            //NOTE: update input value if option is selected, options include it and there is no other input value
            const newSelectedOption = getSelectedOption(options);
            if (selectedOption && !equal(defaultSelectedOption, newSelectedOption) && isStringNullOrEmpty(inputValue)) {
                const newSelectedOptionLabel = (newSelectedOption as AutocompleteOptionType)?.label;
                setInputValue(newSelectedOptionLabel ?? '');
            }
            //NOTE: select option if user entered exact option value to the input
            if (options?.length) {
                const firstOption = options[0];
                const isInputValueExactOption =
                    inputValue === firstOption.value && inputValue !== (selectedOption as AutocompleteOptionType)?.value;
                if (isInputValueExactOption) {
                    setSelectedOption(firstOption);
                    handleValueChange(firstOption);
                }
            }
        }
    }, [JSON.stringify(options?.map((o): AutocompleteOptionType => ({value: o?.value, label: o?.label})))]);

    function getSelectedOption(options: AutocompleteOptionType[]): AutocompleteOptionValue {
        let result: AutocompleteOptionValue;
        if (multiple) {
            const selectedOptions = options?.filter(i => (value as TValue[]).includes(i.value));
            result = selectedOptions?.length ? selectedOptions : defaultSelectedOption;
        } else {
            result = options?.find(i => i.value === value) ?? defaultSelectedOption;
        }
        return result;
    }

    function getInputValue(option: AutocompleteOptionValue) {
        let inputValue = '';

        if (useValueAsDefaultInputValue && !multiple) {
            inputValue = (option as AutocompleteOptionType)?.label;
        }

        return inputValue;
    }

    function handleFilterChange(inputValue: string) {
        if (open && onFilterChange) {
            onFilterChange(inputValue);
        }
    }

    function handleInputChange(_event: React.ChangeEvent<HTMLInputElement>, value: string) {
        setInputValue(value);
    }

    function handleOpen(event: React.ChangeEvent<{}>) {
        setOpen(true);
        setAutocompleteState(event.type);
        setShowInputIcon(false);
    }

    function handleClose(event: React.ChangeEvent<{}>) {
        setOpen(false);
        setAutocompleteState(event.type);
        setShowInputIcon(true);
    }

    function handleClear(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        handleAutocompleteChange(event, null);
        handleInputChange(null, '');
        setOpen(true);
    }

    function handleKeyDown(event: React.KeyboardEvent<{}>) {
        stopEvent(event);
    }

    function handleBlur(_event: React.ChangeEvent<{}>) {
        setAutocompleteState(null);
    }

    function stopEvent(e: React.BaseSyntheticEvent) {
        (e.nativeEvent as Event).stopImmediatePropagation();
        e.stopPropagation();
    }

    /**
     * Callback fired when the value changes. Typing input does not trigger this function.
     * Only clicking suggested item, clicking one of the options and removing item trigger this function.
     * For the typing input part onInputChange is handling value changes.
     *
     * @param {object} _event The event source of the callback.
     * @param {string | AutocompleteOptionType} selected The new value of the component.
     */
    function handleAutocompleteChange(_event: React.ChangeEvent<{}>, selected: string | AutocompleteOptionType) {
        let resultValue: AutocompleteOptionValue = null;

        if (typeof selected === 'string') {
            //value is selected with "Enter", available with freeSolo
            //selected value is string means, the result value will be checked in the options list
            //if it is not in the options list, resultValue will be null which means currently no result value set
            resultValue = optionsInternal.find(o => o.label === selected);
        } else if (selected && selected.inputValue) {
            //dynamically adding value which is not in options list (suggestion)
            resultValue = {
                label: selected.inputValue,
            };
        } else {
            //when typing some inputs on autocomplete component, it shows some options in dropdown
            //clicking an option in dropdown will trigger this block
            //on the other hand clicking create new button or add new item buttons will trigger this part
            resultValue = selected;
        }

        if (resultValue || allowEmptyValue) {
            handleValueChange(resultValue);
        }

        setSelectedOption(resultValue);
        if (!multiple) {
            setInputValue(resultValue?.label ?? '');
        }

        if (!isAlwaysOpen) {
            setOpen(false);
        }
    }

    function handleValueChange(value: AutocompleteOptionType) {
        if (onValueChange) {
            onValueChange(value);
        }
    }

    return {
        open,
        showInputIcon,
        selectedOption,
        inputValue,
        optionsInternal,
        autocompleteState,
        handleFilterChange,
        handleInputChange,
        handleBlur,
        handleOpen,
        handleClose,
        handleClear,
        handleKeyDown,
        handleAutocompleteChange,
    };
}

type UseAutocompleteClientFilterResult = {
    filter: (options: AutocompleteOptionType[], filter: string) => AutocompleteOptionType[];
};

export function useAutocompleteClientFilter(): UseAutocompleteClientFilterResult {
    function filter(options: AutocompleteOptionType[], filterString: string) {
        let result: AutocompleteOptionType[] = options;

        if (!isStringNullOrEmpty(filterString)) {
            const regexString = filterString
                ?.split('')
                ?.map(s => (isNaN(Number(s)) ? `[${s.toUpperCase()}${s.toLowerCase()}]` : s))
                ?.join('');
            const regex = new RegExp(`^${regexString}.*$`);

            result = filterOptions(options, regex) ?? [];
        }

        return result;
    }

    function filterOptions(options: AutocompleteOptionType[], regex: RegExp): AutocompleteOptionType[] {
        const result: AutocompleteOptionType[] = [];
        options?.forEach(option => {
            if (matchOrMatchInSubOptions(option, regex)) {
                result.push({...option, subOptions: filterOptions(option.subOptions, regex)});
            }
        });
        return result?.length ? result : undefined;
    }

    function matchOrMatchInSubOptions(option: AutocompleteOptionType, regex: RegExp): boolean {
        return !!option?.label?.match(regex) || option?.subOptions?.some(o => matchOrMatchInSubOptions(o, regex));
    }

    return {filter};
}
