import React, {useContext} from 'react';
import {IntlContext} from 'react-intl';
import {GridCellParams} from '@mui/x-data-grid';

import SquaredChipList, {ChipKeyValue} from '@components/chip/SquaredChipList';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {TransactionHistory} from '@models/generated/graphql';
import {formatTimestamp, sortByTimestamp} from '@utils';

import {localizedTransactionStatus} from '../../app/intl/shared-resources/transactionStatus';
import {getGridRowData} from '../../module-temp-storage/components/editable/utils';
import {TransactionGridItem, transactionStatusOrder} from '../types';

type TransactionStatusHistoryProps = {
    history: TransactionHistory[];
};

const TransactionStatusHistory = ({history}: TransactionStatusHistoryProps) => {
    const {formatMessage} = useContext(IntlContext);

    const formattedHistory: ChipKeyValue[] = history.map(t => ({
        key: formatMessage(localizedTransactionStatus[t.status]),
        value: formatTimestamp(t.logged_at, 'date-time'),
    }));

    return history.length ? (
        <SquaredChipList list={formattedHistory} hideTooltip={history.length < 2} value={localizedTransactionStatus[history[0].status]} />
    ) : (
        <>{emptyCellCharacter}</>
    );
};

export const TransactionStatusAction = (params: GridCellParams) => {
    const {defaultValue} = getGridRowData<TransactionGridItem>(params);

    const sortHistory = (first: TransactionHistory, second: TransactionHistory): number => {
        let result = sortByTimestamp(second.logged_at, first.logged_at);
        if (result === 0) {
            result = transactionStatusOrder.get(first.status) - transactionStatusOrder.get(second.status);
        }

        return result;
    };

    const transactionHistory: TransactionHistory[] = defaultValue as TransactionHistory[];
    const sortedTransactionHistory = [...transactionHistory].sort(sortHistory);

    return <TransactionStatusHistory history={sortedTransactionHistory} />;
};
