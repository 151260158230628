import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ServiceTypes} from '@inversify';
import {
    BonusCodeDetails,
    BonusCodeInput,
    BulkItemInput,
    BulkMutationInput,
    BulkMutationResponse,
    Mutation,
    MutationAddBonusCodeArgs,
    MutationDeactivateBonusArgs,
    MutationTriggerPlayerBonusesArgs,
    QueryGetBonusCodesArgs,
} from '@models/generated/graphql';
import {BonusCodeQueryFields, BonusCodeServerFilterKeys, BonusCodeServerTextFilterKeys, Filter} from '@redux/entity';
import {EntityBaseGqlService, IEntityReadService} from '@services/entity';
import {ApolloClientProxy} from '@services/gql-api';
import {GqlMutationRequest, ServiceResponsePayload} from '@services/types';

import {GqlRequestBuilder} from './entity/GqlRequestBuilder';

export type DeactivateBonusServiceResponsePayload = ServiceResponsePayload<
    GqlMutationRequest<MutationDeactivateBonusArgs>,
    BonusCodeDetails
>;

export type AddBonusCodeServiceResponsePayload = ServiceResponsePayload<GqlMutationRequest, BonusCodeDetails>;

export type TriggerBonusServiceResponsePayload = ServiceResponsePayload<GqlMutationRequest, BulkMutationResponse>;

export interface IBonusCodeService extends IEntityReadService {
    deactivateBonus(args: MutationDeactivateBonusArgs): Observable<DeactivateBonusServiceResponsePayload>;

    addBonusCode(bonus: BonusCodeInput): Observable<AddBonusCodeServiceResponsePayload>;

    triggerBonusToUsers(items: BulkItemInput[]): Observable<TriggerBonusServiceResponsePayload>;
}

@injectable()
export class BonusCodeService
    extends EntityBaseGqlService<QueryGetBonusCodesArgs, BonusCodeQueryFields, BonusCodeServerFilterKeys>
    implements IBonusCodeService
{
    constructor(
        @inject(ServiceTypes.ApolloClientIGP) client: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.AutoMapper) mapper: Mapper
    ) {
        super(client, mapper, new BonusCodeRequestBuilder());
    }

    public deactivateBonus(args: MutationDeactivateBonusArgs): Observable<DeactivateBonusServiceResponsePayload> {
        return this._service
            .mutate<Mutation, MutationDeactivateBonusArgs>(this.getDeactivateBonusCodeMutation(), args)
            .pipe(map(r => ({...r, responsePayload: r?.responsePayload?.deactivateBonus ?? null})));
    }

    public addBonusCode(bonus: BonusCodeInput): Observable<AddBonusCodeServiceResponsePayload> {
        return this._service
            .mutate<Mutation, MutationAddBonusCodeArgs>(this.getAddBonusCodeMutation(), {bonus})
            .pipe(map(r => ({...r, responsePayload: r?.responsePayload?.addBonusCode ?? null})));
    }

    public triggerBonusToUsers(items: BulkItemInput[]): Observable<TriggerBonusServiceResponsePayload> {
        const bulkData: BulkMutationInput = {
            items: items,
            retries: 3,
            timeout: 10000,
        };

        return this._service
            .mutate<Mutation, MutationTriggerPlayerBonusesArgs>(this.getTriggerBonusMutation(), {bulkData})
            .pipe(map(r => ({...r, responsePayload: r?.responsePayload?.triggerPlayerBonuses})));
    }

    private getDeactivateBonusCodeMutation() {
        return gql`
            mutation DeactivateBonus($bonus_id: String!) {
                deactivateBonus(bonus_id: $bonus_id) {
                    bonus_id
                }
            }
        `;
    }

    private getAddBonusCodeMutation() {
        return gql`
            mutation AddBonusCode($bonus: BonusCodeInput!) {
                addBonusCode(bonus: $bonus) {
                    bonus_id
                }
            }
        `;
    }

    private getTriggerBonusMutation() {
        return gql`
            mutation TriggerPlayerBonuses($bulkData: BulkMutationInput!) {
                triggerPlayerBonuses(bulkData: $bulkData) {
                    id
                }
            }
        `;
    }
}

export class BonusCodeRequestBuilder extends GqlRequestBuilder<QueryGetBonusCodesArgs, BonusCodeQueryFields, BonusCodeServerFilterKeys> {
    public buildQuery(fields: BonusCodeQueryFields[]): DocumentNode {
        return gql`
            query getBonusCodes($filter: BonusCodeFilterInput, $sort: Sorting, $start: Int, $end: Int) {
                getBonusCodes(filter: $filter, sort: $sort, end: $end, start: $start)
                {
                    items {
                        bonus_id
                        bonus_name @include(if: ${this.hasField(fields, 'bonus_name')})
                        platform @include(if: ${this.hasField(fields, 'platform')})
                        bonus_status @include(if: ${this.hasField(fields, 'bonus_status')})
                        threshold @include(if: ${this.hasField(fields, 'threshold')})
                        credit_type @include(if: ${this.hasField(fields, 'credit_type')})
                        bonus_to_credit @include(if: ${this.hasField(fields, 'bonus_to_credit')})
                        release_metric @include(if: ${this.hasField(fields, 'release_metric')})
                        release_mechanism @include(if: ${this.hasField(fields, 'release_mechanism')})
                        meta @include(if: ${this.hasAnyField(fields, this.getBonusMetaQueryItems())}) {
                            bonus_value_param @include(if: ${this.hasField(fields, 'meta.bonus_value_param')})
                            expire_period @include(if: ${this.hasField(fields, 'meta.expire_period')})
                            max_bonus_get @include(if: ${this.hasField(fields, 'meta.max_bonus_get')})
                            min_deposit @include(if: ${this.hasField(fields, 'meta.min_deposit')})
                            overall_max_bonus_get @include(if: ${this.hasField(fields, 'meta.overall_max_bonus_get')})
                            rake_amount @include(if: ${this.hasField(fields, 'meta.rake_amount')})
                            rake_qualify @include(if: ${this.hasField(fields, 'meta.rake_qualify')})
                            metric_amount @include(if: ${this.hasField(fields, 'meta.metric_amount')})
                            metric_qualify @include(if: ${this.hasField(fields, 'meta.metric_qualify')})
                            show_max_bonus_in_total @include(if: ${this.hasField(fields, 'meta.show_max_bonus_in_total')})
                            ticket_serial @include(if: ${this.hasField(fields, 'meta.ticket_serial')})
                            time_range_by_date @include(if: ${this.hasField(fields, 'meta.time_range_by_date')})
                            total_max_bonus_get @include(if: ${this.hasField(fields, 'meta.total_max_bonus_get')})
                            unrealized_expire_period @include(if: ${this.hasField(fields, 'meta.unrealized_expire_period')})
                        }
                        description @include(if: ${this.hasField(fields, 'description')})
                        marketing_codes @include(if: ${this.hasField(fields, 'marketing_codes')})
                        create_time @include(if: ${this.hasField(fields, 'create_time.seconds')}) {
                            seconds
                        }
                        last_update @include(if: ${this.hasField(fields, 'last_update')}) {
                            seconds
                        }
                        start_time @include(if: ${this.hasField(fields, 'start_time')}) {
                            seconds
                        }
                        end_time @include(if: ${this.hasField(fields, 'end_time')}) {
                            seconds
                        }
                        created_by @include(if: ${this.hasField(fields, 'created_by')})
                    }
                    total_count
                }
            }
        `;
    }

    protected buildFilter(filter: Filter<BonusCodeServerFilterKeys>): Pick<QueryGetBonusCodesArgs, 'filter'> {
        return {
            filter: {
                text: this.getGQLTextFilter(
                    Object.keys(this.filterFieldsMapper).map((key: BonusCodeServerTextFilterKeys) =>
                        this.toGQLTextFilter(this.filterFieldsMapper[key], filter[key] as string)
                    )
                ),
                bonus_status: this.toGQLMultiselectFilter(filter, 'bonusStatus'),
                create_time: this.toGQLDateRange(filter['create_time.from'], filter['create_time.to']),
                credit_type: this.toGQLMultiselectFilter(filter, 'creditType'),
            },
        };
    }

    private filterFieldsMapper: Record<BonusCodeServerTextFilterKeys, string[]> = {
        bonusId: nameof.toArray<BonusCodeDetails>(m => [m.bonus_id]),
        bonusName: nameof.toArray<BonusCodeDetails>(m => [m.bonus_name]),
        idName: nameof.toArray<BonusCodeDetails>(m => [m.bonus_id, m.bonus_name]),
        createdBy: nameof.toArray<BonusCodeDetails>(m => [m.created_by]),
    };

    private getBonusMetaQueryItems(): BonusCodeQueryFields[] {
        return [
            'meta.bonus_value_param',
            'meta.expire_period',
            'meta.max_bonus_get',
            'meta.min_deposit',
            'meta.overall_max_bonus_get',
            'meta.rake_amount',
            'meta.rake_qualify',
            'meta.show_max_bonus_in_total',
            'meta.ticket_serial',
            'meta.time_range_by_date',
            'meta.total_max_bonus_get',
            'meta.unrealized_expire_period',
        ];
    }
}
