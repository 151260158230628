import {NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {catchError, Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify/inversifyTypes';
import {mergeMap} from '@otel/rxjs';
import {BoUserService} from '@services/boUserService';
import {ApolloClientProxy} from '@services/gql-api';
import {toSearchFilter} from '@utils/query';

import {downloadRequestFailedMessage, DownloadStrategyRequest, IDownloadStrategy} from 'src/features/block-download';
import {
    BeBonusHistoryColumnsConfiguration,
    BeBonusHistoryColumnsConfigurationKeys,
    BeBonusHistoryGridItem,
    BonusCodeHistoryDownloadModel,
} from '../types';

import {BeBonusHistoryService} from './BeBonusHistoryService';

@injectable()
export class BonusCodeHistoryDownloadStrategy implements IDownloadStrategy<BeBonusHistoryColumnsConfigurationKeys> {
    private readonly _mapper: Mapper;
    private readonly _bonusCodeHistoryService: BeBonusHistoryService;

    constructor(
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.ApolloClientIGP) apollo: ApolloClientProxy<NormalizedCacheObject>,
        @inject(ServiceTypes.BoUserService) boUserService: BoUserService
    ) {
        this._mapper = mapper;
        this._bonusCodeHistoryService = new BeBonusHistoryService(apollo, boUserService);
    }
    process({
        keys,
        filter,
    }: DownloadStrategyRequest<BeBonusHistoryColumnsConfigurationKeys>): Observable<
        'downloadRequestFailedMessage' | Record<keyof BeBonusHistoryColumnsConfiguration, string>[]
    > {
        const searchFilter = toSearchFilter(filter);
        return this._bonusCodeHistoryService.getItemsPage(searchFilter, keys.BonusCode).pipe(
            mergeMap(({items}) => {
                const result = items?.map(i =>
                    this._mapper.map<BeBonusHistoryGridItem, BonusCodeHistoryDownloadModel>(
                        i,
                        BeBonusHistoryGridItem,
                        nameof<BonusCodeHistoryDownloadModel>()
                    )
                );
                return of(result);
            }),
            catchError(() => of(downloadRequestFailedMessage))
        );
    }
}
