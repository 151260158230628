import {constructUsing, createMap, forMember, fromValue, mapFrom, Mapper, mapWith, mapWithArguments} from '@automapper/core';
import moment from 'moment/moment';

import {IMapping} from '@auto-mapper';
import {MultiSelectWithExcludeModel} from '@components/select';
import {CasinoGame, CasinoSourceType, CasinoSupplier} from '@models/casino-game';
import {
    BonusCasinoInput,
    BonusCasinoSourceType,
    BonusCodeDetails,
    BonusCodeInput,
    BonusCreditType,
    BonusMetaInput,
    BonusReleaseMechanism,
    BonusReleaseMetric,
    BonusReleaseRatioType,
    BonusStatus,
    BonusToCredit,
    Platform,
    TimestampInput,
} from '@models/generated/graphql';
import {Timestamp} from '@models/shared';
import {BonusCodeQueryFields} from '@redux/entity';
import {CasinoArrayFilterMode, CasinoArrayFilterWithMode} from '@services/rest-api/casinoCmsApiService';
import {convertDaysToSeconds, formatTimestamp, momentToTimestampSeconds} from '@utils';
import {convertSecondsToDays} from '@utils/date';

import {
    BonusCodeDetailsViewModel,
    BonusCodeDetailsViewModelKeys,
    BonusCodeDownloadModel,
    BonusMetaViewModel,
    CasinoGameViewModel,
    CasinoSupplierViewModel,
    DepositCashBonusFormViewModel,
} from './types';

const bonusStartTimeDefault = 1640139000; //Wednesday, December 22, 2021 2:10:00 AM
const bonusEndTimeDefault = 2147483000; //Tuesday, January 19, 2038 3:03:20 AM

export class BonusCodeMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            BonusCodeDetails,
            BonusCodeDetailsViewModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.bonus_status,
                mapFrom(m => m.bonus_status)
            ),
            forMember(
                vm => vm.bonus_to_credit,
                mapFrom(m => m.bonus_to_credit)
            ),
            forMember(
                vm => vm.create_time,
                mapFrom(m => m.create_time)
            ),
            forMember(
                vm => vm.credit_type,
                mapFrom(m => m.credit_type)
            ),
            forMember(
                vm => vm.description,
                mapFrom(m => m.description)
            ),
            forMember(
                vm => vm.end_time,
                mapFrom(m => m.end_time)
            ),
            forMember(
                vm => vm.last_update,
                mapFrom(m => m.last_update)
            ),
            forMember(
                vm => vm.marketing_codes,
                mapFrom(m => m.marketing_codes)
            ),
            forMember(
                vm => vm.meta,
                mapFrom(m => m.meta)
            ),
            forMember(
                vm => vm.platform,
                mapFrom(m => m.platform)
            ),
            forMember(
                vm => vm.release_mechanism,
                mapFrom(m => m.release_mechanism)
            ),
            forMember(
                vm => vm.release_metric,
                mapFrom(m => m.release_metric)
            ),
            forMember(
                vm => vm.release_ratio_type,
                mapFrom(m => m.release_ratio_type)
            ),
            forMember(
                vm => vm.start_time,
                mapFrom(m => m.start_time)
            ),
            forMember(
                vm => vm.threshold,
                mapFrom(m => m.threshold)
            ),
            forMember(
                vm => vm.total_paid_out_amount,
                mapFrom(m => m.total_paid_out_amount)
            ),
            forMember(
                vm => vm.created_by,
                mapFrom(m => m.created_by ?? '')
            )
        );
        createMap(
            mapper,
            CasinoGame,
            CasinoGameViewModel,
            forMember(
                vm => vm.code,
                mapFrom(m => m.code)
            ),
            forMember(
                vm => vm.supplier,
                mapFrom(m => m.supplier?.data?.attributes?.code)
            ),
            forMember(
                vm => vm.aggregator,
                mapFrom(m => m.aggregator)
            ),
            forMember(
                vm => vm.title,
                mapFrom(m => m.title)
            ),
            forMember(
                vm => vm.isActive,
                mapFrom(m => m.isActive)
            ),
            forMember(
                vm => vm.createdAt,
                mapWith(Timestamp, Date, m => new Date(m.createdAt))
            ),
            forMember(
                vm => vm.updatedAt,
                mapWith(Timestamp, Date, m => new Date(m.updatedAt))
            )
        );
        createMap(
            mapper,
            CasinoSupplier,
            CasinoSupplierViewModel,
            forMember(
                vm => vm.code,
                mapFrom(m => m.code)
            ),
            forMember(
                vm => vm.name,
                mapFrom(m => m.name)
            ),
            forMember(
                vm => vm.isActive,
                mapFrom(m => m.isActive)
            ),
            forMember(
                vm => vm.createdAt,
                mapWith(Timestamp, Date, m => new Date(m.createdAt))
            ),
            forMember(
                vm => vm.updatedAt,
                mapWith(Timestamp, Date, m => new Date(m.updatedAt))
            )
        );
        createMap(
            mapper,
            MultiSelectWithExcludeModel,
            CasinoArrayFilterWithMode,
            forMember(
                m => m.items,
                mapFrom(vm => (vm?.include?.length > 0 ? vm?.include : vm?.exclude) ?? [])
            ),
            forMember(
                m => m.mode,
                mapFrom<MultiSelectWithExcludeModel, CasinoArrayFilterWithMode, CasinoArrayFilterMode>(vm =>
                    vm?.include?.length > 0 ? 'included' : 'excluded'
                )
            )
        );

        createMap(
            mapper,
            DepositCashBonusFormViewModel,
            BonusMetaInput,
            forMember(m => m.time_range_by_date, fromValue(1)),
            forMember(
                m => m.min_deposit,
                mapFrom(vm => Number(vm.min_deposit))
            ),
            forMember(
                m => m.bonus_value_param,
                mapFrom(vm => Number(vm.bonus_value_param))
            ),
            forMember(
                m => m.max_bonus_get,
                mapFrom(vm => Number(vm.maximum_bonus))
            ),
            forMember(
                m => m.overall_max_bonus_get,
                mapFrom(vm => Number(vm.maximum_bonus))
            ),
            forMember(
                m => m.metric_qualify,
                mapFrom(vm => Number(vm.metric_qualify))
            ),
            forMember(
                m => m.metric_amount,
                mapFrom(vm => Number(vm.metric_amount))
            ),
            forMember<DepositCashBonusFormViewModel, BonusMetaInput, BonusCasinoInput>(
                m => m.casino_excluded,
                mapFrom(vm => {
                    return vm.release_metric === BonusReleaseMetric.WagerGenerated
                        ? {
                              source_types: vm?.source_types?.exclude?.map(this.mapCasinoSourceTypeEnumToServer) ?? [],
                              aggregators: vm?.aggregators?.exclude ?? [],
                              supplier_codes: vm?.suppliers?.exclude ?? [],
                              game_codes: vm?.games?.exclude ?? [],
                          }
                        : undefined;
                })
            ),
            forMember<DepositCashBonusFormViewModel, BonusMetaInput, BonusCasinoInput>(
                m => m.casino_included,
                mapFrom(vm => {
                    return vm.release_metric === BonusReleaseMetric.WagerGenerated
                        ? {
                              source_types: vm?.source_types?.include?.map(this.mapCasinoSourceTypeEnumToServer) ?? [],
                              aggregators: vm?.aggregators?.include ?? [],
                              supplier_codes: vm?.suppliers?.include ?? [],
                              game_codes: vm?.games?.include ?? [],
                          }
                        : undefined;
                })
            ),
            forMember(
                m => m.expire_period,
                mapFrom(vm => convertDaysToSeconds(Number(vm.expire_period)))
            ),
            forMember(
                m => m.unrealized_expire_period,
                mapFrom(vm => convertDaysToSeconds(Number(vm.expire_period)))
            )
        );
        createMap(
            mapper,
            DepositCashBonusFormViewModel,
            BonusCodeInput,
            forMember(
                m => m.bonus_name,
                mapFrom(vm => vm.bonus_name)
            ),
            forMember(
                m => m.description,
                mapFrom(vm => vm.description)
            ),
            forMember(m => m.bonus_status, fromValue(BonusStatus.Active)),
            forMember(m => m.platform, fromValue(Platform.Wpto)),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.create_time,
                mapFrom(() => ({seconds: momentToTimestampSeconds(moment())}))
            ),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.start_time,
                fromValue({seconds: bonusStartTimeDefault})
            ),
            forMember<DepositCashBonusFormViewModel, BonusCodeInput, TimestampInput>(
                m => m.end_time,
                fromValue({seconds: bonusEndTimeDefault})
            ),
            forMember(m => m.credit_type, fromValue(BonusCreditType.CashBonus)),
            forMember(m => m.bonus_to_credit, fromValue(BonusToCredit.PercentOfTriggerAmount)),
            forMember(m => m.release_mechanism, fromValue(BonusReleaseMechanism.AmountPerReleaseMetricAmount)),
            forMember(
                m => m.release_metric,
                mapFrom(vm => vm.release_metric)
            ),
            forMember(m => m.release_ratio_type, fromValue(BonusReleaseRatioType.Fixed)),
            forMember(
                m => m.meta,
                mapWith(BonusMetaInput, DepositCashBonusFormViewModel, vm => vm)
            ),
            forMember(
                m => m.created_by,
                mapWithArguments((_, {uid}: {uid: string}) => {
                    return uid;
                })
            )
        );
        createMap<BonusCodeDetailsViewModel, DepositCashBonusFormViewModel>(
            mapper,
            nameof<BonusCodeDetailsViewModel>(),
            nameof<DepositCashBonusFormViewModel>(),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.description,
                mapFrom(m => m.description)
            ),
            forMember(
                m => m.release_metric,
                mapFrom(vm => vm.release_metric)
            ),
            forMember(
                m => m.min_deposit,
                mapFrom(vm => vm.meta?.min_deposit?.toString())
            ),
            forMember(
                m => m.bonus_value_param,
                mapFrom(vm => vm.meta?.bonus_value_param?.toString())
            ),
            forMember(
                m => m.maximum_bonus,
                mapFrom(vm => vm.meta?.overall_max_bonus_get?.toString())
            ),
            forMember(
                m => m.metric_qualify,
                mapFrom(vm => vm.meta?.metric_qualify?.toString())
            ),
            forMember(
                m => m.metric_amount,
                mapFrom(vm => vm.meta?.metric_amount?.toString())
            ),
            forMember(
                m => m.expire_period,
                mapFrom(vm => convertSecondsToDays(vm.meta?.expire_period).toString())
            ),
            forMember(
                m => m.source_types.exclude,
                mapFrom(vm => vm.meta?.casino_excluded?.source_types?.map(this.mapCasinoSourceTypeEnumToClient) ?? [])
            ),
            forMember(
                m => m.source_types.include,
                mapFrom(vm => vm.meta?.casino_included?.source_types?.map(this.mapCasinoSourceTypeEnumToClient) ?? [])
            ),
            forMember(
                m => m.aggregators.exclude,
                mapFrom(vm => vm.meta?.casino_excluded?.aggregators?.map(this.mapCasinoSourceTypeEnumToClient) ?? [])
            ),
            forMember(
                m => m.aggregators.include,
                mapFrom(vm => vm.meta?.casino_included?.aggregators?.map(this.mapCasinoSourceTypeEnumToClient) ?? [])
            ),
            forMember(
                m => m.suppliers.exclude,
                mapFrom(vm => vm.meta?.casino_excluded?.supplier_codes ?? [])
            ),
            forMember(
                m => m.suppliers.include,
                mapFrom(vm => vm.meta?.casino_included?.supplier_codes ?? [])
            ),
            forMember(
                m => m.games.exclude,
                mapFrom(vm => vm.meta?.casino_excluded?.game_codes ?? [])
            ),
            forMember(
                m => m.games.include,
                mapFrom(vm => vm.meta?.casino_included?.game_codes ?? [])
            )
        );

        createMap<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields>(
            mapper,
            nameof<BonusCodeDetailsViewModelKeys>(),
            nameof<BonusCodeQueryFields>(),
            constructUsing(m => {
                const mapper: Record<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields> = {
                    id: 'bonus_id',
                    bonus_id: 'bonus_id',
                    bonus_name: 'bonus_name',
                    bonus_status: 'bonus_status',
                    bonus_to_credit: 'bonus_to_credit',
                    'create_time.seconds': 'create_time.seconds',
                    credit_type: 'credit_type',
                    description: 'description',
                    end_time: 'end_time',
                    last_update: 'last_update',
                    marketing_codes: 'marketing_codes',
                    'meta.bonus_value_param': 'meta.bonus_value_param',
                    'meta.casino_excluded': 'meta.casino_excluded',
                    'meta.casino_included': 'meta.casino_included',
                    'meta.expire_period': 'meta.expire_period',
                    'meta.max_bonus_get': 'meta.max_bonus_get',
                    'meta.metric_amount': 'meta.metric_amount',
                    'meta.metric_qualify': 'meta.metric_qualify',
                    'meta.min_deposit': 'meta.min_deposit',
                    'meta.overall_max_bonus_get': 'meta.overall_max_bonus_get',
                    'meta.rake_amount': 'meta.rake_amount',
                    'meta.rake_qualify': 'meta.rake_qualify',
                    'meta.release_ratios': 'meta.release_ratios',
                    'meta.show_max_bonus_in_total': 'meta.show_max_bonus_in_total',
                    'meta.ticket_serial': 'meta.ticket_serial',
                    'meta.time_range_by_date': 'meta.time_range_by_date',
                    'meta.total_max_bonus_get': 'meta.total_max_bonus_get',
                    'meta.unrealized_expire_period': 'meta.unrealized_expire_period',
                    platform: 'platform',
                    release_mechanism: 'release_mechanism',
                    release_metric: 'release_metric',
                    release_ratio_type: 'release_ratio_type',
                    start_time: 'start_time',
                    threshold: 'threshold',
                    total_paid_out_amount: 'total_paid_out_amount',
                    created_by: 'created_by',
                };

                return mapper[m];
            })
        );
        createMap(
            mapper,
            BonusCodeDetails,
            BonusCodeDownloadModel,
            forMember(
                vm => vm.id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_id,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                vm => vm.bonus_name,
                mapFrom(m => m.bonus_name)
            ),
            forMember(
                vm => vm.bonus_status,
                mapFrom(m => m.bonus_status)
            ),
            forMember(
                vm => vm.bonus_to_credit,
                mapFrom(m => m.bonus_to_credit)
            ),
            forMember(
                vm => vm['create_time.seconds'],
                mapFrom(m => formatTimestamp(m.create_time, 'date-time'))
            ),
            forMember(
                vm => vm.credit_type,
                mapFrom(m => m.credit_type)
            ),
            forMember(
                vm => vm.description,
                mapFrom(m => m.description)
            ),
            forMember(
                vm => vm.end_time,
                mapFrom(m => formatTimestamp(m.end_time, 'date-time'))
            ),
            forMember(
                vm => vm.last_update,
                mapFrom(m => formatTimestamp(m.last_update, 'date-time'))
            ),
            forMember(
                vm => vm.marketing_codes,
                mapFrom(m => m.marketing_codes?.join())
            ),
            forMember(
                vm => vm.platform,
                mapFrom(m => m.platform)
            ),
            forMember(
                vm => vm.release_mechanism,
                mapFrom(m => m.release_mechanism)
            ),
            forMember(
                vm => vm.release_metric,
                mapFrom(m => m.release_metric)
            ),
            forMember(
                vm => vm.release_ratio_type,
                mapFrom(m => m.release_ratio_type)
            ),
            forMember(
                vm => vm.start_time,
                mapFrom(m => formatTimestamp(m.start_time, 'date-time'))
            ),
            forMember(
                vm => vm.threshold,
                mapFrom(m => m.threshold?.toString())
            ),
            forMember(
                vm => vm.total_paid_out_amount,
                mapFrom(m => m.total_paid_out_amount?.toString())
            ),
            forMember(
                vm => vm.created_by,
                mapWithArguments((_m, {email}) => email)
            )
        );

        createMap<BonusMetaViewModel, BonusMetaInput>(
            mapper,
            nameof<BonusMetaViewModel>(),
            nameof<BonusMetaInput>(),
            forMember(
                m => m.bonus_value_param,
                mapFrom(vm => vm.bonus_value_param)
            ),
            forMember(
                m => m.casino_excluded,
                mapFrom(vm => vm.casino_excluded)
            ),
            forMember(
                m => m.casino_included,
                mapFrom(vm => vm.casino_included)
            ),
            forMember(
                m => m.expire_period,
                mapFrom(vm => vm.expire_period)
            ),
            forMember(
                m => m.max_bonus_get,
                mapFrom(vm => vm.max_bonus_get)
            ),
            forMember(
                m => m.metric_amount,
                mapFrom(vm => vm.metric_amount)
            ),
            forMember(
                m => m.metric_qualify,
                mapFrom(vm => vm.metric_qualify)
            ),
            forMember(
                m => m.min_deposit,
                mapFrom(vm => vm.min_deposit)
            ),
            forMember(
                m => m.overall_max_bonus_get,
                mapFrom(vm => vm.overall_max_bonus_get)
            ),
            forMember(
                m => m.rake_amount,
                mapFrom(vm => vm.rake_amount)
            ),
            forMember(
                m => m.rake_qualify,
                mapFrom(vm => vm.rake_qualify)
            ),
            forMember(
                m => m.release_ratios,
                mapFrom(vm => vm.release_ratios)
            ),
            forMember(
                m => m.show_max_bonus_in_total,
                mapFrom(vm => vm.show_max_bonus_in_total)
            ),
            forMember(
                m => m.ticket_serial,
                mapFrom(vm => vm.ticket_serial)
            ),
            forMember(
                m => m.time_range_by_date,
                mapFrom(vm => vm.time_range_by_date)
            ),
            forMember(
                m => m.total_max_bonus_get,
                mapFrom(vm => vm.total_max_bonus_get)
            ),
            forMember(
                m => m.unrealized_expire_period,
                mapFrom(vm => vm.unrealized_expire_period)
            )
        );

        createMap<BonusMetaInput, BonusMetaViewModel>(
            mapper,
            nameof<BonusMetaInput>(),
            nameof<BonusMetaViewModel>(),
            forMember(
                m => m.bonus_value_param,
                mapFrom(vm => vm.bonus_value_param)
            ),
            forMember(
                m => m.casino_excluded,
                mapFrom(vm => vm.casino_excluded)
            ),
            forMember(
                m => m.casino_included,
                mapFrom(vm => vm.casino_included)
            ),
            forMember(
                m => m.expire_period,
                mapFrom(vm => vm.expire_period)
            ),
            forMember(
                m => m.max_bonus_get,
                mapFrom(vm => vm.max_bonus_get)
            ),
            forMember(
                m => m.metric_amount,
                mapFrom(vm => vm.metric_amount)
            ),
            forMember(
                m => m.metric_qualify,
                mapFrom(vm => vm.metric_qualify)
            ),
            forMember(
                m => m.min_deposit,
                mapFrom(vm => vm.min_deposit)
            ),
            forMember(
                m => m.overall_max_bonus_get,
                mapFrom(vm => vm.overall_max_bonus_get)
            ),
            forMember(
                m => m.rake_amount,
                mapFrom(vm => vm.rake_amount)
            ),
            forMember(
                m => m.rake_qualify,
                mapFrom(vm => vm.rake_qualify)
            ),
            forMember(
                m => m.release_ratios,
                mapFrom(vm => vm.release_ratios)
            ),
            forMember(
                m => m.show_max_bonus_in_total,
                mapFrom(vm => vm.show_max_bonus_in_total)
            ),
            forMember(
                m => m.ticket_serial,
                mapFrom(vm => vm.ticket_serial)
            ),
            forMember(
                m => m.time_range_by_date,
                mapFrom(vm => vm.time_range_by_date)
            ),
            forMember(
                m => m.total_max_bonus_get,
                mapFrom(vm => vm.total_max_bonus_get)
            ),
            forMember(
                m => m.unrealized_expire_period,
                mapFrom(vm => vm.unrealized_expire_period)
            )
        );

        createMap<BonusCodeDetailsViewModel, BonusCodeInput>(
            mapper,
            nameof<BonusCodeDetailsViewModel>(),
            nameof<BonusCodeInput>(),
            forMember(
                m => m.bonus_name,
                mapFrom(vm => vm.bonus_name)
            ),
            forMember(
                m => m.bonus_status,
                mapFrom(vm => vm.bonus_status)
            ),
            forMember(
                m => m.bonus_to_credit,
                mapFrom(vm => vm.bonus_to_credit)
            ),
            forMember(
                m => m.create_time,
                mapFrom(vm => vm.create_time)
            ),
            forMember(
                m => m.credit_type,
                mapFrom(vm => vm.credit_type)
            ),
            forMember(
                m => m.description,
                mapFrom(vm => vm.description)
            ),
            forMember(
                m => m.meta,
                mapWith(nameof<BonusMetaViewModel>(), nameof<BonusMetaInput>(), vm => vm.meta)
            ),
            forMember(
                m => m.platform,
                mapFrom(vm => vm.platform)
            ),
            forMember(
                m => m.release_mechanism,
                mapFrom(vm => vm.release_mechanism)
            ),
            forMember(
                m => m.release_metric,
                mapFrom(vm => vm.release_metric)
            ),
            forMember(
                m => m.release_ratio_type,
                mapFrom(vm => vm.release_ratio_type)
            ),
            forMember(
                m => m.start_time,
                mapFrom(vm => vm.start_time)
            ),
            forMember(
                m => m.threshold,
                mapFrom(vm => vm.threshold)
            ),
            forMember(
                m => m.created_by,
                mapFrom(vm => vm.created_by)
            ),
            forMember(
                m => m.end_time,
                mapFrom(vm => vm.end_time)
            )
        );
    }

    private mapCasinoSourceTypeEnumToServer(type: CasinoSourceType): BonusCasinoSourceType {
        const sourceTypeMap: Record<CasinoSourceType, BonusCasinoSourceType> = {
            [CasinoSourceType.Internal]: BonusCasinoSourceType.Internal,
            [CasinoSourceType.External]: BonusCasinoSourceType.External,
        };

        return sourceTypeMap[type];
    }

    private mapCasinoSourceTypeEnumToClient(type: BonusCasinoSourceType): CasinoSourceType {
        const sourceTypeMap: Record<BonusCasinoSourceType, CasinoSourceType> = {
            [BonusCasinoSourceType.Internal]: CasinoSourceType.Internal,
            [BonusCasinoSourceType.External]: CasinoSourceType.External,
        };

        return sourceTypeMap[type];
    }
}
