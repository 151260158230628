import React from 'react';
import {defineMessages} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import Button, {ButtonProps} from '@components/button/Buttons';

const localized = defineMessages({
    label: {
        id: 'DownloadCsvButton_label',
        defaultMessage: 'Download CSV',
    },
});

const useStyles = makeStyles()(theme => ({
    downloadCsvButton: {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

export type DownloadCsvButtonProps = Pick<ButtonProps, 'onClick' | 'loading' | 'disabled'>;

export function DownloadCsvButton(props: DownloadCsvButtonProps) {
    const {classes} = useStyles();
    return <Button label={localized.label} className={classes.downloadCsvButton} {...props} />;
}
