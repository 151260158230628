import {defineMessages} from 'react-intl';

export const localizedBonusEngineDetails = defineMessages({
    bonusEngine: {
        id: 'BED_bonusEngine',
        defaultMessage: 'Bonus Engine',
    },
    cashBonus: {
        id: 'BED_cashBonus',
        defaultMessage: 'Cash Bonus',
    },
    mttToken: {
        id: 'BED_mttToken',
        defaultMessage: 'MTT Token',
    },
    uid: {
        id: 'BED_uid',
        defaultMessage: 'UID',
    },
    triggerBy: {
        id: 'BED_triggerBy',
        defaultMessage: 'Triggered By',
    },
    email: {
        id: 'BED_email',
        defaultMessage: 'Email',
    },
    username: {
        id: 'BED_username',
        defaultMessage: 'Username',
    },
    transactionDate: {
        id: 'BED_transactionDate',
        defaultMessage: 'Transaction Date',
    },
    paidValue: {
        id: 'BED_paidValue',
        defaultMessage: 'Paid Value',
    },
    unrealizedValue: {
        id: 'BED_unrealizedValue',
        defaultMessage: 'Unrealized Value',
    },
    allFilter: {
        id: 'BED_allFilter',
        defaultMessage: 'All',
    },
    playerIDFilter: {
        id: 'BED_playerIDFilter',
        defaultMessage: 'Player ID',
    },
    usernameFilter: {
        id: 'BED_usernameFilter',
        defaultMessage: 'Username',
    },
    emailFilter: {
        id: 'BED_emailFilter',
        defaultMessage: 'Email',
    },
    transactionDateFilter: {
        id: 'BED_transactionDateFilter',
        defaultMessage: 'Transaction Date',
    },
    searchByAllFilter: {
        id: 'BED_searchByAllFilter',
        defaultMessage: 'Search by player id, username or email',
    },
    searchByPlayerIdFilter: {
        id: 'BED_searchByPlayerIdFilter',
        defaultMessage: 'Search by player id',
    },
    searchByUsernameFilter: {
        id: 'BED_searchByPlayerIdFilter',
        defaultMessage: 'Search by username',
    },
    searchByEmailFilter: {
        id: 'BED_searchByPlayerIdFilter',
        defaultMessage: 'Search by email',
    },
    filename: {
        id: 'BED_fileName',
        defaultMessage: 'Bonus code history of {bonusId}',
    },
});
