import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {EventInitiator} from '@models/generated/graphql';
import {formatTimestamp} from '@utils/date';

import {BeBonusHistoryGridItem, BonusCodeHistoryDownloadModel} from './types';

export class BonusCodeHistoryMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap<BeBonusHistoryGridItem, BonusCodeHistoryDownloadModel>(
            mapper,
            BeBonusHistoryGridItem,
            nameof<BonusCodeHistoryDownloadModel>(),
            forMember(
                m => m.withBonusId,
                mapFrom(m => m.bonus_id)
            ),
            forMember(
                m => m.withCreateTime,
                mapFrom(m => formatTimestamp(m.created_at, 'date-time'))
            ),
            forMember(
                m => m.withEmail,
                mapFrom(m => m.email)
            ),
            forMember(
                m => m.withTransactionAmount,
                mapFrom(m => m.transaction_amount?.toString())
            ),
            forMember(
                m => m.withTriggerBy,
                mapFrom(m => (m.trigger_by === EventInitiator.Bo ? m.trigger_by_full_name : m.trigger_by))
            ),
            forMember(
                m => m.withTriggerByUid,
                mapFrom(m => m.trigger_by_uid)
            ),
            forMember(
                m => m.withType,
                mapFrom(m => m.type)
            ),
            forMember(
                m => m.withUid,
                mapFrom(m => m.uid)
            ),
            forMember(
                m => m.withUnrealizedAmount,
                mapFrom(m => m.unrealized_amount?.toString())
            ),
            forMember(
                m => m.withUsername,
                mapFrom(m => m.username)
            )
        );
    }
}
