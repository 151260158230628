import React from 'react';
import {defineMessages} from 'react-intl';
import {useSelector} from 'react-redux';

import {ButtonProps} from '@components/button/Buttons';
import {Icon} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {BonusCodeDetails, BonusStatus} from '@models/generated/graphql';
import {BonusCodeQueryFields, BonusCodeServerFilterKeys, EntityType} from '@redux/entity';
import {getNonEmptyValueValidator, useViewInit} from '@redux/view';
import {getSelectedItemId} from '@utils';

import {getBonusCodeType} from 'src/features/block-bonus-code/hooks';
import {BonusCodeType} from 'src/features/block-bonus-code/types';
import {locationSearchSelector} from '../../app/routing/selectors';

import {BulkBonusDepositCashMatchChooseActionButton} from './BulkBonusDepositCashMatchChooseActionButton';
import {BulkBonusImmediateCashChooseActionButton} from './BulkBonusImmediateCashChooseActionButton';
import {BulkBonusMttChooseActionButton} from './BulkBonusMttChooseActionButton';

const localized = defineMessages({
    manualTrigger: {
        id: 'BPB_manualTrigger',
        defaultMessage: 'Manual Trigger',
    },
});

export const BulkBonusChooseActionStepButton = () => {
    const queryString = useSelector(locationSearchSelector);
    const bonusId = getSelectedItemId(queryString);
    // TODO: [BO-2937] Use useBonusCodeDetails after Bonus Engine was moved to view feature
    const {items} = useViewInit<BonusCodeDetails, BonusCodeServerFilterKeys, BonusCodeQueryFields>({
        viewType: 'ChooseBulkTriggerBonusButton',
        entity: {
            entity: EntityType.BonusCode,
            fields: ['bonus_status', 'credit_type', 'release_metric'],
        },
        displayName: localized.manualTrigger,
        defaultFilters: [{key: 'bonusId', value: bonusId}],
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: getNonEmptyValueValidator<BonusCodeServerFilterKeys>('bonusId'),
    });

    const bonusInfo = items?.length ? items[0] : null;

    const renderByBonusType = (): JSX.Element => {
        const buttonProps: ButtonProps & {bonusId: string} = {
            variant: 'outlined',
            label: localized.manualTrigger,
            color: 'primary',
            startIcon: <Icon icon={CustomIcon.ListPlayOutline} />,
            disabled: bonusInfo?.bonus_status === BonusStatus.Inactive,
            bonusId,
        };

        const bonusCodeType = getBonusCodeType(bonusInfo?.credit_type, bonusInfo?.release_metric);

        const actionMap: Partial<Record<BonusCodeType, JSX.Element | null>> = {
            MTTTicket: <BulkBonusMttChooseActionButton {...buttonProps} bonusId={bonusId} />,
            CashAfterRake: <BulkBonusDepositCashMatchChooseActionButton {...buttonProps} bonusId={bonusId} />,
            CashBonus: <BulkBonusImmediateCashChooseActionButton {...buttonProps} bonusId={bonusId} />,
        };
        const triggerButton = actionMap[bonusCodeType];

        return triggerButton ?? <></>;
    };

    return renderByBonusType();
};
