import {makeStyles} from 'tss-react/mui';

import {CustomTheme} from '@style';

export const useModuleHeaderClasses = makeStyles()((theme: CustomTheme) => ({
    moduleHeader: {
        display: 'flex',
        height: theme.custom.pageHeaderHeight,
        minHeight: theme.custom.pageHeaderHeight,
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.custom.smallSizesNavbarHeight,
        },
        paddingLeft: theme.spacing(5),
    },
    moduleHeaderSpacing: {
        marginBottom: theme.spacing(2.5),
    },
    moduleHeaderTitle: {
        display: 'flex',
    },
    moduleHeaderTitlePadding: {
        paddingLeft: theme.spacing(2.5),
    },
    moduleHeaderTitleSeparatorContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    moduleHeaderTitleSeparator: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    moduleActionsRightAligned: {
        display: 'flex',
        marginBottom: theme.spacing(2.5),
        marginRight: theme.spacing(5),
        float: 'right',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
        columnGap: theme.spacing(1),
    },
    moduleActions: {
        display: 'flex',
        marginBottom: theme.spacing(2.5),
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
        columnGap: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    },
    moduleHeaderActions: {
        gap: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        marginRight: theme.spacing(5),
    },
}));
