import React, {useEffect, useState} from 'react';
import {MessageDescriptor} from 'react-intl';
import {Divider} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {AutocompleteButton} from '@components/autocomplete/AutocompleteButton';
import {FilterProps} from '@components/filter';

import {useBoUsers} from '../hooks';

import {BoUserAutocomplete} from './BoUserAutocomplete';

const useStyles = makeStyles()(theme => ({
    boUserFilterContainer: {
        minWidth: '300px',
    },
    boUserDivider: {
        marginBottom: theme.spacing(1.25),
        borderColor: theme.palette.secondary.light,
    },
}));

type BoUserFilterProps = FilterProps<string> & {
    label?: MessageDescriptor;
    selectedText?: string;
};

export function BoUserFilter({value, onChange, label}: BoUserFilterProps) {
    const {classes} = useStyles();
    const [selectedBoUser, setSelectedBoUser] = useState<string>(value);
    const [selectedBoUserEmail, setSelectedBoUserEmail] = useState<string>();
    const {items} = useBoUsers({
        viewType: 'BoUserFilter',
        fields: ['id', 'email'],
        ids: [selectedBoUser],
        defaultPaging: {
            pageSize: 1,
            page: 1,
        },
        validateFilter: () => selectedBoUser?.length > 0,
    });
    useEffect(() => {
        setSelectedBoUserEmail(selectedBoUser ? items?.[0]?.email : undefined);
    }, [items?.[0]?.email]);

    useEffect(() => {
        setSelectedBoUser(value);
    }, [value]);

    function handleChange({id, email}: {id: string; email: string}) {
        setSelectedBoUser(id);
        setSelectedBoUserEmail(email);
    }

    function handleApplyClick() {
        onChange(selectedBoUser);
    }

    function handleCancelClick() {
        setSelectedBoUser(value);
    }
    return (
        <AutocompleteButton label={label} selectedText={selectedBoUserEmail} onApply={handleApplyClick} onCancel={handleCancelClick}>
            <div className={classes.boUserFilterContainer}>
                <BoUserAutocomplete value={selectedBoUser} onChange={handleChange} />
                <Divider className={classes.boUserDivider} />
            </div>
        </AutocompleteButton>
    );
}
