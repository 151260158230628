import React, {PropsWithChildren} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {SelectButton} from '@components/select';

const localized = defineMessages({
    any: {
        id: 'AutocompleteButton_any',
        defaultMessage: 'Any',
    },
});

const useStyles = makeStyles()(theme => ({
    autocompleteButton: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    autocompleteButtonDropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, 1.5, 1.5),
        boxSizing: 'content-box',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
        },
    },
}));

type AutocompleteButtonProps = PropsWithChildren<{
    label?: string | MessageDescriptor;
    selectedText?: string;
    applyActionLabel?: MessageDescriptor;
    cancelActionLabel?: MessageDescriptor;
    onApply?: () => void;
    onCancel?: () => void;
}>;

export function AutocompleteButton({
    label,
    selectedText,
    applyActionLabel,
    cancelActionLabel,
    onApply,
    onCancel,
    children,
}: AutocompleteButtonProps) {
    const {classes} = useStyles();
    return (
        <SelectButton
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            label={label}
            selectedText={selectedText ?? localized.any}
            styles={{
                dropdownButton: classes.autocompleteButton,
                dropdownButtonOpen: classes.autocompleteButton,
                dropdownContainer: classes.autocompleteButtonDropdownContainer,
            }}
            applyAction={applyActionLabel ?? !!onApply}
            cancelAction={cancelActionLabel ?? !!onCancel}
            onApplyClick={onApply}
            onCancelClick={onCancel}
        >
            {children}
        </SelectButton>
    );
}
