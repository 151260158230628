import React from 'react';

import {DownloadCsvButton} from 'src/features/block-download';
import {useBonusCodeHistoryDownload, UseBonusCodeHistoryDownloadProps} from '../hooks';

type BonusCodeHistoryDownloadButtonProps = UseBonusCodeHistoryDownloadProps;

export function BonusCodeHistoryDownloadButton(props: BonusCodeHistoryDownloadButtonProps) {
    const {handleClick, isProgress} = useBonusCodeHistoryDownload(props);
    return <DownloadCsvButton onClick={handleClick} loading={isProgress} disabled={!props.total || isProgress} />;
}
