import React, {useContext} from 'react';
import {GridCellParams} from '@mui/x-data-grid';

import {ModalButtonColumnValue} from '@components/data-grid/types';
import {ModalContext} from '@components/modal/types';

import {ButtonCell} from './ButtonCell';

export function renderMuiGridModalButtonCell(params: GridCellParams) {
    const {openModal} = useContext(ModalContext);
    const value = params.value as ModalButtonColumnValue;
    return <ButtonCell onClick={() => openModal(value.modalContent)} value={value} />;
}
