import React, {useContext} from 'react';
import {Controller, FormProvider, useFormContext} from 'react-hook-form';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import merge from 'deepmerge';

import {useAutoMapper} from '@auto-mapper';
import Button from '@components/button/Buttons';
import {FormControlGroup, FormGroup, FormGroupTitle} from '@components/form';
import {FormTextInputDefault, numberRegex, RuleType, StyledInput, StyledToggle, useValidationFormatter} from '@components/input';
import {ModalContext, ModalFooter} from '@components/modal';
import {MultiSelectWithExclude} from '@components/select';
import {CasinoAggregator, CasinoSourceType} from '@models/casino-game';
import {BonusCodeInput, BonusReleaseMetric} from '@models/generated/graphql';
import {useAuthUser} from '@auth';

import {useCurrencySymbol} from '../../app/intl/hooks';
import {SelectOption} from '../../module-shared/types';
import {useReduxForm} from '../../shared/form/hooks';
import {bonusCodeActions} from '../index';
import {DepositCashBonusFormViewModel} from '../types';

import {CasinoGameAutocomplete} from './CasinoGameAutocomplete';
import {CasinoSupplierAutocomplete} from './CasinoSupplierAutocomplete';

const localized = defineMessages({
    releaseMetric: {
        id: 'BonusEngineCashAfterRakeForm_releaseMetric',
        defaultMessage: 'Release Metric',
    },
    rakeBonus: {
        id: 'BonusEngineCashAfterRakeForm_rakeBonus',
        defaultMessage: 'Poker Rake-based',
    },
    wagerBonus: {
        id: 'BonusEngineCashAfterRakeForm_wagerBonus',
        defaultMessage: 'Casino Wager-based',
    },
    bonusName: {
        id: 'BonusEngineCashAfterRakeForm_bonusName',
        defaultMessage: 'Bonus Name',
    },
    placeholderBonusName: {
        id: 'BonusEngineCashAfterRakeForm_placeholderBonusName',
        defaultMessage: 'e.g., 47GQGCHU',
    },
    bonusDescription: {
        id: 'BonusEngineCashAfterRakeForm_bonusDescription',
        defaultMessage: 'Bonus Description',
    },
    placeholderBonusDescription: {
        id: 'BonusEngineCashAfterRakeForm_placeholderBonusDescription',
        defaultMessage: 'Description',
    },
    minimalDeposit: {
        id: 'BonusEngineCashAfterRakeForm_minimalDeposit',
        defaultMessage: 'Minimal Deposit',
    },
    placeholderMinimalDeposit: {
        id: 'BonusEngineCashAfterRakeForm_placeholderMinimalDeposit',
        defaultMessage: '0,00',
    },
    bonusValueParam: {
        id: 'BonusEngineCashAfterRakeForm_bonusValueParam',
        defaultMessage: 'Bonus Value Param',
    },
    placeholderBonusValueParam: {
        id: 'BonusEngineCashAfterRakeForm_placeholderBonusValueParam',
        defaultMessage: '0.1',
    },
    bonusValueParamInfoMessage: {
        id: 'BonusEngineCashAfterRakeForm_bonusValueParamInfoMessage',
        defaultMessage: 'Every $1 deposit made, player is eligible for ($bonus value param) of bonus. e.g. 0.1 = 10% conversion',
    },
    maximumBonus: {
        id: 'BonusEngineCashAfterRakeForm_maximumBonus',
        defaultMessage: 'Maximum Bonus',
    },
    placeholderMaximumBonus: {
        id: 'BonusEngineCashAfterRakeForm_placeholderMaximumBonus',
        defaultMessage: '0,00',
    },
    expirePeriod: {
        id: 'BonusEngineCashAfterRakeForm_expirePeriod',
        defaultMessage: 'Expire Period',
    },
    placeholderExpirePeriod: {
        id: 'BonusEngineCashAfterRakeForm_placeholderExpirePeriod',
        defaultMessage: '1',
    },
    expirePeriodInfoMessage: {
        id: 'BonusEngineCashAfterRakeForm_expirePeriodInfoMessage',
        defaultMessage: 'Player has (expire period) of times to generate rake and redeem eligible bonus.',
    },

    gameSelector: {
        id: 'BonusEngineCashAfterRakeForm_gameSelector',
        defaultMessage: 'Game Selector',
    },
    sourceType: {
        id: 'BonusEngineCashAfterRakeForm_sourceType',
        defaultMessage: 'Source',
    },
    aggregator: {
        id: 'BonusEngineCashAfterRakeForm_aggregator',
        defaultMessage: 'Aggregator',
    },
    supplier: {
        id: 'BonusEngineCashAfterRakeForm_supplier',
        defaultMessage: 'Supplier',
    },
    games: {
        id: 'BonusEngineCashAfterRakeForm_games',
        defaultMessage: 'Games',
    },
    rakeGenerated: {
        id: 'BonusEngineCashAfterRakeForm_rakeGenerated',
        defaultMessage: 'Rake Generated',
    },
    placeholderRakeGeneratedQualify: {
        id: 'BonusEngineCashAfterRakeForm_placeholderRakeGeneratedQualify',
        defaultMessage: 'Rake Qualify',
    },
    placeholderRakeGeneratedAmount: {
        id: 'BonusEngineCashAfterRakeForm_placeholderRakeGeneratedAmount',
        defaultMessage: 'Rake Amount',
    },
    rakeGeneratedInfoMessage: {
        id: 'BonusEngineCashAfterRakeForm_rakeGeneratedInfoMessage',
        defaultMessage: 'Every rake generated, player will be paid eligible bonus',
    },
    wagerGenerated: {
        id: 'BonusEngineCashAfterRakeForm_wagerGenerated',
        defaultMessage: 'Wager Generated',
    },
    placeholderWagerGeneratedAmount: {
        id: 'BonusEngineCashAfterRakeForm_placeholderWagerGeneratedQualify',
        defaultMessage: 'Wager amount',
    },
    placeholderWagerGeneratedBonusPaid: {
        id: 'BonusEngineCashAfterRakeForm_placeholderWagerGeneratedBonusPaid',
        defaultMessage: 'Bonus paid',
    },
    wagerGeneratedInfoMessage: {
        id: 'BonusEngineCashAfterRakeForm_wagerGeneratedInfoMessage',
        defaultMessage: 'When $X wager amount generated, player will be paid $Y bonus',
    },
    submit: {
        id: 'BonusEngineCashAfterRakeForm_submit',
        defaultMessage: 'Submit',
    },
    close: {
        id: 'BonusEngineCashAfterRakeForm_close',
        defaultMessage: 'Close',
    },
});

type BonusRakeInputsProps = {
    isDisabled: boolean;
    metricQualifyPlaceholder: MessageDescriptor;
    metricAmountPlaceholder: MessageDescriptor;
};

function BonusRakeInputs({metricQualifyPlaceholder, metricAmountPlaceholder, isDisabled}: BonusRakeInputsProps) {
    const currencySymbol = useCurrencySymbol();
    const validationFormatter = useValidationFormatter();
    const {control} = useFormContext<DepositCashBonusFormViewModel>();

    return (
        <FormGroup row>
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        key={field.name}
                        type="number"
                        value={field.value}
                        onChange={field.onChange}
                        fieldState={fieldState}
                        hideLabel={true}
                        placeholder={metricQualifyPlaceholder}
                        disabled={isDisabled}
                        endAdornment={currencySymbol}
                        hasBottomSpacing={false}
                        fullWidth={false}
                    />
                )}
                name="metric_qualify"
                control={control}
                rules={{
                    required: validationFormatter(RuleType.Required, metricQualifyPlaceholder),
                    pattern: {
                        value: numberRegex,
                        message: validationFormatter(RuleType.Number, metricQualifyPlaceholder),
                    },
                }}
            />
            <Controller
                render={({field, fieldState}) => (
                    <FormTextInputDefault
                        key={field.name}
                        type="number"
                        value={field.value}
                        onChange={field.onChange}
                        fieldState={fieldState}
                        hideLabel={true}
                        placeholder={metricAmountPlaceholder}
                        disabled={isDisabled}
                        endAdornment={currencySymbol}
                        hasBottomSpacing={false}
                        fullWidth={false}
                    />
                )}
                name="metric_amount"
                control={control}
                rules={{
                    required: validationFormatter(RuleType.Required, metricAmountPlaceholder),
                    pattern: {
                        value: numberRegex,
                        message: validationFormatter(RuleType.Number, metricAmountPlaceholder),
                    },
                }}
            />
        </FormGroup>
    );
}

type BonusEngineMTTTicketFormProps = {
    initialModel?: DepositCashBonusFormViewModel;
    mode?: 'create' | 'read';
};

export function BonusEngineCashAfterRakeForm({initialModel, mode = 'create'}: BonusEngineMTTTicketFormProps) {
    const {formatMessage} = useIntl();
    const currencySymbol = useCurrencySymbol();
    const validationFormatter = useValidationFormatter();
    const mapper = useAutoMapper();
    const {closeModal} = useContext(ModalContext);
    const defaultModel: Partial<DepositCashBonusFormViewModel> = {
        release_metric: BonusReleaseMetric.RakeGenerated,
        source_types: {include: [], exclude: []},
        aggregators: {include: [], exclude: []},
        suppliers: {include: [], exclude: []},
        games: {include: [], exclude: []},
    };
    const {sub} = useAuthUser();

    const {state, submit, ...form} = useReduxForm<DepositCashBonusFormViewModel, BonusCodeInput>({
        initialModel: merge(defaultModel, initialModel ?? {}),
        asyncAction: bonusCodeActions.addBonusCode,
        map: vm => mapper.map(vm, DepositCashBonusFormViewModel, BonusCodeInput, {extraArgs: () => ({uid: sub})}),
        onSuccess: () => closeModal(),
        shouldResetOnError: false,
    });

    const isInputsDisabled = mode === 'read' || state.isProgress;

    const releaseMetric = form.watch('release_metric');
    const includedSourceTypes = form.watch('source_types.include');
    const excludedSourceTypes = form.watch('source_types.exclude');
    const includedAggregators = form.watch('aggregators.include');
    const excludedAggregators = form.watch('aggregators.exclude');
    const includedSuppliers = form.watch('suppliers.include');
    const excludedSuppliers = form.watch('suppliers.exclude');
    const areExternalGamesExcluded =
        (includedSourceTypes?.includes(CasinoSourceType.Internal) && !includedSourceTypes?.includes(CasinoSourceType.External)) ||
        (excludedSourceTypes?.includes(CasinoSourceType.External) && !excludedSourceTypes?.includes(CasinoSourceType.Internal));

    const sourceTypeOptions: SelectOption[] = [
        {value: CasinoSourceType.Internal, label: CasinoSourceType.Internal},
        {value: CasinoSourceType.External, label: CasinoSourceType.External},
    ];
    const aggregatorOptions: SelectOption[] = [
        {value: CasinoAggregator.Bragg, label: CasinoAggregator.Bragg},
        {value: CasinoAggregator.VisionLink, label: CasinoAggregator.VisionLink},
    ];

    const releaseMetricsOptions: SelectOption[] = [
        {value: BonusReleaseMetric.RakeGenerated, label: formatMessage(localized.rakeBonus)},
        {value: BonusReleaseMetric.WagerGenerated, label: formatMessage(localized.wagerBonus)},
    ];

    return (
        <div>
            <FormProvider {...form}>
                <form id="addCashAfterRakeForm" data-testid="addCashAfterRakeForm" onSubmit={form.handleSubmit(submit)}>
                    <FormGroup>
                        <FormControlGroup label={formatMessage(localized.bonusName)} labelVariant="default" labelPlacement="left">
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderBonusName}
                                        disabled={isInputsDisabled}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="bonus_name"
                                control={form.control}
                                rules={{
                                    required: validationFormatter(RuleType.Required, localized.bonusName),
                                }}
                            />
                        </FormControlGroup>
                        <FormControlGroup label={formatMessage(localized.bonusDescription)} labelVariant="default" labelPlacement="left">
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderBonusDescription}
                                        disabled={isInputsDisabled}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="description"
                                control={form.control}
                            />
                        </FormControlGroup>
                        <FormControlGroup label={formatMessage(localized.minimalDeposit)} labelVariant="default" labelPlacement="left">
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        type="number"
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderMinimalDeposit}
                                        disabled={isInputsDisabled}
                                        endAdornment={currencySymbol}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="min_deposit"
                                control={form.control}
                                rules={{
                                    required: validationFormatter(RuleType.Required, localized.minimalDeposit),
                                    pattern: {
                                        value: numberRegex,
                                        message: validationFormatter(RuleType.Number, localized.minimalDeposit),
                                    },
                                }}
                            />
                        </FormControlGroup>
                        <FormControlGroup
                            label={formatMessage(localized.bonusValueParam)}
                            labelVariant="default"
                            labelPlacement="left"
                            hint={formatMessage(localized.bonusValueParamInfoMessage)}
                        >
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        type="number"
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderBonusValueParam}
                                        disabled={isInputsDisabled}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="bonus_value_param"
                                control={form.control}
                                rules={{
                                    required: validationFormatter(RuleType.Required, localized.bonusValueParam),
                                    pattern: {
                                        value: numberRegex,
                                        message: validationFormatter(RuleType.Number, localized.bonusValueParam),
                                    },
                                }}
                            />
                        </FormControlGroup>

                        <FormControlGroup>
                            <Controller
                                control={form.control}
                                name="release_metric"
                                defaultValue={BonusReleaseMetric.RakeGenerated}
                                render={({field}) => (
                                    <StyledToggle
                                        value={field.value}
                                        options={releaseMetricsOptions}
                                        onChange={(...events) => {
                                            form.resetField('source_types');
                                            form.resetField('aggregators');
                                            form.resetField('suppliers');
                                            form.resetField('games');
                                            field.onChange(...events);
                                        }}
                                        disabled={isInputsDisabled}
                                    />
                                )}
                                rules={{required: validationFormatter(RuleType.Required, localized.releaseMetric)}}
                            />
                        </FormControlGroup>

                        {releaseMetric === BonusReleaseMetric.WagerGenerated ? (
                            <FormGroup style="highlighted">
                                <FormGroupTitle groupTitle={formatMessage(localized.gameSelector)} />
                                <FormControlGroup label={formatMessage(localized.sourceType)} labelVariant="default" labelPlacement="left">
                                    <Controller
                                        render={({field}) => (
                                            <MultiSelectWithExclude
                                                mode="client"
                                                value={field.value}
                                                onValueChange={(...events) => {
                                                    form.resetField('aggregators');
                                                    form.resetField('suppliers');
                                                    form.resetField('games');
                                                    field.onChange(...events);
                                                }}
                                                options={sourceTypeOptions}
                                                total={sourceTypeOptions.length}
                                                hasSearch={false}
                                                disabled={isInputsDisabled}
                                            />
                                        )}
                                        name="source_types"
                                        control={form.control}
                                    />
                                </FormControlGroup>
                                <FormControlGroup label={formatMessage(localized.aggregator)} labelVariant="default" labelPlacement="left">
                                    <Controller
                                        render={({field}) => (
                                            <MultiSelectWithExclude
                                                mode="client"
                                                value={field.value}
                                                onValueChange={(...events) => {
                                                    form.resetField('suppliers');
                                                    form.resetField('games');
                                                    field.onChange(...events);
                                                }}
                                                options={aggregatorOptions}
                                                total={aggregatorOptions.length}
                                                disabled={areExternalGamesExcluded || isInputsDisabled}
                                                hasSearch={false}
                                            />
                                        )}
                                        name="aggregators"
                                        control={form.control}
                                    />
                                </FormControlGroup>
                                <FormControlGroup label={formatMessage(localized.supplier)} labelVariant="default" labelPlacement="left">
                                    <Controller
                                        render={({field}) => (
                                            <CasinoSupplierAutocomplete
                                                disabled={areExternalGamesExcluded || isInputsDisabled}
                                                value={field.value}
                                                onValueChange={(...events) => {
                                                    form.resetField('games');
                                                    field.onChange(...events);
                                                }}
                                            />
                                        )}
                                        name="suppliers"
                                        control={form.control}
                                    />
                                </FormControlGroup>
                                <FormControlGroup label={formatMessage(localized.games)} labelVariant="default" labelPlacement="left">
                                    <Controller
                                        render={({field}) => (
                                            <CasinoGameAutocomplete
                                                value={field.value}
                                                onValueChange={(...events) => {
                                                    field.onChange(...events);
                                                }}
                                                sourceTypes={{include: includedSourceTypes, exclude: excludedSourceTypes}}
                                                aggregators={{include: includedAggregators, exclude: excludedAggregators}}
                                                suppliers={{include: includedSuppliers, exclude: excludedSuppliers}}
                                                disabled={isInputsDisabled}
                                            />
                                        )}
                                        name="games"
                                        control={form.control}
                                    />
                                </FormControlGroup>
                            </FormGroup>
                        ) : null}

                        <FormControlGroup label={formatMessage(localized.maximumBonus)} labelVariant="default" labelPlacement="left">
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        type="number"
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderMaximumBonus}
                                        disabled={isInputsDisabled}
                                        endAdornment={currencySymbol}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="maximum_bonus"
                                control={form.control}
                                rules={{
                                    required: validationFormatter(RuleType.Required, localized.maximumBonus),
                                    pattern: {
                                        value: numberRegex,
                                        message: validationFormatter(RuleType.Number, localized.maximumBonus),
                                    },
                                }}
                            />
                        </FormControlGroup>

                        {releaseMetric === BonusReleaseMetric.WagerGenerated ? (
                            <FormControlGroup
                                label={formatMessage(localized.wagerGenerated)}
                                labelVariant="default"
                                labelPlacement="left"
                                hint={formatMessage(localized.wagerGeneratedInfoMessage)}
                            >
                                <BonusRakeInputs
                                    metricQualifyPlaceholder={localized.placeholderWagerGeneratedAmount}
                                    metricAmountPlaceholder={localized.placeholderWagerGeneratedBonusPaid}
                                    isDisabled={isInputsDisabled}
                                />
                            </FormControlGroup>
                        ) : (
                            <FormControlGroup
                                label={formatMessage(localized.rakeGenerated)}
                                labelVariant="default"
                                labelPlacement="left"
                                hint={formatMessage(localized.rakeGeneratedInfoMessage)}
                            >
                                <BonusRakeInputs
                                    metricQualifyPlaceholder={localized.placeholderRakeGeneratedQualify}
                                    metricAmountPlaceholder={localized.placeholderRakeGeneratedAmount}
                                    isDisabled={isInputsDisabled}
                                />
                            </FormControlGroup>
                        )}
                        <FormControlGroup
                            label={formatMessage(localized.expirePeriod)}
                            labelVariant="default"
                            labelPlacement="left"
                            hint={formatMessage(localized.expirePeriodInfoMessage)}
                        >
                            <Controller
                                render={({field, fieldState}) => (
                                    <StyledInput
                                        key={field.name}
                                        type="number"
                                        value={field.value}
                                        onChange={field.onChange}
                                        fieldState={fieldState}
                                        hideLabel={true}
                                        placeholder={localized.placeholderExpirePeriod}
                                        disabled={isInputsDisabled}
                                        hasBottomSpacing={false}
                                        fullWidth={false}
                                    />
                                )}
                                name="expire_period"
                                control={form.control}
                                rules={{
                                    required: validationFormatter(RuleType.Required, localized.expirePeriod),
                                    pattern: {
                                        value: numberRegex,
                                        message: validationFormatter(RuleType.Number, localized.expirePeriod),
                                    },
                                }}
                            />
                        </FormControlGroup>
                    </FormGroup>
                </form>
            </FormProvider>
            <ModalFooter>
                <Button
                    label={formatMessage(localized.submit)}
                    type="submit"
                    form="addCashAfterRakeForm"
                    color="primary"
                    disabled={isInputsDisabled}
                />
                <Button label={formatMessage(localized.close)} onClick={closeModal} />
            </ModalFooter>
        </div>
    );
}
