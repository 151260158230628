import React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';

import LocalizedText from '@components/i18n/LocalizedText';

import {SelectOption} from '../../../features/module-shared/types';

import {StyledInputProps, withStyledInputLabel} from './StyledFormInputHoc';

class StyledToggleProps extends StyledInputProps {
    value: string | number | string[];
    options: SelectOption[];
    onChange: (value: string | number | string[]) => void;
    disabled?: boolean;
}

const StyledToggleComponent = (props: StyledToggleProps) => {
    const {options, value, onChange, disabled} = props;

    const handleChange = (_event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
        if (newValue !== null && newValue !== undefined) {
            onChange(options.find(o => o.value === newValue).value as string | number);
        }
    };

    return (
        <ToggleButtonGroup value={value} exclusive onChange={handleChange} disabled={disabled}>
            {options.map((o: SelectOption) => (
                <ToggleButton key={o.value as string | number} value={o.value} disabled={o.disabled} selected={o.value === value}>
                    <LocalizedText label={o.label} />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export const StyledToggle = withStyledInputLabel(StyledToggleComponent);
