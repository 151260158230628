import React, {memo} from 'react';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import {GridCellParams} from '@mui/x-data-grid';
import {makeStyles} from 'tss-react/mui';

import {IModuleGridItem} from '@components/data-grid';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {PhoneNumber} from '@models/generated/graphql';

import {MessageType, PatchAsyncAction} from '../../types';
import {EditableColDef} from '../editable-components/types';

import {EditableProps, EditStateTrigger, withEditable} from './EditableHoc';
import {getEditDisabledState, getGridRowData} from './utils';

const useClasses = makeStyles()(() => ({
    area: {
        minWidth: 50,
        maxWidth: 50,
        background: 'transparent',
    },
    divider: {
        margin: 10,
    },
    phone: {
        width: '100%',
        background: 'transparent',
    },
}));

type EditablePhoneOptions = {
    areaPlacehoder: string;
    mobilePlaceholder: string;
};

export const EditablePhone = memo(
    withEditable(({value, onChange, disabled}: EditableProps<PhoneNumber, EditablePhoneOptions>) => {
        const {classes} = useClasses();

        const changeArea = (e: React.BaseSyntheticEvent) => {
            const updatedValue = {
                area: e.target.value,
                mobile: value?.mobile,
            };
            onChange(updatedValue);
        };

        const changePhone = (e: React.BaseSyntheticEvent) => {
            const updatedValue = {
                area: value?.area,
                mobile: e.target.value,
            };
            onChange(updatedValue);
        };

        const stopEvent = (e: React.BaseSyntheticEvent) => {
            (e.nativeEvent as Event).stopImmediatePropagation();
            e.stopPropagation();
        };

        return (
            <>
                <TextField
                    className={classes.area}
                    value={disabled && !value?.area ? emptyCellCharacter : value.area}
                    disabled={disabled}
                    onInput={changeArea}
                />
                <Divider className={classes.divider} orientation="vertical" flexItem />
                <TextField
                    className={classes.phone}
                    value={disabled && !value?.mobile ? emptyCellCharacter : value.mobile}
                    disabled={disabled}
                    onInput={changePhone}
                    onKeyPress={stopEvent}
                    onKeyDown={stopEvent}
                />
            </>
        );
    })
);

export const renderEditablePhoneCell =
    <T extends IModuleGridItem>(saveAction: PatchAsyncAction, isReadonly?: (model: T) => boolean) =>
    (params: GridCellParams) => {
        const {model, defaultValue, field, id, typeName} = getGridRowData<T>(params);
        const isEditable = (params.colDef as EditableColDef)?.isEditable;
        const isEditDisabled = getEditDisabledState(model, isEditable, isReadonly);
        return (
            <EditablePhone
                model={model}
                value={defaultValue}
                action={saveAction}
                messageType={MessageType.FieldMessage}
                disabled={isEditDisabled}
                field={field}
                typeName={typeName}
                editStateTrigger={EditStateTrigger.WrappedComponent}
                id={id}
            />
        );
    };
