import React, {FC, useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';

import {isStringNullOrEmpty} from '@utils';

import {dashCharacter as emptyCellCharacter} from '../label';
import {StyledTooltip} from '../Tooltip';

import {useClasses} from './AutoAdjustedWidthTooltip.styles';

type AutoAdjustedWidthTooltipProps = {
    value: React.ReactNode;
    showEmptyCharacter?: boolean;
};

/**
 * @deprecated
 * <p>Should be removed. Use {@link MultiLineAutoAdjustedWidthTooltip}</p>
 */
export const AutoAdjustedWidthTooltip: FC<AutoAdjustedWidthTooltipProps> = ({value, showEmptyCharacter = true}) => {
    const {classes} = useClasses();
    const [isOverflowed, setIsOverflow] = useState(false);
    const textElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textElementRef && textElementRef.current) {
            setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
        }
    }, [value]);

    function isValueNotEmpty() {
        return value !== null && value !== undefined && !(typeof value === 'string' && isStringNullOrEmpty(value));
    }

    return isValueNotEmpty() ? (
        <StyledTooltip
            element={
                <div ref={textElementRef} className={classes.autoAdjustedWidthTooltipLabel}>
                    {value}
                </div>
            }
            title={<Box className={classes.autoAdjustedWidthTooltip}>{value}</Box>}
            hideTooltip={!isOverflowed}
        />
    ) : (
        <>{showEmptyCharacter ? emptyCellCharacter : null}</>
    );
};
