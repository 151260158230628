import {createMapper} from '@automapper/core';
import {pojos} from '@automapper/pojos';

import {IMapping} from '@auto-mapper';
import {AccountVerificationMapping} from '@models/account-verification';
import {AgentPathStatisticsMapping} from '@models/agent-path-statistics';
import {AgentPlayerReportingMapping} from '@models/agent-player-reporting';
import {AgentProfileMapping} from '@models/agent-profile';
import {AggregatedFinanceSummaryMapping} from '@models/aggregated-finance-summary';
import {BackpackMapping} from '@models/backpack';
import {BoUserSettingsMapping} from '@models/bo-user-settings/mapper';
import {GameBlindMapping} from '@models/game-blind/mapper';
import {GameRoomMapping} from '@models/game-room';
import {GameTemplateMapping} from '@models/game-template';
import {PlayerBonusMapping} from '@models/player-bonus';
import {PlayerReferrerSummaryMapping} from '@models/player-referrer-summary';
import {SharedMapping} from '@models/shared/mapper';
import {TransactionMapping} from '@models/transaction';
import {UserProfileMapping} from '@models/user-profile';
import {GqlServiceMapping, RestServiceMapping} from '@services/entity';

import {BonusCodeHistoryMapping} from '../features/be-bonus-history/mapper';
import {BlockAccountVerificationMapping} from '../features/block-account-verification/mapper';
import {AgentLevelReportMapping} from '../features/block-agent-level-report/mapper';
import {RuleMapping} from '../features/block-automated-rule/mapper';
import {BoUserMapping} from '../features/block-bo-user/mapper';
import {BonusCodeMapping} from '../features/block-bonus-code/mapper';
import {EmbeddedReportMapping} from '../features/block-embedded-report/mapper';
import {PlayerLabelMapping} from '../features/block-label-group-list/mapper';
import {LegalDocHistoryMapping} from '../features/block-legal-doc-history';
import {LegalDocumentMapping} from '../features/block-legal-document';
import {NoteMapping} from '../features/block-note/mapper';
import {PageViewMapping} from '../features/block-page-view';
import {PlayerBonusVmMapping} from '../features/block-player-bonus/mapper';
import {PlayerReferrerInfoMapping} from '../features/block-player-referral-actions/mapper';
import {PlayersRelationMapping} from '../features/block-players-relation/mapper';
import {SecurityCasesMapping} from '../features/block-security-case/mapper';
import {SportsbookTransactionMapping} from '../features/block-sportsbook-transaction';
import {TransactionActionsMapping} from '../features/block-transaction-actions';
import {UserActivitySummaryMapping} from '../features/block-user-activity-summary/mapper';
import {UserLoginMapping} from '../features/block-user-login';
import {UserProfileActionsMapping} from '../features/block-user-profile-actions/mapper';

export function initAutoMapper() {
    const mapper = createMapper({
        strategyInitializer: pojos(),
    });

    const mappings: IMapping[] = [
        new AccountVerificationMapping(),
        new AgentLevelReportMapping(),
        new AgentPathStatisticsMapping(),
        new AgentPlayerReportingMapping(),
        new AgentProfileMapping(),
        new AggregatedFinanceSummaryMapping(),
        new BackpackMapping(),
        new BonusCodeMapping(),
        new BoUserMapping(),
        new GameBlindMapping(),
        new GameRoomMapping(),
        new GameTemplateMapping(),
        new UserLoginMapping(),
        new GqlServiceMapping(),
        new NoteMapping(),
        new PlayerBonusMapping(),
        new PlayerBonusVmMapping(),
        new PlayerLabelMapping(),
        new PlayerReferrerInfoMapping(),
        new PlayerReferrerSummaryMapping(),
        new PlayersRelationMapping(),
        new RestServiceMapping(),
        new RuleMapping(),
        new SecurityCasesMapping(),
        new SharedMapping(),
        new TransactionMapping(),
        new TransactionActionsMapping(),
        new UserProfileActionsMapping(),
        new UserProfileMapping(),
        new SportsbookTransactionMapping(),
        new EmbeddedReportMapping(),
        new PageViewMapping(),
        new BlockAccountVerificationMapping(),
        new UserActivitySummaryMapping(),
        new BoUserSettingsMapping(),
        new LegalDocumentMapping(),
        new LegalDocHistoryMapping(),
        new BonusCodeHistoryMapping(),
    ];
    mappings.forEach(m => m.createMapping(mapper));

    return mapper;
}
