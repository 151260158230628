import React from 'react';
import {MessageDescriptor, useIntl} from 'react-intl';
import {makeStyles} from 'tss-react/mui';

import {Icon, useIconColorClasses} from '@components/icons/Icon';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {LinkButton} from '@components/link';
import {kycStatusIcons} from '@config/icon';
import {JumioDocumentType, KycStatus} from '@models/generated/graphql';
import {CustomTheme} from '@style';

import {localizedJumioDocumentType} from '../../app/intl/shared-resources/jumioDocumentType';
import {localizedJumioDocumentTypeAbbreviated} from '../../app/intl/shared-resources/jumioDocumentTypeAbbreviated';
import {useKycLink} from '../hooks';

const useAccountVerificationDocumentStatusClasses = makeStyles()((theme: CustomTheme) => {
    return {
        kycDocumentStatus: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(1),
            color: theme.custom.palette.status.lightGrey.main,
        },
    };
});

type AccountVerificationDocumentStatusProps = {
    status: KycStatus;
    docType: JumioDocumentType;
    fullDocumentType?: boolean;
    docRefId?: string;
    isLink?: boolean;
};

export function AccountVerificationDocumentStatus({
    status,
    docRefId,
    docType,
    fullDocumentType,
    isLink,
}: AccountVerificationDocumentStatusProps) {
    const {formatMessage} = useIntl();
    const {classes} = useAccountVerificationDocumentStatusClasses();

    const iconConfig = kycStatusIcons[status ?? 'undefined'];
    const {icon, color} = iconConfig;
    const iconColorClass = useIconColorClasses()[color];
    const label: MessageDescriptor = docType
        ? fullDocumentType
            ? localizedJumioDocumentType[docType]
            : localizedJumioDocumentTypeAbbreviated[docType]
        : null;
    const formattedLabel = label ? formatMessage(label) : emptyCellCharacter;

    const {openLink} = useKycLink(docRefId);

    return (
        <div className={classes.kycDocumentStatus}>
            {isLink ? (
                <LinkButton title={label} icon={icon} color={color} onClick={openLink} linkStyle="chipWithLinkIcon"></LinkButton>
            ) : (
                <>
                    {icon ? <Icon icon={icon} color={color} /> : null}
                    <div className={iconColorClass ?? ''}>{formattedLabel}</div>
                </>
            )}
        </div>
    );
}
