import React from 'react';

import {useAutoMapper} from '@auto-mapper';
import {BonusCodeInput} from '@models/generated/graphql';
import {getNonEmptyValueValidator} from '@redux/view';

import {getBonusCodeType, useBonusCodes} from '../hooks';
import {BonusCodeDetailsViewModel, BonusCodeType, DepositCashBonusFormViewModel} from '../types';

import {BonusEngineCashAfterRakeForm} from './BonusEngineCashAfterRakeForm';
import {BonusEngineCashBonusForm} from './BonusEngineCashBonusForm';
import {BonusEngineMTTTicketForm} from './BonusEngineMTTTicketForm';

type BonusEngineLinkButtonProps = {
    bonusCodeId: string;
};

export function BonusEngineLinkButton({bonusCodeId}: BonusEngineLinkButtonProps) {
    const {items} = useBonusCodes({
        fields: [
            'bonus_id',
            'bonus_name',
            'credit_type',
            'release_metric',
            'bonus_status',
            'bonus_to_credit',
            'description',
            'marketing_codes',
            'meta.bonus_value_param',
            'meta.casino_excluded',
            'meta.casino_included',
            'meta.expire_period',
            'meta.max_bonus_get',
            'meta.metric_amount',
            'meta.metric_qualify',
            'meta.min_deposit',
            'meta.overall_max_bonus_get',
            'meta.rake_amount',
            'meta.rake_qualify',
            'meta.release_ratios',
            'meta.ticket_serial',
        ],
        viewType: 'BonusEngineDetailsModal',
        defaultFilters: [{key: 'bonusId', value: bonusCodeId}],
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: getNonEmptyValueValidator('bonusId'),
    });
    const bonusCodeDetails = items?.[0];

    const bonusCodeType = getBonusCodeType(bonusCodeDetails?.credit_type, bonusCodeDetails?.release_metric);
    const mapper = useAutoMapper();
    const initialModel = mapper.map<BonusCodeDetailsViewModel, BonusCodeInput>(
        bonusCodeDetails,
        nameof<BonusCodeDetailsViewModel>(),
        nameof<BonusCodeInput>()
    );
    const cashAfterRakeInitialModel = mapper.map<BonusCodeDetailsViewModel, DepositCashBonusFormViewModel>(
        bonusCodeDetails,
        nameof<BonusCodeDetailsViewModel>(),
        nameof<DepositCashBonusFormViewModel>()
    );

    const bonusCodeMapping: Record<BonusCodeType, JSX.Element> = {
        MTTTicket: <BonusEngineMTTTicketForm initialModel={initialModel} mode="read" />,
        CashAfterRake: <BonusEngineCashAfterRakeForm initialModel={cashAfterRakeInitialModel} mode="read" />,
        CashBonus: <BonusEngineCashBonusForm initialModel={initialModel} mode="read" />,
    };

    return bonusCodeMapping[bonusCodeType];
}
