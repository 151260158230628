import React, {useContext} from 'react';
import {MessageDescriptor} from 'react-intl';

import {ActionConfirmationModal, ModalContext} from '@components/modal';

import {useBonusCodeDeactivate} from '../hooks';

type BonusDeactivateButtonModalProps = {
    bonusId: string;
    title: MessageDescriptor;
    contentDescription: MessageDescriptor;
};

export function BonusDeactivateConfirmationModal({bonusId, title, contentDescription}: BonusDeactivateButtonModalProps) {
    const {closeModal} = useContext(ModalContext);

    const {deactivate} = useBonusCodeDeactivate(bonusId);
    function handleConfirm() {
        deactivate();
        closeModal();
    }
    return (
        <ActionConfirmationModal
            title={title}
            contentDescription={contentDescription}
            handleConfirm={handleConfirm}
            handleCancel={closeModal}
        />
    );
}
