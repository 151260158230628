import React, {useRef} from 'react';
import {defineMessages} from 'react-intl';
import {push} from '@vs-centaurea/connected-react-router';

import {CurrencyColumn, DataGridColumn, DataGridServer, DataGridServerProps, TextColumn} from '@components/data-grid';
import {
    ActionButtonColumn,
    ActionButtonColumnValue,
    ChipColumn,
    ModalButtonColumn,
    ModalButtonColumnValue,
} from '@components/data-grid/types';
import {LocalizedText} from '@components/i18n';
import {IconColor} from '@components/icons';
import {ModalContent} from '@components/modal';
import {BonusCreditType, BonusStatus} from '@models/generated/graphql';
import {appendSelectedItemToQuery, formatTimestamp} from '@utils';

import {RouteUrl} from 'src/common/routeEnums';
import {BonusCodeDetailsViewModel, BonusCodeDetailsViewModelKeys} from '../types';

import {BonusDeactivateConfirmationModal} from './BonusDeactivateConfirmationModal';
import {BonusEngineLinkButton} from './BonusEngineLinkButton';

export type DataGridBonusCodeKeys = BonusCodeDetailsViewModelKeys | 'deactivate_action' | 'details_action';

export const bonusCodeDataGridLocalized = defineMessages<DataGridBonusCodeKeys>({
    id: null,
    bonus_id: {
        id: 'BE_bonusId',
        defaultMessage: 'Bonus ID',
    },
    bonus_name: {
        id: 'BE_bonusName',
        defaultMessage: 'Bonus Name',
    },
    bonus_status: {
        id: 'BE_bonusStatus',
        defaultMessage: 'Status',
    },
    bonus_to_credit: null,
    'create_time.seconds': {
        id: 'BE_creationDate',
        defaultMessage: 'Creation Date',
    },
    credit_type: {
        id: 'BE_bonusType',
        defaultMessage: 'Bonus Type',
    },
    description: {
        id: 'BE_description',
        defaultMessage: 'Description',
    },
    end_time: null,
    last_update: null,
    marketing_codes: null,
    'meta.bonus_value_param': null,
    'meta.casino_excluded': null,
    'meta.casino_included': null,
    'meta.expire_period': null,
    'meta.max_bonus_get': null,
    'meta.metric_amount': null,
    'meta.metric_qualify': null,
    'meta.min_deposit': null,
    'meta.overall_max_bonus_get': null,
    'meta.rake_amount': null,
    'meta.rake_qualify': null,
    'meta.release_ratios': null,
    'meta.show_max_bonus_in_total': null,
    'meta.ticket_serial': null,
    'meta.time_range_by_date': null,
    'meta.total_max_bonus_get': null,
    'meta.unrealized_expire_period': null,
    platform: null,
    release_mechanism: null,
    release_metric: null,
    release_ratio_type: null,
    start_time: null,
    threshold: null,
    total_paid_out_amount: {
        id: 'BE_totalPaidOutValue',
        defaultMessage: 'Total Paid',
    },
    created_by: {
        id: 'BE_createdBy',
        defaultMessage: 'Created By',
    },
    details_action: {
        id: 'BE_detailsAction',
        defaultMessage: 'Display and Manual Trigger',
    },
    deactivate_action: {
        id: 'BE_deactivateAction',
        defaultMessage: 'Deactivate',
    },
});

const localized = defineMessages({
    modalTitle: {
        id: 'BonusDeactivateButtonModalCell_modalTitle',
        defaultMessage: 'Deactivate Bonus',
    },
    modalActionDescription: {
        id: 'BonusDeactivateButtonModalCell_modalActionDescription',
        defaultMessage: 'Do you want deactivate this bonus?',
    },
    modalDetailsTitle: {
        id: 'BonusDeactivateButtonModalCell_modalDetailsTitle',
        defaultMessage: 'Bonus code details',
    },
});

class DataGridBonusCodeConfigFactory {
    private readonly _config: Partial<Record<DataGridBonusCodeKeys, DataGridColumn<BonusCodeDetailsViewModel>>>;

    constructor() {
        const bonus_id: ModalButtonColumn<BonusCodeDetailsViewModel> = {
            field: 'bonus_id',
            valueGetter: model => this.getIdModalValue(model),
            title: bonusCodeDataGridLocalized.bonus_id,
            sortable: false,
            flex: 0.2,
            type: 'modal_button',
        };
        const bonus_name: TextColumn<BonusCodeDetailsViewModel> = {
            field: 'bonus_name',
            valueGetter: model => model?.bonus_name,
            title: bonusCodeDataGridLocalized.bonus_name,
            sortable: false,
            flex: 0.2,
            type: 'text',
        };
        const create_time: TextColumn<BonusCodeDetailsViewModel> = {
            field: 'create_time.seconds',
            valueGetter: model => formatTimestamp(model.create_time, 'date-time'),
            title: bonusCodeDataGridLocalized['create_time.seconds'],
            sortable: false,
            flex: 0.2,
            type: 'text',
        };
        const description: TextColumn<BonusCodeDetailsViewModel> = {
            field: 'description',
            valueGetter: model => model?.description,
            title: bonusCodeDataGridLocalized.description,
            sortable: false,
            flex: 0.3,
            type: 'text',
        };
        const created_by: TextColumn<BonusCodeDetailsViewModel> = {
            field: 'created_by',
            valueGetter: model => model?.created_by,
            title: bonusCodeDataGridLocalized.created_by,
            sortable: false,
            flex: 0.2,
            type: 'text',
        };
        const total_paid_out_amount: CurrencyColumn<BonusCodeDetailsViewModel> = {
            field: 'total_paid_out_amount',
            valueGetter: model => ({amount: model?.total_paid_out_amount ?? 0, currency: 'USD'}),
            title: bonusCodeDataGridLocalized.total_paid_out_amount,
            sortable: false,
            flex: 0.15,
            type: 'currency',
        };
        const credit_type: ChipColumn<BonusCodeDetailsViewModel> = {
            field: 'credit_type',
            valueGetter: model => ({value: model?.credit_type, type: nameof<BonusCreditType>()}),
            title: bonusCodeDataGridLocalized.credit_type,
            sortable: false,
            flex: 0.15,
            type: 'chip',
        };
        const bonus_status: ChipColumn<BonusCodeDetailsViewModel> = {
            field: 'bonus_status',
            valueGetter: model => ({value: model?.bonus_status, type: nameof<BonusStatus>()}),
            title: bonusCodeDataGridLocalized.bonus_status,
            sortable: false,
            flex: 0.15,
            type: 'chip',
        };
        const details_action: ActionButtonColumn<BonusCodeDetailsViewModel> = {
            field: 'details_action',
            valueGetter: this.getDetailsActionCellValue,
            sortable: false,
            width: 75,
            type: 'action_button',
        };
        const deactivate_action: ModalButtonColumn<BonusCodeDetailsViewModel> = {
            field: 'deactivate_action',
            valueGetter: this.getDeactivateModalValue,
            sortable: false,
            width: 160,
            type: 'modal_button',
        };

        this._config = {
            bonus_id,
            bonus_name,
            'create_time.seconds': create_time,
            description,
            created_by,
            total_paid_out_amount,
            credit_type,
            bonus_status,
            details_action,
            deactivate_action,
        };
    }

    public get config(): Partial<Record<DataGridBonusCodeKeys, DataGridColumn<BonusCodeDetailsViewModel>>> {
        return this._config;
    }

    private getDetailsActionCellValue(model: BonusCodeDetailsViewModel): ActionButtonColumnValue {
        const path = appendSelectedItemToQuery(`${RouteUrl.MarketingFunction}${RouteUrl.BonusEngineDetails}`, model?.bonus_id);
        return {
            type: 'icon',
            icon: {
                key: 'ListPlayOutline',
                color: IconColor.Secondary,
            },
            label: bonusCodeDataGridLocalized.details_action,
            action: push(path),
        };
    }

    private getDeactivateModalValue(model: BonusCodeDetailsViewModel): ModalButtonColumnValue {
        const modalContent: ModalContent = {
            body: (
                <BonusDeactivateConfirmationModal
                    bonusId={model?.bonus_id}
                    title={localized.modalTitle}
                    contentDescription={localized.modalActionDescription}
                />
            ),
        };
        return {
            type: 'button',
            icon: {
                key: 'CancelOutline',
                color: IconColor.SuitRed,
            },
            label: bonusCodeDataGridLocalized.deactivate_action,
            modalContent,
            disabled: model.bonus_status === BonusStatus.Inactive,
            disableTooltip: true,
        };
    }

    private getIdModalValue(model: BonusCodeDetailsViewModel): ModalButtonColumnValue {
        const modalContent: ModalContent = {
            title: <LocalizedText label={localized.modalDetailsTitle} />,
            body: <BonusEngineLinkButton bonusCodeId={model?.bonus_id} />,
        };
        return {
            type: 'link',
            label: model?.bonus_id,
            modalContent,
            disabled: model.credit_type === 'CASINO_COIN',
            disableTooltip: true,
        };
    }
}

type DataGridBonusCodeProps = Omit<DataGridServerProps<BonusCodeDetailsViewModel, DataGridBonusCodeKeys>, 'type' | 'columns'> & {
    columns: DataGridBonusCodeKeys[];
};

export function DataGridBonusCode(props: DataGridBonusCodeProps) {
    const columns = useRef(getColumns(new DataGridBonusCodeConfigFactory().config));

    function getColumns(
        config: Partial<Record<DataGridBonusCodeKeys, DataGridColumn<BonusCodeDetailsViewModel>>>
    ): DataGridColumn<BonusCodeDetailsViewModel>[] {
        return props?.columns?.map((column: DataGridBonusCodeKeys) => config[column]);
    }

    return <DataGridServer {...props} type="mui" columns={columns.current} />;
}
