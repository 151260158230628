import {MessageDescriptor} from 'react-intl';

import {
    ColumnConfigurationColDef,
    FeatureColDef,
    gridCellDefaultFormatter,
    PermissionColDef,
    renderCellHeader,
    renderCellWithTooltip,
    renderGridCurrencyWithTooltip,
    SortableColDef,
} from '@components/data-grid/mui';
import {BonusCodeHistoryFilterInput, EventInitiator} from '@models/generated/graphql';
import {formatTimestamp} from '@utils';

import {Sorting} from 'src/common/types';
import {BeBonusHistoryColumnsConfiguration, beBonusHistoryFullSearch, BeBonusHistoryGridItem} from 'src/features/be-bonus-history';
import {JurisdictionCurrency} from '../../../features/app/config/types';
import {JurisdictionSelectOption} from '../../../features/module-shared/types';
import {ModuleFilterDateRangeObsolete} from '../../../features/shared/filter/components/date-range-picker/FilterDateRangePickerObsolete';
import FilterTextWithDropdown from '../../../features/shared/filter/components/FilterTextWithDropdown';
import {Filter, FilterPlacement, MultipleKeysFilter} from '../../../features/shared/filter/types';

import {localizedBonusEngineDetails} from './BonusEngineDetails.localize';

export const getGridColumns = (currency: JurisdictionCurrency) => {
    const columns: SortableColDef[] = [
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.bonus_id),
            hide: true,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withBonusId)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.uid),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.uid),
            valueFormatter: gridCellDefaultFormatter<BeBonusHistoryGridItem>(i => i.uid),
            flex: 0.2,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withUid)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.email),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.email),
            valueFormatter: gridCellDefaultFormatter<BeBonusHistoryGridItem>(i => i.email),
            flex: 0.2,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withEmail)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.username),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.username),
            valueFormatter: gridCellDefaultFormatter<BeBonusHistoryGridItem>(i => i.username),
            flex: 0.2,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withUsername)],
        } as ColumnConfigurationColDef,
        {
            field: nameof.full<BeBonusHistoryGridItem>(u => u.created_at.seconds),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.transactionDate),
            valueFormatter: gridCellDefaultFormatter<BeBonusHistoryGridItem>(t => formatTimestamp(t.created_at, 'date-time')),
            flex: 0.2,
            sortField: nameof.full<BeBonusHistoryGridItem>(u => u.created_at.seconds),
            sortable: true,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withCreateTime)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.transaction_amount),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.paidValue),
            renderCell: params => renderGridCurrencyWithTooltip(params, currency),
            flex: 0.2,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withTransactionAmount)],
        } as ColumnConfigurationColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.unrealized_amount),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.unrealizedValue),
            renderCell: params => renderGridCurrencyWithTooltip(params, currency),
            flex: 0.2,
            configurationColumns: [nameof<BeBonusHistoryColumnsConfiguration>(u => u.withUnrealizedAmount)],
        } as ColumnConfigurationColDef & FeatureColDef,
        {
            field: nameof<BeBonusHistoryGridItem>(u => u.trigger_by),
            renderHeader: () => renderCellHeader(localizedBonusEngineDetails.triggerBy),
            valueFormatter: params => {
                const bonusHistory: BeBonusHistoryGridItem = params.api.getRow(params.id) as BeBonusHistoryGridItem;
                return bonusHistory.trigger_by === EventInitiator.Bo ? bonusHistory.trigger_by_full_name : bonusHistory.trigger_by;
            },
            renderCell: params => renderCellWithTooltip(params),
            flex: 0.2,
            configurationColumns: [
                nameof<BeBonusHistoryColumnsConfiguration>(u => u.withTriggerBy),
                nameof<BeBonusHistoryColumnsConfiguration>(u => u.withTriggerByUid),
            ],
        } as ColumnConfigurationColDef,
    ];

    const defaultCol: PermissionColDef = {
        field: '',
        width: 200,
        renderCell: renderCellWithTooltip,
        sortable: false,
    } as PermissionColDef;

    return columns.map(c => ({...defaultCol, ...c}));
};

const textSearchOptions: JurisdictionSelectOption[] = [
    {label: localizedBonusEngineDetails.allFilter, value: beBonusHistoryFullSearch},
    {label: localizedBonusEngineDetails.playerIDFilter, value: nameof<BeBonusHistoryGridItem>(w => w.uid)},
    {label: localizedBonusEngineDetails.usernameFilter, value: nameof<BeBonusHistoryGridItem>(w => w.username)},
    {label: localizedBonusEngineDetails.emailFilter, value: nameof<BeBonusHistoryGridItem>(w => w.email)},
];

const getFilterLabel = (key: string): MessageDescriptor => {
    return {
        [beBonusHistoryFullSearch]: localizedBonusEngineDetails.searchByAllFilter,
        [nameof<BeBonusHistoryGridItem>(w => w.uid)]: localizedBonusEngineDetails.searchByPlayerIdFilter,
        [nameof<BeBonusHistoryGridItem>(w => w.username)]: localizedBonusEngineDetails.searchByUsernameFilter,
        [nameof<BeBonusHistoryGridItem>(w => w.email)]: localizedBonusEngineDetails.searchByEmailFilter,
    }[key];
};

export const getFilters = (): Filter[] =>
    [
        {
            keys: [
                beBonusHistoryFullSearch,
                nameof<BeBonusHistoryGridItem>(w => w.uid),
                nameof<BeBonusHistoryGridItem>(w => w.username),
                nameof<BeBonusHistoryGridItem>(w => w.email),
            ],
            component: FilterTextWithDropdown,
            options: textSearchOptions,
            placement: FilterPlacement.Primary,
            getLabel: getFilterLabel,
            limitMaxWidth: true,
        } as MultipleKeysFilter,
        {
            key: [
                nameof.full<BonusCodeHistoryFilterInput>(u => u.created_at.from),
                nameof.full<BonusCodeHistoryFilterInput>(u => u.created_at.to),
            ],
            component: ModuleFilterDateRangeObsolete,
            placement: FilterPlacement.Secondary,
            options: nameof<BonusCodeHistoryFilterInput>(u => u.created_at),
        },
    ] as Filter[];

const defaultSorting: Sorting<string>[] = [{field: nameof.full<BeBonusHistoryGridItem>(u => u.created_at.seconds), sort: 'desc'}];

export default {
    filters: getFilters(),
    getGridColumns,
    defaultSorting,
};
