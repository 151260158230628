import {DocumentNode, gql, NormalizedCacheObject} from '@apollo/client';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {BoUser} from '@models/bo-user';
import {BonusCodeHistory, BonusCodeHistoryFilterInput, BonusCreditType, Query} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {EntityFetchServiceResponsePayload, EntityType} from '@redux/entity';
import {BoUserService} from '@services';
import {ReadonlyRealtimeGridServiceBase} from '@services/deprecated';
import {IGQlFilterVariables, IGQlSearchFilter} from '@services/deprecated/types';
import {ApolloClientProxy} from '@services/gql-api';
import {ignoreCase, toGQKMultiselectFilter, toGQLDateFilter, toGQLTextFilter} from '@utils';

import {Filter, ItemsPage, SearchFilter} from 'src/common/types';
import {IRealtimeGridReadService} from '../../realtime-updates/services/IRealtimeGridReadService';
import {BeBonusHistoryColumnsConfiguration, beBonusHistoryFullSearch, BeBonusHistoryGridItem, BeBonusHistoryItem} from '../types';

const getBeBonusHistoryQuery = (configuration: BeBonusHistoryColumnsConfiguration) => gql`
    query getBonusCodeHistory($filter: BonusCodeHistoryFilterInput, $sort: Sorting, $start: Int, $end: Int) {
        getBonusCodeHistory(filter: $filter, sort: $sort, end: $end, start: $start) {
            items {
                id ${configuration.withBonusId ? '' : '@client'}
                bonus_id ${configuration.withBonusId ? '' : '@client'}
                uid ${configuration.withUid ? '' : '@client'}
                email ${configuration.withEmail ? '' : '@client'}
                username ${configuration.withUsername ? '' : '@client'}
                created_at ${configuration.withCreateTime ? '' : '@client'}  {
                    seconds
                }
                transaction_amount ${configuration.withTransactionAmount ? '' : '@client'}
                unrealized_amount ${configuration.withUnrealizedAmount ? '' : '@client'}
                type ${configuration.withType ? '' : '@client'}
                trigger_by ${configuration.withTriggerBy ? '' : '@client'}
                trigger_by_uid ${configuration.withTriggerByUid ? '' : '@client'}
            }
            total_count
        }
    }
`;

/**
 * @deprecated Should be removed when switched to view feature
 */
export class BeBonusHistoryService
    extends ReadonlyRealtimeGridServiceBase<BeBonusHistoryItem, BeBonusHistoryGridItem, BonusCodeHistory>
    implements IRealtimeGridReadService<string>
{
    private readonly _boUserService: BoUserService;
    constructor(client: ApolloClientProxy<NormalizedCacheObject>, boUserService: BoUserService) {
        super(client);
        this._boUserService = boUserService;
    }

    getItem(): Observable<BeBonusHistoryItem> {
        throw new Error('Method not implemented.');
    }

    getItems(): Observable<BeBonusHistoryGridItem[]> {
        throw new Error('Method not implemented.');
    }

    getItemsIds(filter?: SearchFilter): Observable<string[]> {
        const configuration = this.getGQLConfiguration(new BeBonusHistoryColumnsConfiguration(), [
            nameof<BeBonusHistoryColumnsConfiguration>(c => c.withBonusId),
        ]);

        return this.hasSelectedItemId(filter)
            ? this._client
                  .executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter))
                  .pipe(map<Query, string[]>(res => res?.getBonusCodeHistory?.items?.map(i => `${i?.id}`) ?? []))
            : of([]);
    }

    getItemsPage(filter?: SearchFilter, itemFields?: string[]): Observable<ItemsPage<BeBonusHistoryGridItem>> {
        const configuration = this.getGQLConfiguration(new BeBonusHistoryColumnsConfiguration(), itemFields);

        return this.hasSelectedItemId(filter)
            ? forkJoin([
                  this._client.executeQuery(this.getItemsPageQuery(configuration), this.getGQLVariables(filter)),
                  this._boUserService.get({type: EntityType.BoUser, fields: ['id', 'firstName', 'lastName'], filter: ''}),
              ]).pipe(
                  mergeMap<[Query, EntityFetchServiceResponsePayload<BoUser>], Observable<ItemsPage<BeBonusHistoryGridItem>>>(
                      ([beBonusResponse, usersResponse]) => {
                          const items: BeBonusHistoryGridItem[] =
                              beBonusResponse?.getBonusCodeHistory?.items?.map((i, index) =>
                                  this.mapModels(
                                      {
                                          id: `${index}`,
                                          serverId: `${i?.id}`,
                                          trigger_by_full_name: this.getUserFullNameById(
                                              usersResponse?.responsePayload?.items,
                                              i?.trigger_by_uid
                                          ),
                                      } as BeBonusHistoryGridItem,
                                      i
                                  )
                              ) ?? [];
                          return of({
                              items,
                              total: beBonusResponse?.getBonusCodeHistory?.total_count ?? 0,
                          } as ItemsPage<BeBonusHistoryGridItem>);
                      }
                  ),
                  catchError(() => this.getFailedItemPageResponse())
              )
            : this.getFailedItemPageResponse();
    }

    private getFailedItemPageResponse(): Observable<ItemsPage<BeBonusHistoryGridItem>> {
        return of({
            items: [] as BeBonusHistoryGridItem[],
            total: 0,
        });
    }

    private getUserFullNameById(users: BoUser[], id: string): string {
        const user = users?.find(i => i.id === id);
        return user ? `${user.firstName} ${user.lastName}` : undefined;
    }

    private hasSelectedItemId(filter?: SearchFilter): boolean {
        return !!filter.selectedItemId;
    }

    protected getItemsPageQuery(configuration: BeBonusHistoryColumnsConfiguration): DocumentNode {
        return getBeBonusHistoryQuery(configuration);
    }

    protected getGQLVariables(searchFilter: SearchFilter): IGQlFilterVariables {
        if (this.hasSelectedItemId(searchFilter)) {
            searchFilter.filter.push({key: nameof<BeBonusHistoryItem>(b => b.bonus_id), value: searchFilter.selectedItemId});
        }

        return super.getGQLVariables(searchFilter);
    }

    protected toGQLSearchFilter(filters: Filter[]): IGQlSearchFilter {
        const filter: BonusCodeHistoryFilterInput = {
            text: [
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BeBonusHistoryItem>(b => [b.bonus_id]),
                    nameof<BeBonusHistoryItem>(b => b.bonus_id)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BeBonusHistoryItem>(b => [b.uid]),
                    nameof<BeBonusHistoryItem>(b => b.uid)
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BeBonusHistoryItem>(b => [b.email]),
                    nameof<BeBonusHistoryItem>(b => b.email),
                    ignoreCase
                ),
                toGQLTextFilter(
                    filters,
                    nameof.toArray<BeBonusHistoryItem>(b => [b.username]),
                    nameof<BeBonusHistoryItem>(b => b.username)
                ),
                toGQLTextFilter(
                    filters,
                    [
                        nameof<BeBonusHistoryItem>(w => w.uid),
                        nameof<BeBonusHistoryItem>(w => w.email),
                        nameof<BeBonusHistoryItem>(w => w.username),
                    ],
                    beBonusHistoryFullSearch
                ),
            ],
            type: toGQKMultiselectFilter<BonusCreditType>(
                filters,
                nameof<BonusCodeHistoryFilterInput>(m => m.type)
            ),
        };

        const dateFilter = toGQLDateFilter(
            filters,
            nameof<BonusCodeHistoryFilterInput>(m => m.created_at)
        );

        if (dateFilter) {
            filter.created_at = dateFilter;
        }

        return filter;
    }
}
