import React, {useRef} from 'react';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {DropdownType} from '@components/dropdown/DropdownHoc';
import {DropdownItem} from '@components/dropdown/DropdownItem';
import {EditableProps, withEditButton} from '@components/editable/EditButtonHoc';
import LocalizedText from '@components/i18n/LocalizedText';
import {SelectButton, SelectProps} from '@components/select';

import {dashCharacter as emptyCellCharacter} from '../label';

import {useClasses} from './EditableSelect.style';

const EditableSelectInternal = ({mode, chipType, ...props}: EditableProps & Omit<SelectProps<string>, 'mode'>) => {
    const {classes} = useClasses();
    const containerRef = useRef<HTMLDivElement>(null);

    const {disabled, value, options} = props;
    const isEditDisabled = disabled || mode !== 'Edit';
    const selectedOption = options.find(i => i.value === value);
    const selectedLabel = selectedOption?.label || emptyCellCharacter;

    function getValueInReadMode() {
        const isEmpty = !value;
        const isChip = chipType !== undefined;
        let res: JSX.Element = <></>;

        if (isEmpty) {
            res = <>{emptyCellCharacter}</>;
        } else if (isChip) {
            res = <MemoizedChip chipName={chipType} value={value} cursorPointer />;
        } else {
            res = <LocalizedText label={selectedLabel ?? emptyCellCharacter} />;
        }

        return res;
    }

    return (
        <div className={classes.editableSelect} ref={containerRef}>
            {isEditDisabled ? (
                getValueInReadMode()
            ) : (
                <SelectButton
                    styles={{
                        dropdownButton: classes.editableSelectButton,
                        dropdownButtonOpen: classes.editableSelectButtonOpen,
                    }}
                    selectedText={selectedLabel ?? emptyCellCharacter}
                    container={containerRef.current}
                    dropdownType={DropdownType.Attached}
                    closeOnClickInside
                >
                    {options.map((option, index) => (
                        <DropdownItem
                            key={index}
                            onClick={() => {
                                const submitValue = option.value;
                                return props.onSubmit(submitValue);
                            }}
                            confirmIcon
                            label={option.label}
                            selected={value === option.value}
                            disabled={option.disabled}
                        />
                    ))}
                </SelectButton>
            )}
        </div>
    );
};

export const EditableSelect = withEditButton(EditableSelectInternal);
