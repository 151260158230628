import {gql} from '@apollo/client';

import {GqlEntityArgs} from './types';

//TODO: [BO-2938] Cleanup file after Bonus module is moved to view feature
const fragmentAll = gql`
    fragment BeBonusFragment on BonusCodeDetails {
        bonus_id
        bonus_name
        platform
        bonus_status
        start_time {
            seconds
        }
        end_time {
            seconds
        }
        threshold
        credit_type
        bonus_to_credit
        release_metric
        release_mechanism
        meta {
            bonus_value_param
            expire_period
            max_bonus_get
            min_deposit
            overall_max_bonus_get
            rake_amount
            rake_qualify
            show_max_bonus_in_total
            ticket_serial
            time_range_by_date
            total_max_bonus_get
            unrealized_expire_period
        }
        description
    }
`;

export const fragmentBeBonusInput = gql`
    fragment BeBonusInputFragment on BonusCodeDetails {
        bonus_id
        bonus_name
        bonus_status
        bonus_to_credit
        create_time {
            seconds
        }
        credit_type
        description
        end_time {
            seconds
        }
        meta {
            bonus_value_param
            expire_period
            max_bonus_get
            min_deposit
            overall_max_bonus_get
            rake_amount
            rake_qualify
            show_max_bonus_in_total
            ticket_serial
            time_range_by_date
            total_max_bonus_get
            unrealized_expire_period
        }
        platform
        release_mechanism
        release_metric
        start_time {
            seconds
        }
        threshold
        created_by
    }
`;

export const beBonus: GqlEntityArgs = {
    fragmentAll,
    addSubscription: gql`
        ${fragmentAll}
        subscription OnBeBonusAdded {
            beBonusAdded {
                ...BeBonusFragment
            }
        }
    `,
    addMutation: gql`
        ${fragmentBeBonusInput}
        mutation AddBeBonus($bonus: BonusCodeInput!) {
            addBonusCode(bonus: $bonus) {
                ...BeBonusInputFragment
            }
        }
    `,
};
