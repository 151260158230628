import {IModuleGridItem} from '@components/data-grid';
import {BonusCodeHistory} from '@models/generated/graphql';

import {ColumnConfiguration} from 'src/common/types';
import {IModuleItem} from '../module-shared/types';

export const domain = 'be-bonus-history';

/**
 * @deprecated Should be removed when switched to view feature
 */
export class BeBonusHistoryItem extends BonusCodeHistory implements IModuleItem {}

/**
 * @deprecated Should be removed when switched to view feature
 */
export class BeBonusHistoryGridItem extends BonusCodeHistory implements IModuleGridItem {
    isNew: boolean;
    serverId: string;
    trigger_by_full_name: string;
}

/**
 * @deprecated Should be removed when switched to view feature
 */
export type BeBonusHistoryColumnsConfigurationKeys =
    | 'withBonusId'
    | 'withUid'
    | 'withEmail'
    | 'withUsername'
    | 'withCreateTime'
    | 'withTransactionAmount'
    | 'withUnrealizedAmount'
    | 'withType'
    | 'withTriggerBy'
    | 'withTriggerByUid';

/**
 * @deprecated Should be removed when switched to view feature
 */
export class BeBonusHistoryColumnsConfiguration
    extends ColumnConfiguration
    implements Record<BeBonusHistoryColumnsConfigurationKeys, boolean>
{
    withBonusId: boolean;
    withUid: boolean;
    withEmail: boolean;
    withUsername: boolean;
    withCreateTime: boolean;
    withTransactionAmount: boolean;
    withUnrealizedAmount: boolean;
    withType: boolean;
    withTriggerBy: boolean;
    withTriggerByUid: boolean;

    constructor() {
        super();
        this.withBonusId = false;
        this.withUid = false;
        this.withEmail = false;
        this.withUsername = false;
        this.withCreateTime = false;
        this.withTransactionAmount = false;
        this.withUnrealizedAmount = false;
        this.withType = false;
    }
}

export const beBonusHistoryFullSearch = 'beBonusHistoryFullSearch';

export type BonusCodeHistoryDownloadModel = Record<BeBonusHistoryColumnsConfigurationKeys, string>;
