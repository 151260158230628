import React, {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';

import {CircleBoxWithText} from '@components/circle-box/CircleBoxWithText';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {BoUser} from '@redux/entity';
import {SystemColorsEnum} from '@style';

import {UserGridItem} from '../../../../pages/access-management/types';
import {appSharedActions} from '../../../app-shared/actions';
import {tempStorageDataSelector} from '../../../module-temp-storage/selectors';

type NotificationHistorySenderProps = {
    senderId: string;
};

export const NotificationHistorySender = memo(({senderId}: NotificationHistorySenderProps) => {
    const dispatch = useDispatch();

    const users = useSelector(tempStorageDataSelector<UserGridItem[]>(nameof(BoUser))) ?? [];
    const sender = users?.find(u => u.id === senderId)?.email ?? senderId;
    const firstLetter = sender?.charAt(0)?.toUpperCase() ?? emptyCellCharacter;

    useEffect(() => {
        dispatch(appSharedActions.initUsersLoading());
    }, []);

    return (
        <Box>
            {sender ? (
                <CircleBoxWithText letter={firstLetter} color={SystemColorsEnum.LightBlue} size="small" text={sender} />
            ) : (
                emptyCellCharacter
            )}
        </Box>
    );
});

export const renderNotificationHistorySender = (senderId: string) => {
    return <NotificationHistorySender senderId={senderId} />;
};
