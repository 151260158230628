import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {Box} from '@mui/material';
import equal from 'fast-deep-equal/es6';
import moment from 'moment';
import {makeStyles} from 'tss-react/mui';

import {EditableDate} from '@components/editable/EditableDate';
import {FormError} from '@components/input';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {Timestamp} from '@models/generated/graphql';
import {agentReadPolicies, customerSupportUpdatePermissions} from '@models/permissions/permissions';
import {UserReferralExpirationDateFormModel} from '@services/playerReferralService';
import {CustomTheme} from '@style';
import {formatDate, momentToTimestampSeconds, timestampSecondsToDate} from '@utils';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {LackOfPermissionIndicator, withMultiplePermission} from '../../app/permission/PermissionHoc';
import {playerReferralActions} from '../../block-player-referral-actions/actions';
import {useReduxForm, useReduxFormRequestMode} from '../../shared/form/hooks';

const useUserReferralExpirationDateClasses = makeStyles()((_theme: CustomTheme) => ({
    userReferralExpirationDateContainer: {
        width: '100%',
    },
}));

type UserReferralExpirationDateProps = UserReferralExpirationDateFormModel & {
    disabled?: boolean;
};

const UserReferralExpirationDateInternal = (props: UserReferralExpirationDateProps) => {
    const {classes} = useUserReferralExpirationDateClasses();
    const [fieldValueModel, setFieldValueModel] = useState<UserReferralExpirationDateFormModel>(props);
    const {locale} = useJurisdictionConfig();

    const {control, submit, cancel, handleSubmit} = useReduxForm<UserReferralExpirationDateFormModel, UserReferralExpirationDateFormModel>({
        initialModel: fieldValueModel,
        asyncAction: playerReferralActions.editExpirationDate,
        map: m => ({
            referee_uid: props.referee_uid,
            expiration_date: m?.expiration_date,
        }),
    });

    const {requestMode, resetRequestMode} = useReduxFormRequestMode<UserReferralExpirationDateFormModel>(
        playerReferralActions.editExpirationDate
    );

    useEffect(() => {
        if (!equal(fieldValueModel, props)) {
            setFieldValueModel(props);
        }
    }, [props]);

    return (
        <Box className={classes.userReferralExpirationDateContainer}>
            <form onSubmit={handleSubmit(submit)}>
                <Controller
                    render={({field, fieldState}) => (
                        <Box>
                            <EditableDate
                                mode={requestMode}
                                value={timestampSecondsToDate((field.value as Timestamp)?.seconds)}
                                formatter={value => (value ? formatDate(value, 'date', false, false) : emptyCellCharacter)}
                                onChange={value => {
                                    const updatedValue = {
                                        seconds: momentToTimestampSeconds(moment(value)),
                                    };
                                    field.onChange(updatedValue);
                                    resetRequestMode();
                                }}
                                onSave={() => handleSubmit(submit)}
                                onCancel={cancel}
                                disabled={props.disabled}
                                disableFutureDates={false}
                                hasBottomSpacing={false}
                                fullWidth={false}
                                locale={locale}
                            ></EditableDate>
                            {fieldState.invalid ? <FormError>{fieldState.error.message}</FormError> : <></>}
                        </Box>
                    )}
                    control={control}
                    name="expiration_date"
                    defaultValue={props.expiration_date}
                ></Controller>
            </form>
        </Box>
    );
};

export const UserReferralExpirationDate = withMultiplePermission(UserReferralExpirationDateInternal, {
    allowedPermissions: customerSupportUpdatePermissions,
    restrictedPermissions: agentReadPolicies,
    indicator: LackOfPermissionIndicator.Disabled,
});
