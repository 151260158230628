import React from 'react';
import {defineMessages} from 'react-intl';
import {StyledComponentProps} from '@mui/material/styles';

import {MessageOrientation} from '@components/alerts/Message';
import {AutocompleteOptionType} from '@components/autocomplete';
import {EditableAutocompleteProps, withEditableAutocomplete} from '@components/editable/EditableAutocomplete';
import LocalizedText from '@components/i18n/LocalizedText';
import {dashCharacter as emptyCellCharacter} from '@components/label';
import {UserProfile} from '@models/generated/graphql';
import {EntityType, Filter, UserProfileServerFilterKeys} from '@redux/entity';
import {isStringNullOrEmpty} from '@utils';

import {ViewAutocomplete} from 'src/features/view-autocomplete/components/ViewAutocomplete';

export type PlayerIdAutocompleteErrorState = 'not_found';

type PlayerIdAutocompleteProps = Omit<EditableAutocompleteProps<Filter<UserProfileServerFilterKeys>>, 'value'> &
    StyledComponentProps & {
        value: string;
        optionsFilter?: (value: string) => boolean;
        formErrorState?: PlayerIdAutocompleteErrorState;
        onFormErrorStateChange?: (state: PlayerIdAutocompleteErrorState) => void;
    };

const localized = defineMessages({
    autocompleteDisplayName: {
        id: 'PlayerIdAutocomplete_autocompleteDisplayName',
        defaultMessage: 'Player ID',
    },
});

export const PlayerIdAutocomplete = ({value, onFormErrorStateChange, ...props}: PlayerIdAutocompleteProps & StyledComponentProps) => {
    const viewType = 'PlayerIdAutocomplete';
    const selectedPlayer = value ? ({uid: value} as UserProfile) : null;

    function handleOptionsChange(options: AutocompleteOptionType[], inputValue: string) {
        if (onFormErrorStateChange) {
            if (!options?.length && inputValue) {
                onFormErrorStateChange('not_found');
            } else {
                onFormErrorStateChange(null);
            }
        }
    }

    function optionMapper(item: UserProfile): AutocompleteOptionType {
        const value = item?.uid;
        const filteredValue = !props.optionsFilter || props.optionsFilter(value) ? value : null;
        return filteredValue ? {label: filteredValue, value: filteredValue} : null;
    }

    function valueMapper(value: UserProfile): string {
        return value?.uid;
    }

    const filter: Filter<UserProfileServerFilterKeys> = value ? {uid: value} : null;
    return props.mode === 'Read' ? (
        <LocalizedText label={!isStringNullOrEmpty(value) ? value : emptyCellCharacter} />
    ) : (
        <ViewAutocomplete
            {...props}
            value={selectedPlayer}
            viewType={viewType}
            entityType={EntityType.UserProfile}
            displayName={localized.autocompleteDisplayName}
            fields={[nameof<UserProfile>(i => i.uid)]}
            optionMapper={optionMapper}
            valueMapper={valueMapper}
            errorMessageOrientation={MessageOrientation.Floating}
            filterKey={'uid'}
            filter={filter}
            validateFilter={() => true}
            mode="server"
            onOptionsChange={handleOptionsChange}
        />
    );
};

export const EditablePlayerIdAutocomplete = withEditableAutocomplete(PlayerIdAutocomplete);
