import {Mapper} from '@automapper/core';
import {inject, injectable} from 'inversify';
import {Observable, of} from 'rxjs';

import {ServiceTypes} from '@inversify';
import {BonusCodeDetails} from '@models/generated/graphql';
import {mergeMap} from '@otel';
import {BonusCodeQueryFields, EntityType} from '@redux/entity';
import {IBonusCodeService} from '@services/bonusCodeService';
import {InternalUserApiService} from '@services/rest-api';
import {ServerResponseStatus} from '@services/types';

import {
    downloadRequestFailedMessage,
    DownloadRequestFailedMessageType,
    DownloadStrategyRequest,
    IDownloadStrategy,
} from 'src/features/block-download';
import {BonusCodeDetailsViewModelKeys, BonusCodeDownloadModel} from '../types';

@injectable()
export class BonusCodeDownloadStrategy implements IDownloadStrategy<BonusCodeDetailsViewModelKeys> {
    private readonly _mapper: Mapper;
    private readonly _bonusCodeService: IBonusCodeService;
    private readonly _userService: InternalUserApiService;

    constructor(
        @inject(ServiceTypes.AutoMapper) mapper: Mapper,
        @inject(ServiceTypes.BonusCodeService) service: IBonusCodeService,
        @inject(ServiceTypes.BoUserApiService) userService: InternalUserApiService
    ) {
        this._mapper = mapper;
        this._bonusCodeService = service;
        this._userService = userService;
    }

    public process({
        keys,
        filter,
    }: DownloadStrategyRequest<BonusCodeDetailsViewModelKeys>): Observable<
        Record<BonusCodeDetailsViewModelKeys, string>[] | DownloadRequestFailedMessageType
    > {
        const fields = keys?.BonusCode?.map((key: BonusCodeDetailsViewModelKeys) =>
            this._mapper.map<BonusCodeDetailsViewModelKeys, BonusCodeQueryFields>(
                key,
                nameof<BonusCodeDetailsViewModelKeys>(),
                nameof<BonusCodeQueryFields>()
            )
        );
        return this._bonusCodeService.get({fields, filter, type: EntityType.BonusCode}).pipe(
            mergeMap(response => {
                let result: Observable<BonusCodeDownloadModel[] | DownloadRequestFailedMessageType> = of(downloadRequestFailedMessage);
                if (response.status === ServerResponseStatus.Success) {
                    const items: BonusCodeDetails[] = response?.responsePayload?.items as BonusCodeDetails[];
                    const ids = items.map(i => i.created_by);
                    result = fields.includes('created_by')
                        ? this._userService.getUserPage({ids}).pipe(
                              mergeMap(({responsePayload, status}) => {
                                  let result: BonusCodeDownloadModel[] | DownloadRequestFailedMessageType = downloadRequestFailedMessage;
                                  if (status === ServerResponseStatus.Success) {
                                      result = items?.map(bonusCode =>
                                          this._mapper.map<BonusCodeDetails, BonusCodeDownloadModel>(
                                              bonusCode,
                                              BonusCodeDetails,
                                              BonusCodeDownloadModel,
                                              {
                                                  extraArgs: () => ({
                                                      email: responsePayload?.items?.find(i => i?.id === bonusCode?.created_by)?.email,
                                                  }),
                                              }
                                          )
                                      );
                                  }
                                  return of(result);
                              })
                          )
                        : (result = of(
                              items?.map(bonusCode =>
                                  this._mapper.map<BonusCodeDetails, BonusCodeDownloadModel>(
                                      bonusCode,
                                      BonusCodeDetails,
                                      BonusCodeDownloadModel
                                  )
                              )
                          ));
                }
                return result;
            })
        );
    }
}
