import React, {ReactElement} from 'react';
import {makeStyles} from 'tss-react/mui';

import {OutlinedButton, SquareIconButton} from '@components/button/Buttons';
import {ButtonColumnValue} from '@components/data-grid/types';
import {LocalizedText} from '@components/i18n';
import {CustomIcon, Icon} from '@components/icons';
import StyledTooltip from '@components/Tooltip';

const useStyles = makeStyles()(theme => ({
    buttonCell: {
        minWidth: 'unset',
        height: '32px',
    },
    buttonCellLinkType: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

type BaseButtonCellProps = {
    value: ButtonColumnValue;
    onClick: () => void;
};

export function ButtonCell({value, onClick}: BaseButtonCellProps) {
    const {type = 'button', icon, label, disabled, disableTooltip} = value;
    const {cx, classes} = useStyles();

    const mapper: Record<ButtonColumnValue['type'], ReactElement> = {
        button: (
            <div>
                <OutlinedButton
                    startIcon={icon ? <Icon icon={CustomIcon[icon?.key]} color={icon.color} /> : null}
                    label={label}
                    onClick={onClick}
                    className={classes.buttonCell}
                    disabled={disabled}
                />
            </div>
        ),
        icon: (
            <div>
                <SquareIconButton onClick={onClick} iconClassName={CustomIcon[icon?.key]} iconFontSize={16} disabled={disabled} />
            </div>
        ),
        link: (
            <div className={cx(!disabled && classes.buttonCellLinkType)} onClick={() => (!disabled ? onClick() : null)}>
                <LocalizedText label={label} />
            </div>
        ),
    };
    const component = mapper[type];
    return disableTooltip ? (
        component
    ) : (
        <StyledTooltip hideTooltip={disableTooltip} title={<LocalizedText label={label} />} element={component} />
    );
}
